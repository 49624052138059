import {
  Box,
  Checkbox,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import GridItem from 'form/GridItem';
import { useState } from 'react';
import { StyledFormControlLabel } from 'styles/ObservationStyles/PainMuiStyles';
import { IFormRegister, IFormValues, IFormWatch } from '../../type';
import * as S from '../../../styles/ObservationStyles/PainConditionStyle';
import MuiTextField from 'form/MuiTextField';
import adornment from 'form/adornment';
import { ReactComponent as Body } from 'assets/img/body.svg';
import MuiRadioGroup from 'form/MuiRadioGroup';

interface Props extends IFormRegister, IFormValues, IFormWatch {}

const radioId = ['face', 'legs', 'activity', 'cry', 'consolability'];

export default function FLACC(props: Props) {
  const { register, getValues, setValue, watch } = props;
  const [sumValue, setSumValue] = useState(0);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, v: string) => {
    console.log('name', e.target.name);
    console.log('value', e.target.value);
    setValue(e.target.name, e.target.value);
    setSumValue(
      radioId.reduce((acc, cur) => {
        const value = Number(watch(cur));
        return value ? acc + value : acc;
      }, 0)
    );
  };

  return (
    <div>
      <Typography
        sx={{ fontSize: '20px', fontWeight: 700, whiteSpace: 'nowrap' }}
      >
        FLACC
      </Typography>
      <Box sx={{ width: '1000px', margin: '24px auto' }}>
        <Grid container spacing={1}>
          <GridItem bgColor="#0000001F" text="" />
          <GridItem bgColor="#0000001F" text="0" />
          <GridItem bgColor="#0000001F" text="1" />
          <GridItem bgColor="#0000001F" text="2" />

          <GridItem bgColor="#0000001F" text="얼굴 (face)" />
          <RadioGroup
            name={'flaccFace'}
            row
            sx={{
              width: '75%',
              display: 'flex',
              justifyContent: 'space-around'
            }}
            onChange={onChange}
          >
            <StyledFormControlLabel
              value={0}
              label={
                <Typography sx={{ fontSize: '13px' }}>
                  특별한 표정 없음 또는 미소
                </Typography>
              }
              control={
                <Radio
                  size="small"
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
              }
            />
            <StyledFormControlLabel
              value={1}
              label={
                <Typography sx={{ fontSize: '13px' }}>
                  때때로 찡그린 얼굴 또는 <br />
                  찌푸림, 물러남, 무관심함
                </Typography>
              }
              control={
                <Radio
                  size="small"
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
              }
            />
            <StyledFormControlLabel
              value={2}
              label={
                <Typography sx={{ fontSize: '13px' }}>
                  자주 지속되는 찌푸림,
                  <br />꽉 다문 턱, 아래턱 떨림
                </Typography>
              }
              control={
                <Radio
                  size="small"
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
              }
            />
          </RadioGroup>

          <GridItem bgColor="#0000001F" text="다리 (legs)" />
          <RadioGroup
            name={'flaceLegs'}
            row
            sx={{
              width: '75%',
              display: 'flex',
              justifyContent: 'space-around'
            }}
            onChange={onChange}
          >
            <StyledFormControlLabel
              value={0}
              label={
                <Typography sx={{ fontSize: '13px' }}>
                  정상적 자세 또는 이완됨
                </Typography>
              }
              control={
                <Radio
                  size="small"
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
              }
            />
            <StyledFormControlLabel
              value={1}
              label={
                <Typography sx={{ fontSize: '13px' }}>
                  불안함, 침착하지 못함, 긴장
                </Typography>
              }
              control={
                <Radio
                  size="small"
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
              }
            />
            <StyledFormControlLabel
              value={2}
              label={
                <Typography sx={{ fontSize: '13px' }}>
                  발을 치거나 다리를 들어 올림
                </Typography>
              }
              control={
                <Radio
                  size="small"
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
              }
            />
          </RadioGroup>

          <GridItem bgColor="#0000001F" text="활동 (acitivity)" />
          <RadioGroup
            name={'flaceActivity'}
            row
            sx={{
              width: '75%',
              display: 'flex',
              justifyContent: 'space-around'
            }}
            onChange={onChange}
          >
            <StyledFormControlLabel
              value={0}
              label={
                <Typography sx={{ fontSize: '13px' }}>
                  조용히 눕기, 정상적인 자세, <br />
                  쉽게 움직임
                </Typography>
              }
              control={
                <Radio
                  size="small"
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
              }
            />
            <StyledFormControlLabel
              value={1}
              label={
                <Typography sx={{ fontSize: '13px' }}>
                  몸부림침, 몸을 앞뒤로 <br />
                  뒤척거림 긴장
                </Typography>
              }
              control={
                <Radio
                  size="small"
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
              }
            />
            <StyledFormControlLabel
              value={2}
              label={
                <Typography sx={{ fontSize: '13px' }}>
                  아치형으로 됨,굳음 또는 경련
                </Typography>
              }
              control={
                <Radio
                  size="small"
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
              }
            />
          </RadioGroup>

          <GridItem bgColor="#0000001F" text="울음 (cry)" />
          <RadioGroup
            name={'flaceCry'}
            row
            sx={{
              width: '75%',
              display: 'flex',
              justifyContent: 'space-around'
            }}
            onChange={onChange}
          >
            <StyledFormControlLabel
              value={0}
              label={
                <Typography sx={{ fontSize: '13px' }}>
                  울음 없음 (깨거나 잠)
                </Typography>
              }
              control={
                <Radio
                  size="small"
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
              }
            />
            <StyledFormControlLabel
              value={1}
              label={
                <Typography sx={{ fontSize: '13px' }}>
                  신음소리 또는 끙끙거리는 <br />
                  소리, 때때로 불평함
                </Typography>
              }
              control={
                <Radio
                  size="small"
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
              }
            />
            <StyledFormControlLabel
              value={2}
              label={
                <Typography sx={{ fontSize: '13px' }}>
                  꾸준한 울음, 비명 또는 흐느낌,
                  <br />
                  잦은 불평
                </Typography>
              }
              control={
                <Radio
                  size="small"
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
              }
            />
          </RadioGroup>

          <GridItem bgColor="#0000001F" text="진정 (consolability)" />
          <RadioGroup
            name={'flaceConsolability'}
            row
            sx={{
              width: '75%',
              display: 'flex',
              justifyContent: 'space-around'
            }}
            onChange={onChange}
          >
            <StyledFormControlLabel
              value={0}
              label={
                <Typography sx={{ fontSize: '13px' }}>만족, 이완됨</Typography>
              }
              control={
                <Radio
                  size="small"
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
              }
            />
            <StyledFormControlLabel
              value={1}
              label={
                <Typography sx={{ fontSize: '13px' }}>
                  때때로 접촉, 안김, 말 걸기, <br />
                  안심됨
                </Typography>
              }
              control={
                <Radio
                  size="small"
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
              }
            />
            <StyledFormControlLabel
              value={2}
              label={
                <Typography sx={{ fontSize: '13px' }}>
                  진정되거나 안위가 어려움
                </Typography>
              }
              control={
                <Radio
                  size="small"
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
              }
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-end'}
            sx={{ marginTop: '20px' }}
          >
            <Typography
              gutterBottom
              minWidth={115}
              fontWeight={700}
              variant="subtitle1"
            >
              합계: {sumValue}점
            </Typography>
            <Typography
              minWidth={115}
              variant="caption"
              sx={{ color: '#604180' }}
            >
              <Typography variant="inherit">
                <Box component={'strong'} mr={0.5}>
                  0점:
                </Box>
                통증없음
              </Typography>
              <Typography variant="inherit">
                <Box component={'strong'} mr={0.5}>
                  1~3점:
                </Box>
                약간 불편함
              </Typography>
              <Typography variant="inherit">
                <Box component={'strong'} mr={0.5}>
                  4~6점:
                </Box>
                중간정도 불편함
              </Typography>
              <Typography variant="inherit">
                <Box component={'strong'} mr={0.5}>
                  7~10점:
                </Box>
                매우 불편하고 아픈상태
              </Typography>
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Grid item xs={12}>
        <Box sx={{ width: '100%', marginTop: '40px', marginBottom: '12px' }}>
          <S.ContentContainer>
            <S.ItemWrap>
              <S.ItemsHead>통증점수</S.ItemsHead>
              <MuiTextField
                required={false}
                value={sumValue}
                sx={{
                  minWidth: '130px',
                  width: '130px'
                }}
                InputProps={{ ...adornment('', '점'), readOnly: true }}
                {...register('flaceScore')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증부위</S.ItemsHead>
              <MuiTextField
                required={false}
                placeholder="직접 입력"
                {...register('flaceWay')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <Body />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증양상</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.PATTERN"
                values={[1, 2]}
                defaultValue={getValues('flacePattern')}
                onChange={v => setValue('flacePattern', v)}
                width="100px"
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증빈도</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.FREQUENCY"
                values={[1, 2, 3]}
                defaultValue={getValues('flaceFrequency')}
                onChange={v => setValue('flaceFrequency', v)}
                width="100px"
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증기간</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.LONG"
                values={[1, 2]}
                defaultValue={getValues('flaceLong')}
                onChange={v => setValue('flaceLong', v)}
                width="100px"
              />
              <MuiTextField
                required={false}
                placeholder="직접 입력"
                {...register('flaceLongInput')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증중재</S.ItemsHead>
              <span className="medi">비약물적 중재</span>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('flaceNoneMediChecked1'))}
                  {...register('flaceNoneMediChecked1')}
                  onChange={(e, checked) => {
                    setValue('flaceNoneMediChecked1', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>심호흡 방법 교육</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('flaceNoneMediChecked2'))}
                  {...register('flaceNoneMediChecked2')}
                  onChange={(e, checked) => {
                    setValue('flaceNoneMediChecked2', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>냉찜질 시행</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('flaceNoneMediChecked3'))}
                  {...register('flaceNoneMediChecked3')}
                  onChange={(e, checked) => {
                    setValue('flaceNoneMediChecked3', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>휴식</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('flaceNoneMediChecked4'))}
                  {...register('flaceNoneMediChecked4')}
                  onChange={(e, checked) => {
                    setValue('flaceNoneMediChecked4', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>기타</span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('flaceNoneMediInput')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHeadDummy></S.ItemsHeadDummy>

              <span className="none_medi">약물적 중재</span>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('flaceMediChecked'))}
                  {...register('flaceMediChecked')}
                  onChange={(e, checked) => {
                    setValue('flaceMediChecked', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>약물명 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('flaceMediInput1')}
              />
              <S.CheckboxWrap>
                <span>용량 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('flaceMediInput2')}
              />
              <S.CheckboxWrap>
                <span>투여방법 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('flaceMediInput3')}
              />
            </S.ItemWrap>
          </S.ContentContainer>
        </Box>
      </Grid>
    </div>
  );
}
