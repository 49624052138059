import * as S from '../../styles/EHRStyles/HistoryStyles';

export default function History() {
  return (
    <S.Layout>
      <S.Container>
        <S.TitleBtnWrap>
          <span className="title">처방내역</span>
          <S.AdditionalBtn>추가 처방</S.AdditionalBtn>
        </S.TitleBtnWrap>
        <S.Contents>
          <S.Inner>
            <span className="content">기본 처방</span>
            <S.BasicCard>
              <span>[정규] Check vital sign q 8hrs</span>
              <span className="addi">└ [추가] Check vital sign q 2hrs ★</span>
            </S.BasicCard>
            <S.BasicCard>
              <span>[정규] SpO₂ monitoring q1r</span>
              <span className="addi">└ [추가] SpO₂ monitoring q 2hrs ★</span>
            </S.BasicCard>
            <S.BasicCard>
              <span>[정규] CBC, electrolyte & Chemistry, ABGA</span>
            </S.BasicCard>
            <S.BasicCard>
              <span className="blue">
                [추가] 호흡 곤란 지속될 경우 다시 노티 주세요. ★
              </span>
            </S.BasicCard>
          </S.Inner>
        </S.Contents>
        <S.Contents>
          <S.Inner>
            <span className="content">투약 내역</span>
            <S.Medication>
              <span className="medi">[정규] Lasix 5mg/2ml ------ 1A 2ml</span>
            </S.Medication>
          </S.Inner>
        </S.Contents>
        <S.Contents>
          <S.Inner>
            <span className="content">검사 처방</span>
            <S.TestPrescription>
              <span className="medi">
                [정규] CBC, electrolyte & Chemistry, ABGA
              </span>
            </S.TestPrescription>
            <S.TestPrescription>
              <span className="medi">[정규] Chest X-ray (portable)</span>
            </S.TestPrescription>
          </S.Inner>
        </S.Contents>
      </S.Container>
    </S.Layout>
  );
}
