import * as S from '../../../styles/ObservationStyles/PainConditionStyle';

import { Box, Checkbox, FormHelperText, Typography } from '@mui/material';
import { IFormRegister, IFormValues } from '../../type';

import NRS_face1 from 'assets/img/NRS_face1.svg';
import NRS_face2 from 'assets/img/NRS_face2.svg';
import NRS_face3 from 'assets/img/NRS_face3.svg';
import NRS_face4 from 'assets/img/NRS_face4.svg';
import NRS_face5 from 'assets/img/NRS_face5.svg';
import NRS_face6 from 'assets/img/NRS_face6.svg';

import { useState } from 'react';
import {
  StyledMiniBox,
  StyledSlider
} from 'styles/ObservationStyles/PainMuiStyles';
import MuiTextField from 'form/MuiTextField';
import adornment from 'form/adornment';
import MuiRadioGroup from 'form/MuiRadioGroup';
import { ReactComponent as Body } from 'assets/img/body.svg';

interface Props extends IFormRegister, IFormValues {}

export default function NRS(props: Props) {
  const { register, getValues, setValue } = props;
  const [painScore, setPainScore] = useState(
    getValues('nrsScore') !== undefined ? getValues('nrsScore') : 0
  );
  const [inputError, setInputError] = useState(false);

  return (
    <div>
      <Typography
        sx={{ fontSize: '20px', fontWeight: 700, whiteSpace: 'nowrap' }}
      >
        NRS
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '24px'
        }}
      >
        <Box
          sx={{
            width: '800px',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px'
          }}
        >
          <img src={NRS_face1} />
          <img src={NRS_face2} />
          <img src={NRS_face3} />
          <img src={NRS_face4} />
          <img src={NRS_face5} />
          <img src={NRS_face6} />
        </Box>
        <StyledSlider
          min={0}
          max={10}
          marks={Array(11)
            .fill(0)
            .map((_, i) => {
              return { value: i, label: i };
            })}
          sx={{ width: '800px' }}
          value={painScore === '' ? 0 : parseInt(painScore)}
          onChange={(_, value) => {
            setPainScore(String(value));
            setValue('nrsScore', value);
          }}
        />
        <Box
          sx={{
            width: '800px',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px'
          }}
        >
          <StyledMiniBox>
            No <br /> Pain
          </StyledMiniBox>
          <StyledMiniBox>
            Mild <br /> Pain
          </StyledMiniBox>
          <StyledMiniBox>
            Moderate <br /> Pain
          </StyledMiniBox>
          <StyledMiniBox>
            Severe <br /> Pain
          </StyledMiniBox>
          <StyledMiniBox>
            Extreme <br /> Pain
          </StyledMiniBox>
        </Box>
        <Box sx={{ width: '95%', marginTop: '40px', marginBottom: '12px' }}>
          <S.ContentContainer>
            <S.ItemWrap>
              <S.ItemsHead>통증점수</S.ItemsHead>
              <MuiTextField
                required={false}
                value={painScore}
                sx={{
                  minWidth: '130px',
                  width: '130px'
                }}
                InputProps={{ ...adornment('', '점') }}
                onChange={({ target: { value } }) => {
                  setPainScore(value);
                  setValue('nrsScore', value);

                  const numValue = Number(value);
                  if (
                    value === '' ||
                    (numValue >= 0 &&
                      numValue <= 10 &&
                      Number.isInteger(numValue))
                  )
                    setInputError(false);
                  else setInputError(true);
                }}
                error={
                  painScore === '' ||
                  (Number(painScore) >= 0 &&
                    Number(painScore) <= 10 &&
                    Number.isInteger(Number(painScore)))
                    ? false
                    : true
                }
              />
              {inputError ? (
                <FormHelperText error={true}>
                  PAIN SCORE 값은 0 이상 10 이하의 정수입니다.
                </FormHelperText>
              ) : null}
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증부위</S.ItemsHead>
              <MuiTextField
                required={false}
                placeholder="직접 입력"
                {...register('nrsWay')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <Body />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증양상</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.PATTERN"
                values={[1, 2]}
                defaultValue={getValues('nrsPattern')}
                onChange={v => setValue('nrsPattern', v)}
                width="100px"
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증빈도</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.FREQUENCY"
                values={[1, 2, 3]}
                defaultValue={getValues('nrsFrequency')}
                onChange={v => setValue('nrsFrequency', v)}
                width="100px"
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증기간</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.LONG"
                values={[1, 2]}
                defaultValue={getValues('nrsLong')}
                onChange={v => setValue('nrsLong', v)}
                width="100px"
              />
              <MuiTextField
                required={false}
                placeholder="직접 입력"
                {...register('nrsLongInput')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증중재</S.ItemsHead>
              <span className="medi">비약물적 중재</span>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('nrsNoneMediChecked1'))}
                  {...register('nrsNoneMediChecked1')}
                  onChange={(e, checked) => {
                    setValue('nrsNoneMediChecked1', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>심호흡 방법 교육</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('nrsNoneMediChecked2'))}
                  {...register('nrsNoneMediChecked2')}
                  onChange={(e, checked) => {
                    setValue('nrsNoneMediChecked2', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>냉찜질 시행</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('nrsNoneMediChecked3'))}
                  {...register('nrsNoneMediChecked3')}
                  onChange={(e, checked) => {
                    setValue('nrsNoneMediChecked3', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>휴식</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('nrsNoneMediChecked4'))}
                  {...register('nrsNoneMediChecked4')}
                  onChange={(e, checked) => {
                    setValue('nrsNoneMediChecked4', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>기타</span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('nrsNoneMediInput')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHeadDummy></S.ItemsHeadDummy>
              <span className="none_medi">약물적 중재</span>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('nrsMediChecked'))}
                  {...register('nrsMediChecked')}
                  onChange={(e, checked) => {
                    setValue('nrsMediChecked', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>약물명 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('nrsMediInput1')}
              />
              <S.CheckboxWrap>
                <span>용량 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('nrsMediInput2')}
              />
              <S.CheckboxWrap>
                <span>투여방법 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('nrsMediInput3')}
              />
            </S.ItemWrap>
          </S.ContentContainer>
        </Box>
      </Box>
    </div>
  );
}
