import adornment from 'form/adornment';
import MuiTextField from 'form/MuiTextField';
import MuiRadioGroup from 'form/MuiRadioGroup';
import * as S from '../../../styles/ObservationStyles/ModalStyles';
import { IFormRegister, IFormValues } from '../../type';
import { ReactComponent as Body } from 'assets/img/body.svg';

interface Props extends IFormRegister, IFormValues {}

export default function Ltube(props: Props) {
  const { register, getValues, setValue } = props;
  return (
    <S.ContentContainer>
      <S.Title>L-Tube</S.Title>
      <S.ItemWrap>
        <S.ItemsHead>크기</S.ItemsHead>
        <MuiTextField
          required={false}
          sx={{
            minWidth: '130px',
            width: '130px',
            marginLeft: '10px'
          }}
          InputProps={{ ...adornment('', 'Fr') }}
          {...register('lSize')}
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>위치</S.ItemsHead>
        <MuiTextField
          required={false}
          sx={{
            minWidth: '130px',
            width: '130px',
            marginLeft: '10px'
          }}
          placeholder="직접 입력"
          {...register('lLocation')}
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <Body />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>삽입길이</S.ItemsHead>
        <MuiTextField
          required={false}
          sx={{
            minWidth: '130px',
            width: '130px',
            marginLeft: '10px'
          }}
          InputProps={{ ...adornment('', 'cm') }}
          {...register('lSwelling')}
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>위치 이상</S.ItemsHead>
        <MuiRadioGroup
          i18nKey="EXIST.SHORT"
          values={[1, 2]}
          defaultValue={getValues('lRedness')}
          onChange={v => setValue('lRedness', v)}
          width="70px"
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>Bleeding</S.ItemsHead>
        <MuiRadioGroup
          i18nKey="EXIST.SHORT"
          values={[1, 2]}
          defaultValue={getValues('lExudate')}
          onChange={v => setValue('lExudate', v)}
          width="70px"
        />
      </S.ItemWrap>
    </S.ContentContainer>
  );
}
