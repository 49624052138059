import styled from 'styled-components';

export const ModalContainer = styled.div<{ $showModal: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 90%;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 102;
  visibility: ${props => (props.$showModal ? 'visible' : 'hidden')};
  display: ${props => (props.$showModal ? 'block' : 'none')};
`;

export const ModalHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  img {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
`;

export const HeadText = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const ModalSave = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  span {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
  }
  color: gray;
`;

export const SaveBtn = styled.button`
  width: 58px;
  height: 32px;
  color: #fff;
  border: none;
  border-radius: 4px;
  background: ${props => props.theme.color.brand_300};
  font-family: 'Spoqa', sans-serif;

  cursor: pointer;
`;

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 32px;
  height: 700px;
  overflow: scroll;
`;

export const DateTimeWrap = styled.div`
  display: flex;
  gap: 12px;
`;

export const CategoryBtn = styled.button<{ selected: boolean }>`
  background: ${props =>
    props.selected ? props.theme.color.brand_300 : '#979797'};
  color: #fff;
  width: 100%;
  height: 36px;
  font-size: 12px;
  line-height: 15px;
  font-family: 'Spoqa', sans-serif;
  border: ${props => (props.selected ? 'none' : '1px solid #979797')};
  border-radius: 4px;
  cursor: pointer;
`;

export const BtnWrap = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
`;

export const ItemWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px;
`;

export const RadioWrap = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
  label {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  span {
    font-size: 14px;
    margin-top: 2px;
  }
`;

export const ItemsHead = styled.span`
  display: flex;
  align-items: center;
  width: 85px;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
`;

export const ItemInput = styled.input`
  width: 110px;
  border: 0.5px solid gray;
  border-radius: 4px;
  padding: 6px;
  font-family: 'Spoqa', sans-serif;
`;

export const RadioInput = styled.input.attrs({ type: 'radio' })`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  font-family: 'Spoqa', sans-serif;
  &:checked {
    background-color: ${props => props.theme.color.brand_300};
    border: 3px solid white;
    box-shadow: 0 0 0 1.8px ${props => props.theme.color.brand_300};
  }
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
