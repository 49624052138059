import adornment from 'form/adornment';
import CheckboxGroup from 'form/CheckboxGroup';
import MuiCheckbox from 'form/MuiCheckbox';
import MuiTextField from 'form/MuiTextField';
import * as S from '../../styles/FilloStyles/Info3Styles';
import { IFormRegister, IFormValues } from '../type';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

export default function Info3(props: Props) {
  const { disabled, register, getValues, setValue } = props;

  return (
    <S.Layout>
      <S.Container>
        <span className="title">Ⅲ. 가족력</span>
        <S.ContentsContainer>
          <S.ContentsBox>
            <span className="sub_title">가족 사항</span>
            <S.CheckBoxWrap>
              <CheckboxGroup
                i18nNullKey="ETC"
                // disabled={cycle === '1'? true : false}
                i18nKey="HOSPITALIZATION.FAMILLY"
                values={[1, 2, 3, 4, 5]}
                defaultValue={getValues('family_historys.family.checked')}
                onChange={v => setValue('family_historys.family.checked', v)}
              />
              <MuiCheckbox
                label="아들"
                disabled={disabled}
                defaultValue={getValues('family_historys.son.checked')}
                {...register('family_historys.son.checked')}
                sx={{ marginLeft: '18px' }}
              />
              <MuiTextField
                required={false}
                disabled={disabled}
                sx={{
                  width: '70px',
                  marginRight: '10px',
                  marginLeft: '-10px'
                }}
                InputProps={{ ...adornment('', '명') }}
                {...register('family_historys.son.input')}
              />
              <MuiCheckbox
                label="딸"
                disabled={disabled}
                defaultValue={getValues('family_historys.daughter.checked')}
                {...register('family_historys.daughter.checked')}
              />
              <MuiTextField
                required={false}
                disabled={disabled}
                InputProps={{ ...adornment('', '명') }}
                sx={{
                  width: '70px',
                  marginLeft: '-10px'
                }}
                {...register('family_historys.daughter.input')}
              />
            </S.CheckBoxWrap>
          </S.ContentsBox>
        </S.ContentsContainer>
        <S.ContentsContainer>
          <S.ContentsBox>
            <span className="sub_title2">
              가족력
              <br />
              (암포함)
            </span>
            <MuiTextField
              required={false}
              fullWidth
              disabled={disabled}
              placeholder="직접 입력"
              {...register('family_historys.family_history')}
            />
          </S.ContentsBox>
        </S.ContentsContainer>
      </S.Container>
    </S.Layout>
  );
}
