import { IVitalSign } from 'apis/vital/type';
import { IFormValues, IFormWatch } from '../../type';
import * as S from '../../../styles/ObservationStyles/VitalTable';

interface Props extends IFormValues, IFormWatch {}

export default function VitalTable(props: Props) {
  const { watch, setValue } = props;

  const vitalSignList: IVitalSign[] = watch('vital_sign');
  console.log(vitalSignList);

  return (
    <S.Layout>
      <S.HeaderBox>
        <S.HeaderContents style={{ backgroundColor: '#3B5FF8' }}>
          BP
        </S.HeaderContents>
        <S.HeaderContents style={{ backgroundColor: '#92D050' }}>
          PR
        </S.HeaderContents>
        <S.HeaderContents style={{ backgroundColor: '#FF9200' }}>
          RR
        </S.HeaderContents>
        <S.HeaderContents style={{ backgroundColor: '#DC4831' }}>
          BT
        </S.HeaderContents>
        <S.HeaderContents style={{ backgroundColor: '#AD5AC4' }}>
          O2
        </S.HeaderContents>
      </S.HeaderBox>
      <S.BodyContainer>
        {vitalSignList.map((item, i) => {
          return (
            <S.BodyBox key={i}>
              <S.BodyContents>
                {item.sbp}/{item.dbp}
              </S.BodyContents>
              <S.BodyContents>{item.pr}</S.BodyContents>
              <S.BodyContents>{item.rr}</S.BodyContents>
              <S.BodyContents>{item.bt}</S.BodyContents>
              <S.BodyContents>{item.sp02}</S.BodyContents>
            </S.BodyBox>
          );
        })}
      </S.BodyContainer>
    </S.Layout>
  );
}
