import * as S from '../../styles/InfoStyles/PatientInfoStyles';
import { Skeleton } from '@mui/material';

import usePatient from 'store/patient/usePatient';

export default function PatientInfo() {
  const { patientInfo } = usePatient();

  if (!patientInfo) {
    return <Skeleton variant="rectangular" sx={{ flex: 1 }} />;
  }

  const {
    patient_id,
    name,
    age,
    gender,
    admin_hod,
    admin_pod,
    department,
    room,
    main_doctor,
    disease_main,
    disease_sub
  } = patientInfo;

  return (
    <S.Layout>
      <S.Container>
        <S.UserBtnWrap>
          <span className="patient">
            {name} / {gender === 1 ? '여자' : '남자'} /{age} ({patient_id})
            {department} / {room}
          </span>
          <S.BtnWrap>
            <S.Infection>감염</S.Infection>
            <S.Fall>낙상</S.Fall>
            <S.Bedsore>욕창</S.Bedsore>
            <S.BloodType>Rh+ A+</S.BloodType>
          </S.BtnWrap>
        </S.UserBtnWrap>
        <S.Detail>
          <S.Inner>
            <S.Box1>
              <S.BoxWrap>
                <span className="name">진단명 :</span>
                <span className="contents">{disease_main.disease_kor}</span>
              </S.BoxWrap>
              <S.BoxWrap>
                <span className="name">담당교수 / 주치의 :</span>
                <span className="contents">{main_doctor}</span>
              </S.BoxWrap>
              <S.BoxWrap>
                <span className="name">담당 간호사 :</span>
                <span className="contents">{name}</span>
              </S.BoxWrap>
            </S.Box1>
            <S.Box2>
              <span>HOD : {admin_hod}</span>
              <span>POD : {admin_pod}</span>
              <span>수술명 : - </span>
            </S.Box2>
          </S.Inner>
        </S.Detail>
      </S.Container>
    </S.Layout>
  );
}
