import * as S from '../../styles/InfoStyles/VitalStyles';
import usePatient from 'store/patient/usePatient';
import { Skeleton } from '@mui/material';

export default function Vital() {
  const { patientInfo } = usePatient();

  if (!patientInfo) {
    return <Skeleton variant="rectangular" sx={{ flex: 1 }} />;
  }

  return (
    <S.Layout>
      <S.Container>
        <span className="title">환자 상태 (V/S)</span>
        <S.Contents>
          <S.Inner>
            <span className="content">2023-00-00 00:00 AM</span>
            <S.TestPrescription>
              <span className="medi">BP(mmHg)</span>
              <span className="medi">63/37</span>
            </S.TestPrescription>
            <S.TestPrescription>
              <span className="medi">PR(회/분)</span>
              <span className="medi">121</span>
            </S.TestPrescription>
            <S.TestPrescription>
              <span className="medi">RR(회/분)</span>
              <span className="medi">65</span>
            </S.TestPrescription>
            <S.TestPrescription>
              <span className="medi">BT(℃)</span>
              <span className="medi">36.8</span>
            </S.TestPrescription>
            <S.TestPrescription>
              <span className="medi">SpO₂(%)</span>
              <span className="medi">88</span>
            </S.TestPrescription>
          </S.Inner>
        </S.Contents>

        <S.Contents>
          <S.Inner>
            <S.TestPrescription>
              <span className="medi">
                {'<'} O2 Therapy {'>'}
              </span>
            </S.TestPrescription>
            <S.TestPrescription>
              <span className="medi">Device</span>
              <span className="medi">-</span>
            </S.TestPrescription>
            <S.TestPrescription>
              <span className="medi">O2용량(L/min)</span>
              <span className="medi">-</span>
            </S.TestPrescription>
          </S.Inner>
        </S.Contents>
      </S.Container>
    </S.Layout>
  );
}
