import * as S from '../../styles/RecordsStyles/HistoryStyles';
import usePatient from 'store/patient/usePatient';
import { Skeleton } from '@mui/material';

import NursingRecord from './NursingRecord';
import { Box } from '@mui/material';

export default function HistoryRecord() {
  const { patientInfo } = usePatient();

  const Content = () => {
    const contentBoxProps = {
      display: 'flex',
      mt: 2.5,
      columnGap: 2.5,
      overflow: 'hidden'
    };

    if (!patientInfo) {
      return (
        <Box {...contentBoxProps}>
          <Box flex={1} display="flex" flexDirection="column" overflow="auto">
            <Skeleton variant="rectangular" sx={{ flex: 1 }} />
          </Box>
          <Box flex={1} display="flex" flexDirection="column" overflow="auto">
            <Skeleton variant="rectangular" sx={{ flex: 1 }} />
          </Box>
        </Box>
      );
    }

    return (
      <Box {...contentBoxProps}>
        <NursingRecord patientInfo={patientInfo} />
      </Box>
    );
  };

  return (
    <S.Layout>
      <S.Container>
        <Content />
      </S.Container>
    </S.Layout>
  );
}
