import styled from 'styled-components';

export const Layout = styled.div`
  width: 100%;
  height: calc(100% - 240px);
  /* min-height: 684px; */
  /* margin-top: 240px; */
  background: ${props => props.theme.color.brand_100};
`;

export const Container = styled.div`
  height: 100%;
  padding-bottom: 20px;
`;

export const Title = styled.div`
  margin: 0 0 0px 20px;
  display: flex;
  gap: 10px;
  height: 30px;
  padding-top: 10px;
  span {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const ContainerWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  gap: 8px;
  /* margin: 0 15px 0 15px; */
`;

export const HistoryContainer = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 4px;
  margin: 0 10px 0 15px;
`;

export const CreateContainer = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 4px;
  margin: 0 15px 0 0;
`;
