import styled from 'styled-components';

export const Layout = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  height: 100%;
  margin: 0px 10px;
`;

export const Title = styled.div`
  font-size: 12px;
  font-weight: 500;
  flex: 3;
  padding-top: 4px;
  color: ${props => props.theme.color.black_800};
`;

export const IntputWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.textarea`
  width: 100%;
  border: 1px solid ${props => props.theme.color.black_300};
  border-radius: 4px;
  margin-top: 8px;
  height: 85px;
`;
