import adornment from 'form/adornment';
import MuiTextField from 'form/MuiTextField';
import MuiRadioGroup from 'form/MuiRadioGroup';
import * as S from '../../../styles/ObservationStyles/ModalStyles';
import { IFormRegister, IFormValues } from '../../type';
import { ReactComponent as Body } from 'assets/img/body.svg';

interface Props extends IFormRegister, IFormValues {}

export default function IVcath(props: Props) {
  const { register, getValues, setValue } = props;
  return (
    <S.ContentContainer>
      <S.Title>IV Cath</S.Title>
      <S.ItemWrap>
        <S.ItemsHead>크기</S.ItemsHead>
        <MuiTextField
          required={false}
          sx={{
            minWidth: '130px',
            width: '130px'
          }}
          InputProps={{ ...adornment('', 'G') }}
          {...register('ivSize')}
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>위치</S.ItemsHead>
        <MuiTextField
          required={false}
          sx={{
            minWidth: '130px',
            width: '130px'
          }}
          placeholder="직접 입력"
          {...register('ivLocation')}
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <Body />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>부종</S.ItemsHead>
        <MuiRadioGroup
          i18nKey="EXIST.SHORT"
          values={[1, 2]}
          defaultValue={getValues('ivSwelling')}
          onChange={v => setValue('ivSwelling', v)}
          width="70px"
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>발적</S.ItemsHead>
        <MuiRadioGroup
          i18nKey="EXIST.SHORT"
          values={[1, 2]}
          defaultValue={getValues('ivRedness')}
          onChange={v => setValue('ivRedness', v)}
          width="70px"
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>삼출물</S.ItemsHead>
        <MuiRadioGroup
          i18nKey="EXIST.EXUDATION"
          values={[1, 2]}
          defaultValue={getValues('ivExudate')}
          onChange={v => setValue('ivExudate', v)}
          width="70px"
        />
        <MuiTextField
          required={false}
          sx={{
            minWidth: '130px',
            width: '200px',
            marginLeft: '-12px'
          }}
          placeholder="직접 입력"
          {...register('ivExudateInput')}
        />
      </S.ItemWrap>
    </S.ContentContainer>
  );
}
