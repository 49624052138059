import Header from 'components/Common/Header';
import * as S from '../../styles/EHRStyles/EHRStyles';
import PatientInfo from './PatientInfo';
import History from './History';
import SBAR from './SBAR';

export default function EHR() {
  return (
    <>
      <></>
      <S.Layout>
        <S.Container>
          <S.ContainerWrap>
            <S.InfoContainer>
              <PatientInfo />
            </S.InfoContainer>
            <S.PrescriptionContainer>
              <History />
            </S.PrescriptionContainer>
          </S.ContainerWrap>
          <S.SBARContainer>
            <SBAR />
          </S.SBARContainer>
        </S.Container>
      </S.Layout>
    </>
  );
}
