import * as S from '../../styles/SafetyStyles/ModalStyles';
import MuiRadioGroup from 'form/MuiRadioGroup';
import MuiCheckbox from 'form/MuiCheckbox';
import { IFormRegister, IFormValues } from '../type';
interface Props extends IFormRegister, IFormValues {}

export default function MFS(props: Props) {
  const { register, getValues, setValue } = props;
  return (
    <>
      <S.IndexTitle>
        <span>낙상위험 평가도구 I</span>
      </S.IndexTitle>
      <S.IndexContent>
        <S.ItemWrap>
          <S.ItemsHead>낙상 경험</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="SAFETY.HISTORY"
            values={[1, 2]}
            defaultValue={getValues('morse_fall_scale.history.value')}
            onChange={v => setValue('morse_fall_scale.history.value', v)}
            width="250px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>이차적 진단</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="SAFETY.SECONDARY.DIAGNOSIS"
            values={[1, 2]}
            defaultValue={getValues(
              'morse_fall_scale.secondary_diagnosis.value'
            )}
            onChange={v =>
              setValue('morse_fall_scale.secondary_diagnosis.value', v)
            }
            width="250px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>보행보조기구</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="SAFETY.AMBULATORY.AID"
            values={[1, 2, 3]}
            defaultValue={getValues('morse_fall_scale.ambulatory_aid.value')}
            onChange={v => setValue('morse_fall_scale.ambulatory_aid.value', v)}
            width="250px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>정맥주사라인</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="SAFETY.IV"
            values={[1, 2]}
            defaultValue={getValues('morse_fall_scale.iv.value')}
            onChange={v => setValue('morse_fall_scale.iv.value', v)}
            width="250px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>걸음걸이</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="SAFETY.GAIT"
            values={[1, 2, 3]}
            defaultValue={getValues('morse_fall_scale.gait.value')}
            onChange={v => setValue('morse_fall_scale.gait.value', v)}
            width="250px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>의식상태</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="SAFETY.MENTAL.STATUS"
            values={[1, 2]}
            defaultValue={getValues('morse_fall_scale.mental_status.value')}
            onChange={v => setValue('morse_fall_scale.mental_status.value', v)}
            width="250px"
          />
        </S.ItemWrap>
      </S.IndexContent>
      <span className="total">합계 : -- 점</span>
      <div className="total-div">
        <div className="score">
          <div>
            <span className="standard">고위험군</span>
            <span>: 45점 이상</span>
          </div>
          <div>
            <span className="standard">중위험군</span>
            <span>: 25~45점</span>
          </div>
          <div>
            <span className="standard">저위험군</span>
            <span>: 0~24점</span>
          </div>
        </div>
      </div>
    </>
  );
}
