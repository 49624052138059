import CheckboxGroup from 'form/CheckboxGroup';
import MuiRadioGroup from 'form/MuiRadioGroup';
import MuiTextField from 'form/MuiTextField';
import * as S from '../../styles/FilloStyles/Info4Styles';
import { IFormRegister, IFormValues } from '../type';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

export default function Info4(props: Props) {
  const { disabled, register, getValues, setValue } = props;

  return (
    <S.Layout>
      <S.Container>
        <span className="title">Ⅳ. 과거력</span>
        <S.ContentsContainer>
          <S.ContentsBox>
            <span className="sub_title">병력</span>
            <MuiRadioGroup
              i18nKey="EXIST.SHORT"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('disease_historys.history.value')}
              onChange={v => setValue('disease_historys.history.value', v)}
              width="130px"
            />
          </S.ContentsBox>
        </S.ContentsContainer>
        <S.ContentsContainer>
          <S.ContentsBox>
            <span className="dummy_box"></span>
            <div style={{ display: 'flex' }}>
              <CheckboxGroup
                i18nNullKey="ETC"
                // disabled={cycle === '1'? true : false}
                i18nKey="HOSPITALIZATION.DISEASE"
                values={[1, 2, 3, 4, 5, 6, 0]}
                defaultValue={getValues('disease_historys.history.checked')}
                onChange={v => setValue('disease_historys.history.checked', v)}
              />
              <MuiTextField
                required={false}
                fullWidth={false}
                disabled={disabled}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('disease_historys.history.input')}
              />
            </div>
          </S.ContentsBox>
        </S.ContentsContainer>
        <S.ContentsContainer>
          <S.ContentsBox>
            <span className="sub_title2">병력 기타상세</span>
            {/* </S.ContentsBox>
        </S.ContentsContainer>
        <S.ContentsContainer>
          <S.ContentsBox> */}
            <MuiTextField
              required={false}
              fullWidth
              disabled={disabled}
              placeholder="직접 입력"
              {...register('disease_historys.etc_history')}
            />
          </S.ContentsBox>
        </S.ContentsContainer>
      </S.Container>
    </S.Layout>
  );
}
