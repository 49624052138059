export interface IGetSurvey {
  user_id: number;
  patient_id: number | undefined;
}

// 임상관찰 type
export interface IPostClinicalObservation extends IGetSurvey {
  content: string;
  check_time: string | null;
  option_type: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUpdateClinicalObs extends Omit<IClinicalObs, 'create_at'> {}

export interface IClinicalObs {
  content: string;
  check_time: string;
  patient_id: number;
  user_id: number;
  option_type: 1 | 2 | 3 | 4 | 5 | 6;
  xr_clinical_observation_id: number;
  create_at: string;
}

export interface IGetClinicalObs {
  patient_id: number;
  user_id: number;
}

export enum RECORD_TYPE_CLINICAL {
  IV = '1',
  TUNNEL = '2',
  T_TUBE = '3',
  L_TUBE = '4',
  CHEST_TUBE = '5',
  JP_BAG = '6'
}

// 통증사정
export interface IPostPain extends IGetSurvey {
  content: string;
  check_time: string | null;
  option_type: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUpdatePain extends Omit<IPain, 'create_at'> {}

export interface IPain {
  content: string;
  check_time: string;
  patient_id: number;
  user_id: number;
  option_type: 1 | 2 | 3 | 4 | 5 | 6;
  xr_clinical_observation_id: number;
  create_at: string;
}

export interface IGetPain {
  patient_id: number;
  user_id: number;
}

export enum RECORD_TYPE_PAIN {
  NRS = '1',
  FLACC = '2',
  CNPS = '3',
  FFI = '4',
  KOOS = '5',
  LEFS = '6',
  NDI = '7',
  START_BACK = '8'
}
