import GlobalStyle from 'Theme/GlobalStyles';
import './App.css';
import Router from 'router/Router';
import { ThemeProvider } from 'styled-components';
import { theme } from 'Theme/theme';
import { Provider } from 'react-redux';
import store, { persistor } from 'store';
import { IntlProvider } from 'react-intl';
import locale from 'locale';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';

function App() {
  return (
    <IntlProvider locale="ko" messages={locale.ko}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <Provider store={store}>
            <GlobalStyle />
            <ThemeProvider theme={theme}>
              <Router />
            </ThemeProvider>
          </Provider>
        </SnackbarProvider>
      </LocalizationProvider>
    </IntlProvider>
  );
}

export default App;
