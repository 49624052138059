import * as S from '../../styles/SafetyStyles/SafetyStyles';
import left from '../../assets/img/left.png';
import right from '../../assets/img/right.png';
import edit from '../../assets/img/edit.png';
import remove from '../../assets/img/remove.png';
import FallModal from './FallModal';
import BedsoreModal from './BedsoreModal';
import AddRestraintBand from './AddRestraintBand';

interface OwnProps {
  viewType: number;
  showFall: boolean;
  showBed: boolean;
  showAddRestraint: boolean;
  setShowFall: React.Dispatch<React.SetStateAction<boolean>>;
  setShowBed: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAddRestraint: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Right({
  viewType,
  showFall,
  showBed,
  showAddRestraint,
  setShowBed,
  setShowFall,
  setShowAddRestraint
}: OwnProps) {
  const openFallModal = () => {
    setShowFall(prev => !prev);
  };

  const openBedsoreModal = () => {
    setShowBed(prev => !prev);
  };

  const openAddRestraintBand = () => {
    setShowAddRestraint(prev => !prev);
  };
  return (
    <S.RightContainer>
      {!viewType ? (
        <>
          <S.AddBtnWrap>
            <S.RecordWrap>
              <div>
                <img src={left} alt="left" />
                <span>이전 기록</span>
              </div>
              <S.Separator />
              <div>
                <span>이후 기록</span>
                <img src={right} alt="right" />
              </div>
            </S.RecordWrap>
            <S.AddBtn background="#0070c0" onClick={openFallModal}>
              낙상 사정 및 간호제공 추가
            </S.AddBtn>
          </S.AddBtnWrap>
          <S.FallCardContainer>
            <S.FallCardWrap>
              <S.Card>
                <div className="date-icon">
                  <span>2023-00-00 00:00 AM</span>
                  <div className="icon-wrap">
                    <img src={edit} alt="edit-icon" />
                    <img src={remove} alt="remove-icon" />
                  </div>
                </div>
                <S.ContentWrap>
                  <span>낙상위험평가 (점)</span>
                  <span>- </span>
                </S.ContentWrap>
                <S.ContentWrap style={{ borderBottom: 'none' }}>
                  <span>낙상고위험</span>
                  <span>- </span>
                </S.ContentWrap>
              </S.Card>
              <S.Card>
                <div className="date-icon">{'<낙상간호제공>'}</div>
                <S.ContentWrap>
                  <span>환경</span>
                  <span>- </span>
                </S.ContentWrap>
                <S.ContentWrap>
                  <span>배변/배설</span>
                  <span>- </span>
                </S.ContentWrap>
                <S.ContentWrap style={{ borderBottom: 'none' }}>
                  <span>기타</span>
                  <span>- </span>
                </S.ContentWrap>
              </S.Card>
            </S.FallCardWrap>
          </S.FallCardContainer>
          <S.AddBtnWrap>
            <S.RecordWrap>
              <div>
                <img src={left} alt="left" />
                <span>이전 기록</span>
              </div>
              <S.Separator />
              <div>
                <span>이후 기록</span>
                <img src={right} alt="right" />
              </div>
            </S.RecordWrap>
            <S.AddBtn background="#70AD47" onClick={openBedsoreModal}>
              욕창 사정 및 간호제공 추가
            </S.AddBtn>
          </S.AddBtnWrap>
          <S.BedsoreContainer>
            <S.Card>
              <div className="date-icon">
                <span>2023-00-00 00:00 AM</span>
                <div className="icon-wrap">
                  <img src={edit} alt="edit-icon" />
                  <img src={remove} alt="remove-icon" />
                </div>
              </div>
              <S.ContentWrap>
                <span>욕창위험평가 (점)</span>
                <span>- </span>
              </S.ContentWrap>
              <S.ContentWrap>
                <span>욕창 유/무</span>
                <span>- </span>
              </S.ContentWrap>

              <S.ContentWrap>
                <span>욕창 위치</span>
                <span>- </span>
              </S.ContentWrap>
              <S.ContentWrap>
                <span>욕창 크기(길이*폭*깊이)</span>
                <span>- </span>
              </S.ContentWrap>
              <S.ContentWrap>
                <span>손상단계</span>
                <span>- </span>
              </S.ContentWrap>
              <S.ContentWrap>
                <span>조직 유형</span>
                <span>- </span>
              </S.ContentWrap>
              <S.ContentWrap>
                <span>Dressing</span>
                <span>- </span>
              </S.ContentWrap>
              <S.ContentWrap style={{ borderBottom: 'none' }}>
                <span>삼출물</span>
                <span>- </span>
              </S.ContentWrap>
            </S.Card>
          </S.BedsoreContainer>
        </>
      ) : (
        <>
          <S.AddBtnWrap>
            <S.RecordWrap>
              <div>
                <img src={left} alt="left" />
                <span>이전 기록</span>
              </div>
              <S.Separator />
              <div>
                <span>이후 기록</span>
                <img src={right} alt="right" />
              </div>
            </S.RecordWrap>
            <div style={{ display: 'flex', gap: '8px' }}>
              <S.AddBtn background="#FFC000" style={{ color: 'black' }}>
                억제대 동의서 작성 및 확인
              </S.AddBtn>
              <S.AddBtn
                background="#FFC000"
                style={{ color: 'black' }}
                onClick={openAddRestraintBand}
              >
                억제대 적용 추가
              </S.AddBtn>
            </div>
          </S.AddBtnWrap>
          <S.BedsoreContainer>
            <S.Card>
              <div className="date-icon">
                <span>2023-00-00 00:00 AM</span>
                <div className="icon-wrap">
                  <img src={edit} alt="edit-icon" />
                  <img src={remove} alt="remove-icon" />
                </div>
              </div>
              <S.ContentWrap>
                <span>동의서</span>
                <span>유</span>
              </S.ContentWrap>
              <S.ContentWrap>
                <span>억제대 적용부위 순환상태(2hr)</span>
                <span>정상 </span>
              </S.ContentWrap>
              <S.ContentWrap>
                <span>억제대 적용부위 피부상태(2hr)</span>
                <span>정상 </span>
              </S.ContentWrap>
              <S.ContentWrap>
                <span>적용사유</span>
                <span>치료적 목적</span>
              </S.ContentWrap>
              <S.ContentWrap>
                <span>적용부위</span>
                <span>손목</span>
              </S.ContentWrap>
              <S.ContentWrap>
                <span>제거사유</span>
                <span>- </span>
              </S.ContentWrap>
              <S.DamagePrevention>
                <span>손상예방중재</span>
                <div>
                  <span>• &nbsp;적용부위 예방적 드레싱</span>
                  <span>0</span>
                </div>
                <div>
                  <span>• &nbsp;2시간마다 제거 후 사용</span>
                  <span>0</span>
                </div>
                <div>
                  <span>• &nbsp;끈이 풀어지지 않게 묶임</span>
                  <span>0</span>
                </div>
              </S.DamagePrevention>
            </S.Card>
          </S.BedsoreContainer>
        </>
      )}
      <FallModal showFall={showFall} setShowFall={setShowFall} />
      <BedsoreModal showBed={showBed} setShowBed={setShowBed} />
      <AddRestraintBand
        showAddRestraint={showAddRestraint}
        setShowAddRestraint={setShowAddRestraint}
      />
    </S.RightContainer>
  );
}
