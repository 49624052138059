import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  gap: 16px;
  width: 100%;
  .date {
    font-weight: 700;
    font-size: 13px;
  }
`;

export const CircleWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const CircleWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const Circle = styled.div<{ $status: number }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${props =>
    props.$status ? '#604180' : props.theme.color.black_200};
`;

export const ConnectionLine = styled.div`
  flex: 5;
  height: 2px;
  background-color: ${props => props.theme.color.black_200};
`;

export const TextWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Text = styled.span`
  color: ${props => props.theme.color.black_600};
  font-size: 12px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 55vh;
  overflow-y: auto;
`;

export const Card = styled.div`
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  background: #fff;
`;

export const CardWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  .day {
    font-weight: 600;
  }
`;

export const Items = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Btn = styled.button`
  border: 1px solid #8e8e8d;
  border-radius: 4px;
  color: black;
  width: 60px;
  height: 22px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  font-family: 'Spoqa', sans-serif;
`;
