import * as S from '../../../../styles/ObservationStyles/ObservationStyles';

import ActionButtons from './ActionButtons';
import { IClinicalObs } from 'apis/observation/type';

interface Props extends IClinicalObs {
  activeId?: number;
  studentNo: string | undefined;
  nurseName: string;
  refetch?: () => void;
}

const ChestItem = (props: Props) => {
  const {
    activeId,
    studentNo,
    nurseName,
    content,
    create_at,
    option_type,
    refetch,
    xr_clinical_observation_id,
    ...otherProps
  } = props;

  const actionButtons = refetch ? (
    <ActionButtons
      {...otherProps}
      content={content}
      option_type={option_type}
      xr_clinical_observation_id={xr_clinical_observation_id}
      refetch={refetch}
    />
  ) : null;

  const contents = JSON.parse(content);
  const contentKeys = Object.keys(contents) as any[];

  console.log('contents', contents);

  return (
    <div
      style={{ width: '286px', display: 'flex', backgroundColor: '#F2EDFB' }}
    >
      <S.ItemWrap>
        <S.CategoryItemWrap>
          <S.ItemDesc key={contentKeys[0]}>
            <span className="buttontack">
              <span className="timetack">{props.check_time}</span>
              <span>{actionButtons}</span>
            </span>
            <span>
              {contents.chestSize}G # {contents.chestLocation}
            </span>
            <span>
              Dressing: {contents.chestDressing === '1' ? 'intact' : 'cloud'} /
              Bleeding: {contents.chestBleeding === '1' ? '무' : '유'} / 배액:
              {''}
              {contents.chestSwelling === '1' ? 'Clear' : '막힘'} / 색 :{''}
              {contents.chestColor === '1' ? 'Bloody' : 'Serous'} / Air leakage:
              {''}
              {contents.chestAirLeakage === '1' ? '무' : '유'} / Oscillation :
              {''}
              {contents.chestOsillation === '1' ? '무' : '유'} / 피하기종:{''}
              {contents.chestExudate === '1' ? '무' : '유'} / 발적:{''}
              {contents.chestRedness === '1' ? '무' : '유'} / 부종:
              {contents.chestEdema === '1' ? '무' : '유'} / 삼출물:{''}
              {contents.chestWater === '1' ? '무' : contents.chestWaterInput} /
              배액압력:
              {''}
              {contents.chestPress === '1' ? '무' : '유'} / Bubbling:{' '}
              {contents.chestBleeding === '1' ? '무' : '유'}
            </span>
          </S.ItemDesc>
        </S.CategoryItemWrap>
      </S.ItemWrap>
    </div>
  );
};

export default ChestItem;
