import styled from 'styled-components';

export const Cotainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const AddBtn = styled.button`
  border: none;
  border-radius: 4px;
  padding: 10px;
  width: 120px;
  background: ${props => props.theme.color.brand_300};
  color: #fff;
  font-family: 'Spoqa', sans-serif;
  cursor: pointer;
`;

export const Wrap = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`;

export const PainArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1.5;
`;

export const PainHead = styled.div`
  background: #fff;
  border-radius: 4px;
  font-weight: 700;
  font-size: 13px;
  padding: 8px;
`;

export const PainDesc = styled.div`
  background: #fff;
  border-radius: 4px;
  font-weight: 700;
  font-size: 13px;
  padding: 8px;
  height: 55vh;
`;

export const RecordWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  div {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  img {
    width: 20px;
    height: 20px;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const Separator = styled.span`
  height: 12px;
  margin: 0;
  padding: 0;
  border: 0.5px solid ${props => props.theme.color.black_800};
`;

export const RecordDIV = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 8.5;
`;

export const ItemWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .medi {
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
  }
  .none_medi {
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
    margin-right: 12px;
  }
`;

export const ItemVerticalWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: #fff;
  width: 100%;
  max-width: 340px;
`;

export const ItemInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 8px;
`;

export const ItemHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .time {
    font-weight: 500;
    font-size: 14px;
  }
  .btn-wrap {
    display: flex;
    gap: 8px;
  }
  .head {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
  img {
    cursor: pointer;
    width: 15px;
    height: 15px;
  }
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid ${props => props.theme.color.black_200};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const ItemsHead = styled.span`
  display: flex;
  align-items: center;
  /* width: 85px; */
  margin-right: 40px;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  white-space: nowrap;
`;

export const ItemsHeadDummy = styled.span`
  display: flex;
  align-items: center;
  width: 85px;
  /* margin-right: 40px; */
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  white-space: nowrap;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CheckboxWrap = styled.div`
  display: flex;
  flex-direction: row;
  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 42px;
  }
`;
