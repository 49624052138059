import { createGlobalStyle } from 'styled-components';
import Spoqa from '../assets/fonts/SpoqaHanSansNeo.woff2';

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Spoqa';
        src: local('Spoqa'), local('Spoqa');
        font-style: normal;
        src: url(${Spoqa}) format('woff2');
    }
    
    body {
        background-color: #fff;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content : center;
        font-family: Spoqa, sans-serif;
    }

    #root {
       width: 100%;
       height: calc(var(--vh, 1vh) * 100);
       position: relative;
    }   
    
`;

export default GlobalStyle;
