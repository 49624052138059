import styled from 'styled-components';

export const DropdownButton = styled.button`
  padding: 8px;
  margin-right: 19px;
  width: 200px;
  height: 38px;
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  font-family: 'Spoqa', sans-serif;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  direction: row;
`;

export const FontItem = styled.div`
  padding: 10px 0 2px 10px;
  cursor: pointer;
  font-size: 13px;
  display: flex;
  direction: row;
  &:hover {
    background-color: #ddd;
  }
`;

interface OwnProps {
  $visible: boolean;
}

export const DropdownContent = styled.div<OwnProps>`
  display: ${props => (props.$visible ? 'block' : 'none')};
  position: absolute;
  background-color: #f9f9f9;
  min-width: 230px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 39px;
  border-radius: 8px;
  margin-right: 19px;
  /* left: 96px; */
`;

export const DropdownWrapper = styled.div`
  position: relative;
  display: flex;
  direction: row;
  justify-content: right;
`;
