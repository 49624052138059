import * as S from '../../styles/FilloStyles/Info2Styles';
import { IFormRegister, IFormValues } from '../type';
import MuiRadioGroup from 'form/MuiRadioGroup';
import MuiTextField from 'form/MuiTextField';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}
export default function Info2(props: Props) {
  const { disabled, register, getValues, setValue } = props;

  return (
    <S.Layout>
      <S.Container>
        <span className="title">Ⅲ. 입원정보</span>
        <S.ContentsContainer>
          <S.ContentsBox>
            <span className="sub_title">입원경로</span>
            <MuiRadioGroup
              disabled={disabled}
              i18nNullKey="ETC"
              i18nKey="HOSPITALIZATION.PATH"
              values={[1, 2, 0]}
              defaultValue={getValues('information.root.value')}
              onChange={v => setValue('information.root.value', v)}
              width="125px"
            />
            <MuiTextField
              required={false}
              fullWidth={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ width: '84px' }}
              {...register('information.root.input')}
            />
          </S.ContentsBox>
        </S.ContentsContainer>
        <S.ContentsContainer>
          <S.ContentsBox>
            <span className="sub_title">입원방법</span>
            <MuiRadioGroup
              disabled={disabled}
              i18nNullKey="ETC"
              i18nKey="HOSPITALIZATION.WAY"
              values={[1, 2, 3, 0]}
              defaultValue={getValues('information.method.value')}
              onChange={v => setValue('information.method.value', v)}
              width="125px"
            />
            <MuiTextField
              required={false}
              fullWidth={false}
              disabled={disabled}
              placeholder="직접 입력"
              sx={{ width: '84px' }}
              {...register('information.method.input')}
            />
          </S.ContentsBox>
        </S.ContentsContainer>
        <S.ContentsContainer>
          <S.ContentsBox>
            <span className="sub_title2">
              입원동기
              <br />
              (주증상)
            </span>
            <MuiTextField
              required={false}
              fullWidth
              disabled={disabled}
              placeholder="직접 입력"
              {...register('information.motive')}
            />
          </S.ContentsBox>
        </S.ContentsContainer>
      </S.Container>
    </S.Layout>
  );
}
