import { FormControlLabel, styled, TableCell } from '@mui/material';
import { Slider } from '@mui/material';

export const StyledSlider = styled(Slider)`
  color: #604180;
  & .MuiSlider-thumb {
    width: 12;
    height: 12;
  }

  & .MuiSlider-rail {
    color: #00000033;
  }

  & .MuiSlider-mark {
    width: 12;
    height: 12;
    margin-left: -5px;
    border-radius: 8px;
    color: #00000033;

    &.MuiSlider-markActive {
      background-color: #f2edfb;
      opacity: 1;
    }
  }
`;

export const StyledMiniBox = styled('div')`
  width: 75px;
  border: 1px solid #00000080;
  border-radius: 4px;

  color: #00000080;
  font-size: 13px;
  text-align: center;
  word-break: keep-all;
`;

// FLACC
export const StyledFormControlLabel = styled(FormControlLabel)({
  '&.MuiFormControlLabel-root': {
    width: '200px',

    '& .MuiFormControlLabel-label': {
      wordBreak: 'keep-all'
    }
  }
});

// CNPS 선 변경
export const CNPSThreeStyledTableCell = styled(TableCell)({
  border: '1px solid lightgray',
  // borderBottom: '4px solid lightgray',
  borderBottom: 'none',
  padding: '0px'
});

export const CNPSFourStyledTableCell = styled(TableCell)({
  border: '1px solid lightgray',
  borderBottom: '4px solid lightgray',
  borderTop: 'none',
  padding: '0px'
});

export const CNPSStyledTableCell = styled(TableCell)({
  border: '1px solid lightgray',
  borderBottom: '4px solid lightgray',
  padding: '0px'
});

export const CNPSStyledTableCellWithoutLeft = styled(TableCell)({
  border: '1px solid lightgray',
  borderBottom: '4px solid lightgray',
  borderLeft: 'none',
  padding: '0px'
});

export const CNPSStyledTableCellWithoutRight = styled(TableCell)({
  border: '1px solid lightgray',
  borderBottom: '4px solid lightgray',
  borderRight: 'none',
  padding: '0px'
});

export const CNPSStyledTableCellWithoutLeftRight = styled(TableCell)({
  border: '1px solid lightgray',
  borderBottom: '4px solid lightgray',
  borderLeft: 'none',
  borderRight: 'none',
  padding: '0px'
});
