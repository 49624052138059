import { Fragment } from 'react';
import { List, Typography, useTheme } from '@mui/material';

import useI18n from '../../../hook/useI18n';
import { INursingRecord } from 'apis/records/type';
// import { sxRecordItem } from 'routes/Main/style';

import RecordTitle from './RecordTitle';
import ActionButtons from './ActionButtons';

interface Props extends INursingRecord {
  activeId?: number;
  studentNo: string | undefined;
  nurseName: string;
  refetch?: () => void;
}

const RecordItem = (props: Props) => {
  const { palette } = useTheme();
  const i18n = useI18n();
  const {
    activeId,
    studentNo,
    nurseName,
    create_at,
    content,
    nursing_record_id,
    record_type,
    refetch,
    ...otherProps
  } = props;

  const actionButtons = refetch ? (
    <ActionButtons
      {...otherProps}
      content={content}
      record_type={record_type}
      nursing_record_id={nursing_record_id}
      refetch={refetch}
    />
  ) : null;

  const titleProps = {
    actionButtons,
    studentNo: studentNo,
    nurseName: nurseName,
    create_at: create_at,
    record_time: props.record_time,
    title: i18n(`RECORD.${record_type}`)
  };

  const className = activeId === nursing_record_id ? 'active' : '';
  const contents = JSON.parse(content);
  const type = i18n(`RECORD.TYPE.${record_type}`);
  const contentKeys = Object.keys(contents) as any[];

  return (
    <List
      sx={{
        pt: 0,

        '&.active': {
          backgroundColor: palette.grey[100]
        },
        ':not(:first-of-type)': {
          mt: 2,
          px: 1,
          borderTop: '1px solid rgba(0, 0, 0, 0.05)'
        },
        ':not(:last-of-type)': {
          px: 1
        }
      }}
      className={className}
      component="ul"
    >
      <RecordTitle {...titleProps} />
      {contentKeys.map(contentKey => {
        // console.log(contents['sign_sx']);
        if (contentKey === 'diagnosisRelate') {
          return (
            <Fragment key={contentKey}>
              <Typography component="li" variant="caption" fontWeight="bold">
                • 간호진단 (Diagnosis)
              </Typography>
              <Typography
                component="li"
                variant="caption"
                sx={{ pl: 1, whiteSpace: 'pre-wrap' }}
              >
                {contents[contentKey]}
              </Typography>
              <Typography component="li" variant="caption" marginLeft={'8px'}>
                {i18n(`${type}.${contentKey}` as any)}
              </Typography>
            </Fragment>
          );
        } else if (type === 'REMARKS' && contentKey === 'diagnosis') {
          return (
            <Fragment key={contentKey}>
              <Typography
                component="li"
                variant="caption"
                sx={{ pl: 1, whiteSpace: 'pre-wrap' }}
              >
                {contents[contentKey]}
              </Typography>
              <br />
            </Fragment>
          );
        }
        // omaha
        else if (contentKey === 'sign_sx') {
          return (
            <Fragment key={contentKey}>
              <Typography component="li" variant="caption" fontWeight="bold">
                • {i18n(`${type}.${contentKey}` as any)}
              </Typography>
              <Typography
                component="li"
                variant="caption"
                sx={{ pl: 1, whiteSpace: 'pre-wrap' }}
              >
                {contents[contentKey].map((sign: string, i: number) => {
                  if (i === contents[contentKey].length - 1) {
                    return sign;
                  }
                  return sign + ', ';
                })}
              </Typography>
              <br />
            </Fragment>
          );
        } else if (contentKey === 'sign_sx_etc') {
          return (
            <Fragment key={contentKey}>
              <Typography
                component="li"
                variant="caption"
                sx={{
                  pl: 1,
                  whiteSpace: 'pre-wrap',
                  color: '#0000008e',
                  marginTop: '-23px',
                  marginBottom: '23px'
                }}
              >
                {contents[contentKey]}
              </Typography>
            </Fragment>
          );
        } else if (contentKey === 'omaha_target1') {
          return (
            <Fragment key={contentKey}>
              <Typography component="li" variant="caption" fontWeight="bold">
                • 중재방안 (Intrvention Scheme)
              </Typography>
              <Typography
                component="li"
                variant="caption"
                marginLeft={'8px'}
                sx={{ fontSize: '12px' }}
              >
                보건교육, 상담과 지도 (Teaching, Guidance, and Counseling) -
                목표 및 대상별 정보 (Targets and Client-Specific Information)
              </Typography>
              <Typography
                component="li"
                variant="caption"
                sx={{ pl: 1, whiteSpace: 'pre-wrap', color: '#0000008e' }}
              >
                {contents[contentKey]}
              </Typography>
            </Fragment>
          );
        } else if (contentKey === 'omaha_target2') {
          return (
            <Fragment key={contentKey}>
              <Typography
                component="li"
                variant="caption"
                marginLeft={'8px'}
                sx={{ fontSize: '12px' }}
              >
                처치와 절차 (Treatments and Procedures) - 목표 및 대상별 정보
                (Targets and Client-Specific Information)
              </Typography>
              <Typography
                component="li"
                variant="caption"
                sx={{ pl: 1, whiteSpace: 'pre-wrap', color: '#0000008e' }}
              >
                {contents[contentKey]}
              </Typography>
            </Fragment>
          );
        } else if (contentKey === 'omaha_target3') {
          return (
            <Fragment key={contentKey}>
              <Typography
                component="li"
                variant="caption"
                marginLeft={'8px'}
                sx={{ fontSize: '12px' }}
              >
                사례관리 (Case Management) - 목표 및 대상별 정보 (Targets and
                Client-Specific Information)
              </Typography>
              <Typography
                component="li"
                variant="caption"
                sx={{ pl: 1, whiteSpace: 'pre-wrap', color: '#0000008e' }}
              >
                {contents[contentKey]}
              </Typography>
            </Fragment>
          );
        } else if (contentKey === 'omaha_target4') {
          return (
            <Fragment key={contentKey}>
              <Typography
                component="li"
                variant="caption"
                marginLeft={'8px'}
                sx={{ fontSize: '12px' }}
              >
                감독 (Surveillance) - 목표 및 대상별 정보 (Targets and
                Client-Specific Information)
              </Typography>
              <Typography
                component="li"
                variant="caption"
                sx={{ pl: 1, whiteSpace: 'pre-wrap', color: '#0000008e' }}
              >
                {contents[contentKey]}
              </Typography>
            </Fragment>
          );
        } else if (contentKey === 'omaha_knowledge_selct') {
          return (
            <Fragment key={contentKey}>
              <Typography
                component="li"
                variant="caption"
                fontWeight="bold"
                marginTop={3}
              >
                • 결과에 대한 문제측정척도 (Problem Rating Scale for Outcomes)
              </Typography>
              <Typography
                component="li"
                variant="caption"
                marginLeft={'8px'}
                sx={{ fontSize: '12px' }}
              >
                지식 (Knowledge)
              </Typography>
              <Typography
                component="li"
                variant="caption"
                sx={{ pl: 1, whiteSpace: 'pre-wrap', color: '#0000008e' }}
              >
                {contents[contentKey]}
              </Typography>
            </Fragment>
          );
        } else if (contentKey === 'omaha_knowledge') {
          return (
            <Fragment key={contentKey}>
              <Typography
                component="li"
                variant="caption"
                sx={{ pl: 1, whiteSpace: 'pre-wrap', color: '#0000008e' }}
              >
                - {contents[contentKey]}
              </Typography>
            </Fragment>
          );
        } else if (contentKey === 'omaha_behavior_selct') {
          return (
            <Fragment key={contentKey}>
              <Typography
                component="li"
                variant="caption"
                marginLeft={'8px'}
                sx={{ fontSize: '12px' }}
              >
                행동 (Behavior)
              </Typography>
              <Typography
                component="li"
                variant="caption"
                sx={{ pl: 1, whiteSpace: 'pre-wrap', color: '#0000008e' }}
              >
                {contents[contentKey]}
              </Typography>
            </Fragment>
          );
        } else if (contentKey === 'omaha_behavior') {
          return (
            <Fragment key={contentKey}>
              <Typography
                component="li"
                variant="caption"
                sx={{ pl: 1, whiteSpace: 'pre-wrap', color: '#0000008e' }}
              >
                - {contents[contentKey]}
              </Typography>
            </Fragment>
          );
        } else if (contentKey === 'omaha_status_selct') {
          return (
            <Fragment key={contentKey}>
              <Typography
                component="li"
                variant="caption"
                marginLeft={'8px'}
                sx={{ fontSize: '12px' }}
              >
                상태 (Status)
              </Typography>
              <Typography
                component="li"
                variant="caption"
                sx={{ pl: 1, whiteSpace: 'pre-wrap', color: '#0000008e' }}
              >
                {contents[contentKey]}
              </Typography>
            </Fragment>
          );
        } else if (contentKey === 'omaha_status') {
          return (
            <Fragment key={contentKey}>
              <Typography
                component="li"
                variant="caption"
                sx={{ pl: 1, whiteSpace: 'pre-wrap', color: '#0000008e' }}
              >
                - {contents[contentKey]}
              </Typography>
            </Fragment>
          );
        } else {
          return (
            <Fragment key={contentKey}>
              <Typography component="li" variant="caption" fontWeight="bold">
                • {i18n(`${type}.${contentKey}` as any)}
              </Typography>
              <Typography
                component="li"
                variant="caption"
                sx={{ pl: 1, whiteSpace: 'pre-wrap' }}
              >
                {contents[contentKey]}
              </Typography>
              <br />
            </Fragment>
          );
        }
      })}
    </List>
  );
};

export default RecordItem;
