import { useForm } from 'react-hook-form';
import { initialVsState } from './initialState';
import IOGraph from './IOGraph';
import IORecord from './IORecord';

import * as S from '../../../styles/ObservationStyles/VitalStyles';

export default function IntakeOutput() {
  const { handleSubmit, watch, getValues, setValue } = useForm({
    defaultValues: initialVsState
  });

  const formProps = {
    watch,
    getValues,
    setValue
  };

  return (
    <div>
      <S.GraphBox>
        <IOGraph {...formProps} />
      </S.GraphBox>
      <IORecord {...formProps} />
    </div>
  );
}
