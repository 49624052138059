import apiGateway from '../axios';
import { formatToRequestParameter } from '../formatting';
import { IPostClinicalObservation, IGetSurvey, IGetClinicalObs } from './type';

export const createObservation = (request: IPostClinicalObservation) => {
  const url = `/enr_etc/xr/create/clinical_observation
  `;
  return apiGateway.post(url, request);
};

export const getObservation = (request: IGetClinicalObs) => {
  const url = `/enr_etc/xr/get/clinical_observation?${formatToRequestParameter(request)}`;
  return apiGateway.get(url);
};
