import { Grid, Skeleton } from '@mui/material';
import {
  SurveyDialogProps,
  TOperationDefaultValues
} from 'components/Main/type';
import { useForm } from 'react-hook-form';
import usePatient from 'store/patient/usePatient';
import * as S from '../../styles/NursingStyles/NursingStyles';
import InfoOP1 from './InfoOP1';
import InfoOP2 from './InfoOP2';
import InfoOP3 from './InfoOP3';
import PreOpForBorder from './Operation';

export default function Nursing(
  props: SurveyDialogProps<TOperationDefaultValues>
) {
  const { defaultValues } = props;

  const { patientInfo } = usePatient();
  const { register, watch, setValue, getValues, handleSubmit } = useForm({
    defaultValues
  });

  if (!patientInfo) {
    return <Skeleton variant="rectangular" sx={{ flex: 1 }} />;
  }

  const { patient_id, name, age, gender, room, department } = patientInfo;

  const formProps = {
    watch,
    register,
    getValues,
    setValue
  };

  return (
    <>
      <S.Layout>
        <S.HeaderBox>
          <S.Title>
            <span>{name}</span>
            <span>/</span>
            <span>{gender === 1 ? '여자' : '남자'}</span>
            <span>/</span>
            <span>
              {age} ({patient_id}) {department}
            </span>
            <span>/</span>
            {/* 이 10-1은 병동이라고함 */}
            <span>{room}</span>
          </S.Title>
          <S.ButtonWithTimeBox>
            <span>최근 저장한 시간 : 2022-10-12 13:30 PM </span>
            <S.Button type="submit">저장</S.Button>
          </S.ButtonWithTimeBox>
        </S.HeaderBox>
        <Grid
          container
          wrap="wrap"
          rowSpacing={5}
          columnSpacing={2}
          sx={{ py: 4, px: 2 }}
        >
          <InfoOP1 {...formProps} />
          <InfoOP2 {...formProps} />
          <InfoOP3 {...formProps} />
          <PreOpForBorder {...formProps} />
        </Grid>
      </S.Layout>
    </>
  );
}
