import * as S from '../../styles/ObservationStyles/ObservationStyles';
import { useState } from 'react';
import Clinical from './ClinicalObservation/Clinical';
import VitalSign from './VitalSign/VitalSign';
import PainCondition from './PainCondition/PainCondition';

import usePatient from 'store/patient/usePatient';
import { Skeleton } from '@mui/material';
import IntakeOutput from './IntakeOutput/IntakeOutput';

export default function Observation() {
  const [category, setCategory] = useState<number>(1);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleCategoryClick = (categoryNumber: number) => {
    setCategory(categoryNumber);
  };

  const { patientInfo } = usePatient();

  if (!patientInfo) {
    return <Skeleton variant="rectangular" sx={{ flex: 1 }} />;
  }

  const { patient_id, name, age, gender, department, room } = patientInfo;

  return (
    <>
      <></>
      <S.Layout style={{ height: category !== 1 ? 'calc(100% - 240px)' : '' }}>
        <S.Container>
          <S.Head>
            <span className="patient">
              {name} / {gender === 1 ? '여자' : '남자'} /{age} ({patient_id})
              {department} / {room}
            </span>
            <S.BtnWrap>
              <S.CategoryBtn
                selected={category === 1}
                onClick={() => handleCategoryClick(1)}
              >
                임상관찰
              </S.CategoryBtn>
              <S.CategoryBtn
                selected={category === 2}
                onClick={() => handleCategoryClick(2)}
              >
                V/S
              </S.CategoryBtn>
              <S.CategoryBtn
                selected={category === 3}
                onClick={() => handleCategoryClick(3)}
              >
                I/O
              </S.CategoryBtn>
              <S.CategoryBtn
                selected={category === 4}
                onClick={() => handleCategoryClick(4)}
              >
                통증사정
              </S.CategoryBtn>
            </S.BtnWrap>
          </S.Head>
          {category === 1 && (
            <Clinical showModal={showModal} setShowModal={setShowModal} />
          )}
          {category === 2 && <VitalSign />}
          {category === 3 && <IntakeOutput />}
          {category === 4 && (
            <PainCondition showModal={showModal} setShowModal={setShowModal} />
          )}
        </S.Container>
      </S.Layout>
      <S.Overlay $show={showModal} />
    </>
  );
}
