import * as S from '../../styles/SafetyStyles/SafetyStyles';
import frontBody from '../../assets/img/frontBody.png';
import backBody from '../../assets/img/backBody.png';
import { useState } from 'react';

interface OwnProps {
  viewType: number;
}

export default function Left({ viewType }: OwnProps) {
  const [isFront, setIsFront] = useState<boolean>(true);
  return (
    <S.LeftContainer>
      <S.ViewTypeTitle>
        <span>{viewType ? '억제대 부위' : '욕창 부위'}</span>
      </S.ViewTypeTitle>
      <S.BodyContainer>
        {viewType ? null : (
          <>
            <S.BtnWrap style={{ justifyContent: 'flex-end' }}>
              <S.CategoryBtn
                style={{ width: '40px' }}
                $selected={isFront}
                onClick={() => setIsFront(true)}
              >
                앞
              </S.CategoryBtn>
              <S.CategoryBtn
                style={{ width: '40px' }}
                $selected={!isFront}
                onClick={() => setIsFront(false)}
              >
                뒤
              </S.CategoryBtn>
            </S.BtnWrap>
            <img src={isFront ? frontBody : backBody} alt="human-body" />
          </>
        )}
      </S.BodyContainer>
    </S.LeftContainer>
  );
}
