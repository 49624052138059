import MuiTextField from 'form/MuiTextField';
import MuiRadioGroup from 'form/MuiRadioGroup';
import * as S from '../../../styles/ObservationStyles/ModalStyles';
import { IFormRegister, IFormValues } from '../../type';
import { ReactComponent as Body } from 'assets/img/body.svg';

interface Props extends IFormRegister, IFormValues {}

export default function TunnelCath(props: Props) {
  const { register, getValues, setValue } = props;
  return (
    <S.ContentContainer>
      <S.Title>Tunnel cath / Non-Tunnel cath</S.Title>
      <S.ItemWrap>
        <S.ItemsHead>종류</S.ItemsHead>
        <MuiRadioGroup
          i18nKey="EXIST.TUNNELCATH"
          values={[1, 2, 3, 4, 5, 6]}
          defaultValue={getValues('tunnelSize')}
          onChange={v => setValue('tunnelSize', v)}
          width="130px"
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>위치</S.ItemsHead>
        <MuiTextField
          required={false}
          sx={{
            minWidth: '130px',
            width: '130px',
            marginLeft: '10px'
          }}
          placeholder="직접 입력"
          {...register('tunnelLocation')}
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <Body />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>부종</S.ItemsHead>
        <MuiRadioGroup
          i18nKey="EXIST.SHORT"
          values={[1, 2]}
          defaultValue={getValues('tunnelSwelling')}
          onChange={v => setValue('tunnelSwelling', v)}
          width="130px"
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>발적</S.ItemsHead>
        <MuiRadioGroup
          i18nKey="EXIST.SHORT"
          values={[1, 2]}
          defaultValue={getValues('tunnelRedness')}
          onChange={v => setValue('tunnelRedness', v)}
          width="130px"
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>삼출물</S.ItemsHead>
        <MuiRadioGroup
          i18nKey="EXIST.EXUDATION"
          values={[1, 2]}
          defaultValue={getValues('tunnelExudate')}
          onChange={v => setValue('tunnelExudate', v)}
          width="130px"
        />
        <MuiTextField
          required={false}
          sx={{
            minWidth: '130px',
            width: '400px',
            marginLeft: '-70px'
          }}
          placeholder="직접 입력"
          {...register('tunnelExudateInput')}
        />
      </S.ItemWrap>
    </S.ContentContainer>
  );
}
