import { Box, Card } from '@mui/material';
import { IPatientInfo } from 'apis/admin/type';
import { getObservation } from 'apis/observation/observationApi';
import useInfiniteScroll from 'hook/useInfiniteScroll';
import { useRef } from 'react';
import useUser from 'store/user/useUser';
import RecordList from './RecordList';

interface Props {
  patientInfo: IPatientInfo;
}

const ClinicalRecord = ({ patientInfo }: Props) => {
  const { student_uuid: user_id } = useUser();

  const moreRef = useRef(null);
  const { list, onResetList } = useInfiniteScroll({
    moreRef,
    listKey: 'xr_clinical_observations',
    getApi: () =>
      getObservation({
        user_id,
        patient_id: patientInfo?.patient_id
      })
  });

  return (
    <Box flex={1} display="flex" flexDirection="column" overflow="auto">
      <Card
        component="section"
        sx={{
          p: '10px 15px',
          height: '100%',
          overflow: 'hidden',
          backgroundColor: '#F2EDFB',
          width: '73vw'
        }}
      >
        <RecordList list={list} onResetList={onResetList} />
        <div ref={moreRef} />
      </Card>
    </Box>
  );
};

export default ClinicalRecord;
