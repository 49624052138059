export const initialClinicalObs = {
  ivCath: {
    ivSize: '',
    ivLocation: '',
    ivSwelling: '',
    ivRedness: '',
    ivExudate: '',
    ivExudateInput: ''
  },
  tunnel: {
    tunnelSize: '',
    tunnelLocation: '',
    tunnelSwelling: '',
    tunnelRedness: '',
    tunnelExudate: '',
    tunnelExudateInput: ''
  },
  tTube: {
    tSize: '',
    tLocation: '',
    tSwelling: '',
    tRedness: '',
    tExudate: '',
    tExudateInput: ''
  },
  lTube: {
    lSize: '',
    lLocation: '',
    lSwelling: '',
    lRedness: '',
    lExudate: ''
  },
  chestTube: {
    chestSize: '',
    chestLocation: '',
    chestDressing: '',
    chestAirLeakage: '',
    chestEdema: '',
    chestBleeding: '',
    chestOsillation: '',
    chestWater: '',
    chestWaterInput: '',
    chestSwelling: '',
    chestExudate: '',
    chestPress: '',
    chestColor: '',
    chestRedness: '',
    chestBubbling: ''
  },
  jpBagHemovac: {
    jpLocation: '',
    jpLocationLisk: '',
    jpAmount: '',
    jpPattern: '',
    jpColor: '',
    hvLocation: '',
    hvLocationLisk: '',
    hvAmount: '',
    hvPattern: '',
    hvColor: ''
  }
};
