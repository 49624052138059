import * as S from '../../styles/RecordsStyles/DARStyles';
import { IFormRegister } from '../type';

interface Props extends IFormRegister {
  disabled?: boolean;
}

export default function DAR({ register, disabled }: Props) {
  return (
    <S.Layout>
      <S.Container>
        <S.IntputWrap>
          <S.Title>데이터 Data</S.Title>
          <S.Input {...register('subjective')} />
        </S.IntputWrap>
        <S.IntputWrap>
          <S.Title>활동 Action</S.Title>
          <S.Input {...register('objective')} />
        </S.IntputWrap>
        <S.IntputWrap>
          <S.Title>반응 Response</S.Title>
          <S.Input {...register('assessment')} />
        </S.IntputWrap>
      </S.Container>
    </S.Layout>
  );
}
