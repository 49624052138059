import * as S from '../../styles/CommonStyles/DropdownMenuStyles';

import React, { useState, useEffect, useRef, memo } from 'react';
import {
  AccountCircleOutlined,
  LogoutOutlined,
  KeyboardArrowDownOutlined
} from '@mui/icons-material';
import { Typography, Box } from '@mui/material';
import useStudent from 'store/student/useStudent';
import useSurvey from 'store/survey/useSurvey';
import usePatient from 'store/patient/usePatient';
import useUser from 'store/user/useUser';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/img/xr-logo.svg';

const fonts = [
  {
    label: 'Account Settings'
  },
  {
    label: 'Log out'
  }
];

interface DropdownMenuProps {
  target: string;
}

function DropdownMenu({ target }: DropdownMenuProps) {
  const { onSignOut, student_name } = useUser();
  const { onResetStudent } = useStudent();
  const { onResetPatient, patientInfo } = usePatient();
  const { onCloseReadOnly } = useSurvey();
  const navigate = useNavigate();

  const [$visible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleButtonClick = () => {
    setVisible(!$visible);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref, setVisible]);

  const handleFontSelect = (font: string) => {
    setVisible(false);
    if (font === 'Log out') {
      onSignOut();
      // 로그아웃 시 스토어 초기화
      onResetStudent();
      onResetPatient();
      onCloseReadOnly();
      navigate('/login');
    } else if (font === 'Screen Settings') {
      navigate('/screensetting');
      return;
    } else if (font === 'Account Settings') {
      navigate('/mypage');
      return;
    }
  };

  return (
    <S.DropdownWrapper ref={ref}>
      <S.DropdownButton type="button" onClick={() => handleButtonClick()}>
        <Box
          // height={25}
          component="img"
          alt={'SmartNurse'}
          src={logo}
          sx={{ objectFit: 'contain', marginTop: '-10px' }}
        />
        <div
          style={{
            marginLeft: '15px',
            width: 'auto',
            whiteSpace: 'nowrap',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'right',
            cursor: 'pointer',
            marginTop: '-3px'
          }}
        >
          <Typography sx={{ fontSize: '8px', textAlign: 'right' }}>
            간호사
          </Typography>
          {student_name}
        </div>
        <KeyboardArrowDownOutlined
          sx={{ marginLeft: '8px', marginTop: '5px', cursor: 'pointer' }}
        />
      </S.DropdownButton>
      <S.DropdownContent $visible={$visible}>
        <div
          style={{
            flexDirection: 'column',
            justifyContent: 'right'
          }}
        >
          {fonts.map(v => {
            return (
              <S.FontItem
                key={v.label}
                onClick={() => handleFontSelect(v.label)}
              >
                <div style={{ paddingTop: '4px' }}>
                  {v.label === 'Account Settings' ? (
                    <AccountCircleOutlined sx={{ fontSize: '15px' }} />
                  ) : (
                    <LogoutOutlined sx={{ fontSize: '15px' }} />
                  )}
                </div>
                <div style={{ marginLeft: '10px' }}>{v.label}</div>
              </S.FontItem>
            );
          })}
        </div>
      </S.DropdownContent>
    </S.DropdownWrapper>
  );
}

export default memo(DropdownMenu);
