import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import * as S from '../../styles/RecordsStyles/CreateStyles';
import DAR from './DAR';
import NANDA from './NANDA';
import Remark from './Remark';
import {
  getNandaDomain,
  createNursingRecord,
  updateNursingRecord
} from 'apis/records/recordsApi';

import { initialNursingRecord } from './initialStates';

import { INames, RECORD_TYPE } from 'apis/records/type';
import usePatient from 'store/patient/usePatient';
import { findKeyValue } from 'apis/convert';
import { requiredSelect } from 'apis/requiredItems';
import { format } from 'date-fns';
import { useLocation, useNavigate } from 'react-router';
import useUser from 'store/user/useUser';

import { MobileTimePicker } from '@mui/x-date-pickers';
import { AccessTime } from '@mui/icons-material';

import MuiTextField from 'form/MuiTextField';

import { TabContext, TabList } from '@mui/lab';

export default function CreateRecord() {
  const { student_uuid } = useUser();
  const { nursingRecord, patientInfo, onClearNursingRecord } = usePatient();

  console.log('student_uuid', student_uuid);

  const { register, watch, setValue, handleSubmit, reset, getValues } =
    useForm();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [recordType, setRecordType] = useState<string>(RECORD_TYPE.NANDA);
  const [recordTime, setRecordTime] = useState<Date | null>(new Date());

  const [domainNames, setDomainNames] = useState<INames[]>([]);
  useEffect(() => {
    if (domainNames.length !== 0) return;
    getNandaDomain().then(({ data }) => setDomainNames(data.names));
  }, [domainNames]);

  const onRestNursingRecord = useCallback(() => {
    setRecordTime(new Date());
    setRecordType(RECORD_TYPE.NANDA);
    reset(initialNursingRecord.nanda);
    onClearNursingRecord();
  }, [reset, onClearNursingRecord]);

  useEffect(() => {
    if (!nursingRecord) return onRestNursingRecord();

    const { record_time, record_type, content } = nursingRecord;
    reset(JSON.parse(content));
    setRecordType(`${record_type}`);
    setRecordTime(new Date(record_time));
  }, [nursingRecord, reset, onRestNursingRecord]);

  const onSubmit = (data: any) => {
    // 간호기록 시간 입력 체크
    if (!recordTime) return alert('시간입력해주세요');

    let contentKeys: string[] = [];

    // update content
    switch (recordType) {
      case RECORD_TYPE.NANDA: {
        contentKeys = Object.keys(initialNursingRecord.nanda);
        break;
      }
      case RECORD_TYPE.DAR: {
        contentKeys = Object.keys(initialNursingRecord.soapie);
        break;
      }
      case RECORD_TYPE.NARRATIVE_RECORD: {
        contentKeys = Object.keys(initialNursingRecord.narrativeRecord);
        break;
      }
    }

    const content = findKeyValue(data, contentKeys);

    // nanda 필수값 체크
    if (recordType === RECORD_TYPE.NANDA) {
      if (!requiredSelect(content?.domain)) {
        return alert('도메인입력필요');
      }
      if (!requiredSelect(content?.class)) {
        return alert('클래스입력필요');
      }
      if (!requiredSelect(content?.diagnosis)) {
        return alert('진단입력필요');
      }
    }

    const request = {
      recordType: Number(recordType),
      record_time: format(recordTime, 'yyyy-MM-dd HH:mm'),
      content: JSON.stringify(content)
    };

    // 간호기록 수정
    if (nursingRecord) {
      return updateNursingRecord({ ...nursingRecord, ...request })
        .then(() => {
          onRestNursingRecord();
          navigate(`${pathname}?isUpdateNursingRecord=true`);
          alert('간호기록을 수정하였습니다.');
        })
        .catch(e => alert(`간호기록 수정 실패하였습니다. ${e}`));
    }

    // 간호기록 생성
    createNursingRecord({
      userId: student_uuid,
      patient_id: patientInfo!.patient_id,
      ...request
    })
      .then(() => {
        onRestNursingRecord();
        navigate(`${pathname}?isUpdateNursingRecord=true`);
        alert('간호기록을 저장하였습니다.');
      })
      .catch(e => alert(`간호기록 저장 실패하였습니다. ${e}`));
  };

  return (
    <S.Layout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.Container>
          <S.HeaderBox>
            <S.Title>간호 기록 작성</S.Title>
            <MobileTimePicker
              value={recordTime}
              onChange={setRecordTime}
              renderInput={params => (
                <MuiTextField
                  {...params}
                  fullWidth={false}
                  sx={{ width: 130 }}
                  InputProps={{ endAdornment: <AccessTime /> }}
                />
              )}
            />
          </S.HeaderBox>
          <S.ButtonWrap>
            <TabContext value={recordType}>
              <TabList
                variant="fullWidth"
                onChange={(_, value) => {
                  setRecordType(value);
                }}
              >
                <S.StyledTab label="NANDA" value={RECORD_TYPE.NANDA} />
                <S.StyledTab label="DAR" value={RECORD_TYPE.DAR} />
                <S.StyledTab
                  label="서술기록"
                  value={RECORD_TYPE.NARRATIVE_RECORD}
                />
              </TabList>
              <S.ContentsContainer>
                <S.StyledTabPanel value={RECORD_TYPE.NANDA}>
                  <NANDA
                    watch={watch}
                    setValue={setValue}
                    register={register}
                    domainNames={domainNames}
                  />
                </S.StyledTabPanel>
                <S.StyledTabPanel value={RECORD_TYPE.DAR}>
                  <DAR register={register} />
                </S.StyledTabPanel>
                <S.StyledTabPanel value={RECORD_TYPE.NARRATIVE_RECORD}>
                  <Remark register={register} />
                </S.StyledTabPanel>

                <S.SaveButtonWrap>
                  <S.CancleButton type="button" onClick={onRestNursingRecord}>
                    취소
                  </S.CancleButton>
                  <S.SaveButton type="submit">저장</S.SaveButton>
                </S.SaveButtonWrap>
              </S.ContentsContainer>
            </TabContext>
          </S.ButtonWrap>
        </S.Container>
      </form>
    </S.Layout>
  );
}
