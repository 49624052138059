import styled from 'styled-components';
interface MenuProps {
  $isSelected: boolean;
  $isDisabled: boolean | undefined;
}

export const Layout = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => props.theme.color.brand_100};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 240px;
  background: ${props => props.theme.color.brand_300};
  position: fixed;
  z-index: 100;
`;

export const BodyContainer = styled.div`
  height: calc(100%-240px);
  padding-top: 240px;
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin: 16px;
`;

export const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 70px;
    height: 45px;
  }
`;

export const ToolbarBox = styled.div`
  height: 30px;
  padding-top: 10px;
  width: 100%;
  padding-bottom: 5px;
  white-space: pre-wrap;
  background-color: white;
  border-radius: 4px;
`;

export const DropDown = styled.div`
  /* width: 200px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #eef5ff;
  border-radius: 4px;
  cursor: pointer;
  .user {
    display: flex;
    flex-direction: column;
  }
  .logo {
    margin-right: 10px;
  }
  .role {
    font-size: 10px;
    margin-left: auto;
  }
  .name {
    font-size: 14px;
  }
  img {
    width: 8px;
    height: 5px;
    margin-left: 12px;
  } */
`;

export const SearchContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const SearchInput = styled.input`
  width: calc(100% - 20px);
  height: 28px;
  padding: 8px 10px;
  font-family: 'Spoqa', sans-serif;
  outline: none;
  border: none;
  border-radius: 4px;
`;

export const SearchIcon = styled.img`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  cursor: pointer;
`;

export const MenuContainer = styled.div`
  border-radius: 4px;
  background: ${props => props.theme.color.brand_200};
`;

export const MenuWrap = styled.div`
  margin: 12px;
  gap: 12px;
  display: flex;
  align-items: center;
`;

export const Menu = styled.div<MenuProps>`
  width: 100%;
  height: 62px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
  border-radius: 4px;
  color: ${props =>
    props.$isSelected
      ? props.theme.color.brand_300
      : props.theme.color.black_500};
  font-weight: ${props => (props.$isSelected ? 'bold' : '500')};
  cursor: ${props =>
    props.$isDisabled
      ? 'not-allowed'
      : 'pointer'}; /* Set cursor to not-allowed if disabled */
  pointer-events: ${props =>
    props.$isDisabled
      ? 'none'
      : 'auto'}; /* Disable pointer events if disabled */
  opacity: ${props =>
    props.$isDisabled ? '0.5' : '1'}; /* Reduce opacity if disabled */

  img {
    width: 26px;
    height: 22px;
    filter: ${({ $isSelected }) =>
      $isSelected
        ? 'invert(28%) sepia(38%) saturate(794%) hue-rotate(227deg) brightness(92%) contrast(91%)'
        : 'invert(55%) sepia(0%) saturate(2529%) hue-rotate(177deg) brightness(102%) contrast(95%)'};
  }
  span {
    font-size: 13px;
  }
  background-color: ${props =>
    props.$isSelected ? '#fff' : props.theme.color.brand_100};
`;
