import * as S from '../../styles/InfoStyles/SpecialStyles';
import usePatient from 'store/patient/usePatient';
import { Skeleton } from '@mui/material';

export default function Special() {
  const { patientInfo } = usePatient();

  if (!patientInfo) {
    return <Skeleton variant="rectangular" sx={{ flex: 1 }} />;
  }

  return (
    <S.Layout>
      <S.Container>
        <S.TitleBtnWrap>
          <span className="title"> 특이사항 메모</span>
          {/* <S.AdditionalBtn>추가 처방</S.AdditionalBtn> */}
        </S.TitleBtnWrap>

        <S.Contents>
          <S.Inner>
            <S.Medication>
              <span className="medi">
                Dx. RDS로 NICU에서 PPV 시행하며 치료 받은 후 금일 PED adm.
                <br />
                HD 10
                <br />
                NPO 해제 {'>'} PM 60cc q3hrs
                <br />
                O2 nasal 2L/m {'>'} 3PM RR 65회/분, SpO2 88% 및 dyspnea 양상
                보여 Dr. 여지영 noti 후 3L/min으로 증량. {'>'} 호전되어
                2L/분으로 감량
              </span>
            </S.Medication>
          </S.Inner>
        </S.Contents>
      </S.Container>
    </S.Layout>
  );
}
