import * as S from '../../../styles/ObservationStyles/PainConditionStyle';

import { Box, Checkbox, FormHelperText, Grid, Typography } from '@mui/material';
import { IFormRegister, IFormValues } from '../../type';
import { ReactComponent as Body } from 'assets/img/body.svg';
import KOOSContents from './KOOSContents/KOOSContents';
import KOOSContents3 from './KOOSContents/KOOSContents3';
import KOOSContents4 from './KOOSContents/KOOSContents4';
import KOOSContents2 from './KOOSContents/KOOSContents2';
import MuiTextField from 'form/MuiTextField';
import adornment from 'form/adornment';
import { useState } from 'react';
import MuiRadioGroup from 'form/MuiRadioGroup';

interface Props extends IFormRegister, IFormValues {}

export default function KOOS(props: Props) {
  const { register, getValues, setValue } = props;
  const [inputError, setInputError] = useState(false);

  const [painScore, setPainScore] = useState(
    getValues('koosScore') !== undefined ? getValues('koosScore') : 0
  );
  const formProps = {
    register,
    getValues,
    setValue
  };

  return (
    <div>
      <Typography
        sx={{ fontSize: '20px', fontWeight: 700, whiteSpace: 'nowrap' }}
      >
        Knee injury and Osteoarthritis Outcome Score (KOOS)
      </Typography>
      {/* 증상 - 경직성 */}
      <KOOSContents {...formProps} />
      {/* 통증 */}
      <KOOSContents2 {...formProps} />
      {/* 일상 생활 기능 */}
      <KOOSContents3 {...formProps} />
      {/* 운동 - 삶의질 */}
      <KOOSContents4 {...formProps} />
      <Grid item xs={12}>
        <Box sx={{ width: '100%', marginTop: '40px', marginBottom: '12px' }}>
          <S.ContentContainer>
            <S.ItemWrap>
              <S.ItemsHead>통증점수</S.ItemsHead>
              <MuiTextField
                required={false}
                sx={{
                  minWidth: '130px',
                  width: '130px'
                }}
                InputProps={{ ...adornment('', '점') }}
                onChange={({ target: { value } }) => {
                  setPainScore(value);
                  setValue('koosScore', value);

                  const numValue = Number(value);
                  if (
                    value === '' ||
                    (numValue >= 0 &&
                      numValue <= 10 &&
                      Number.isInteger(numValue))
                  )
                    setInputError(false);
                  else setInputError(true);
                }}
                error={
                  painScore === '' ||
                  (Number(painScore) >= 0 &&
                    Number(painScore) <= 10 &&
                    Number.isInteger(Number(painScore)))
                    ? false
                    : true
                }
              />
              {inputError ? (
                <FormHelperText error={true}>
                  PAIN SCORE 값은 0 이상 10 이하의 정수입니다.
                </FormHelperText>
              ) : null}
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증부위</S.ItemsHead>
              <MuiTextField
                required={false}
                placeholder="직접 입력"
                {...register('koosWay')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <Body />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증양상</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.PATTERN"
                values={[1, 2]}
                defaultValue={getValues('koosPattern')}
                onChange={v => setValue('koosPattern', v)}
                width="100px"
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증빈도</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.FREQUENCY"
                values={[1, 2, 3]}
                defaultValue={getValues('koosFrequency')}
                onChange={v => setValue('koosFrequency', v)}
                width="100px"
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증기간</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.LONG"
                values={[1, 2]}
                defaultValue={getValues('koosLong')}
                onChange={v => setValue('koosLong', v)}
                width="100px"
              />
              <MuiTextField
                required={false}
                placeholder="직접 입력"
                {...register('koosLongInput')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증중재</S.ItemsHead>
              <span className="medi">비약물적 중재</span>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('koosNoneMediChecked1'))}
                  {...register('koosNoneMediChecked1')}
                  onChange={(e, checked) => {
                    setValue('koosNoneMediChecked1', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>심호흡 방법 교육</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('koosNoneMediChecked2'))}
                  {...register('koosNoneMediChecked2')}
                  onChange={(e, checked) => {
                    setValue('koosNoneMediChecked2', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>냉찜질 시행</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('koosNoneMediChecked3'))}
                  {...register('koosNoneMediChecked3')}
                  onChange={(e, checked) => {
                    setValue('koosNoneMediChecked3', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>휴식</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('koosNoneMediChecked4'))}
                  {...register('koosNoneMediChecked4')}
                  onChange={(e, checked) => {
                    setValue('koosNoneMediChecked4', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>기타</span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('koosNoneMediInput')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHeadDummy></S.ItemsHeadDummy>
              <span className="none_medi">약물적 중재</span>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('koosMediChecked'))}
                  {...register('koosMediChecked')}
                  onChange={(e, checked) => {
                    setValue('koosMediChecked', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>약물명 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('koosMediInput1')}
              />
              <S.CheckboxWrap>
                <span>용량 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('koosMediInput2')}
              />
              <S.CheckboxWrap>
                <span>투여방법 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('koosMediInput3')}
              />
            </S.ItemWrap>
          </S.ContentContainer>
        </Box>
      </Grid>
    </div>
  );
}
