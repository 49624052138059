import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPatient, IPatientInfo } from 'apis/info/type';
import { IUpdateClinicalObs } from 'apis/observation/type';
import { IUpdateNursingRecord } from 'apis/records/type';

// name, initialState, reducers.
export interface PatientState {
  patient?: IPatient | null;
  patientInfo?: IPatientInfo | null;
  nursingRecord?: IUpdateNursingRecord | null;
  xrClinicalObservation?: IUpdateClinicalObs | null;
}

const initialState: PatientState = {
  patient: null,
  patientInfo: null,
  nursingRecord: null,
  xrClinicalObservation: null
};

export const patientSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    resetPatient: () => initialState,
    selectedPatient(state, action: PayloadAction<IPatient | null>) {
      state.patient = action.payload;
      state.patientInfo = null;
      state.nursingRecord = null;
      state.xrClinicalObservation = null;
    },
    selectedPatientInfo(state, action: PayloadAction<IPatientInfo | null>) {
      state.patientInfo = action.payload;
    },
    selectedNursingRecord(state, action: PayloadAction<IUpdateNursingRecord>) {
      state.nursingRecord = action.payload;
    },
    clearNursingRecord(state) {
      state.nursingRecord = null;
    },
    selectedClinicalObs(state, action: PayloadAction<IUpdateClinicalObs>) {
      state.xrClinicalObservation = action.payload;
    },
    clearClinicalObs(state) {
      state.xrClinicalObservation = null;
    }
  }
});

export const {
  resetPatient,
  selectedPatient,
  selectedPatientInfo,
  selectedNursingRecord,
  clearNursingRecord,
  selectedClinicalObs,
  clearClinicalObs
} = patientSlice.actions;

export default patientSlice.reducer;
