import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px;
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ContentWrap = styled.div`
  display: flex;
  margin-top: 8px;
  gap: 8px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
  flex: 6.8;
`;

export const ItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
`;

export const ItemTitle = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  text-align: center;
  span {
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
  }
  span:first-child {
    text-align: left;
  }
`;

export const ItemDesc = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  text-align: center;
  margin-top: 4px;
  padding-bottom: 2px;
  span {
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
  }
  span:first-child {
    text-align: left;
  }
`;

export const Reference = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
  flex: 3.2;
  transition: 0.3s;
`;

export const ReferWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
`;

export const ReferTitle = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  text-align: center;
  span {
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
  }
`;

export const ReferDesc = styled.div`
  display: flex;
  border-bottom: 1px solid #dddddd;
  text-align: center;
  margin-top: 4px;
  padding-bottom: 2px;
  span {
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
  }
`;

export const ToggleContainer = styled.div`
  position: relative;
  cursor: pointer;

  > .toggle-container {
    width: 40px;
    height: 18px;
    border-radius: 100px;
    padding: 2px 4px 2px 4px;
    background-color: #dbdbdb;
  }
  > .toggle--checked {
    background-color: ${props => props.theme.color.brand_300};
    transition: 0.3s;
  }

  > .toggle-circle {
    position: absolute;
    top: 2px;
    left: 3px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: rgb(255, 254, 255);
    transition: 0.3s;
  }
  > .toggle--checked {
    left: 28px;
    transition: 0.3s;
  }
`;
