import * as S from '../../../styles/ObservationStyles/PainConditionStyle';

import { Box, Checkbox, FormHelperText, Grid, Typography } from '@mui/material';
import { IFormRegister, IFormValues, IFormWatch } from '../../type';
import { ReactComponent as Body } from 'assets/img/body.svg';
import useTableForm from 'form/useTableForm';
import MuiTable from 'form/MuiTable';
import MuiTextField from 'form/MuiTextField';
import MuiRadioGroup from 'form/MuiRadioGroup';
import adornment from 'form/adornment';

interface Props extends IFormRegister, IFormValues, IFormWatch {}

export default function LEFS(props: Props) {
  const { register, getValues, setValue } = props;
  const { radioGroup, sumValues } = useTableForm(props);

  const columns = [
    { fieldId: 'title', label: '' },
    { fieldId: '0', label: '심하게 어려움(0점)' },
    { fieldId: '1', label: '상당히 활동 어려움(1점)' },
    { fieldId: '2', label: '보통 어려움(2점)' },
    { fieldId: '3', label: '조금 어려움(3점)' },
    { fieldId: '4', label: '어려움 없음(4점)' }
  ];

  const rows = [
    {
      id: 'lefs01',
      title: '1. 집안일, 학교생활 등 평상시에 하는 일',
      ...radioGroup({
        key: 'lefs01',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs02',
      title: '2. 취미생활, 레크레이션, 스포츠 활동',
      ...radioGroup({
        key: 'lefs02',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs03',
      title: '3. 욕조에 들어가거나 나오기',
      ...radioGroup({
        key: 'lefs03',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs04',
      title: '4. 방에서 다른 방으로 걸어서 이동',
      ...radioGroup({
        key: 'lefs04',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs05',
      title: '5. 신발이나 양말 신기',
      ...radioGroup({
        key: 'lefs05',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs06',
      title: '6. 쪼그려 앉기',
      ...radioGroup({
        key: 'lefs06',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs07',
      title: '7. 바닥에 놓은 가방이나 장바구니를 들어올리기',
      ...radioGroup({
        key: 'lefs07',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs08',
      title: '8. 쉬운 집안 일 하기',
      ...radioGroup({
        key: 'lefs08',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs09',
      title: '9. 힘든 집안 일 하기',
      ...radioGroup({
        key: 'lefs09',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs10',
      title: '10. 차에 타고 내리기',
      ...radioGroup({
        key: 'lefs10',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs11',
      title: '11. 200m 걷기',
      ...radioGroup({
        key: 'lefs11',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs12',
      title: '12. 1.6km (1 mile) 걷기',
      ...radioGroup({
        key: 'lefs12',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs13',
      title: '13. 10개의 계단을 오르거나 내리기(한 층 정도)',
      ...radioGroup({
        key: 'lefs13',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs14',
      title: '14. 1시간 동안 서 있기',
      ...radioGroup({
        key: 'lefs14',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs15',
      title: '15. 1시간 동안 바닥에 앉아 있기',
      ...radioGroup({
        key: 'lefs15',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs16',
      title: '16. 평지에서 뛰기',
      ...radioGroup({
        key: 'lefs16',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs17',
      title: '17. 고르지 못한 지면에서 뛰기',
      ...radioGroup({
        key: 'lefs17',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs18',
      title: '18. 빠르게 달리다 급회전하기',
      ...radioGroup({
        key: 'lefs18',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs19',
      title: '19. 깡충깡충 뛰기',
      ...radioGroup({
        key: 'lefs19',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    },
    {
      id: 'lefs20',
      title: '20. 침대에서 돌아눕기',
      ...radioGroup({
        key: 'lefs20',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px'
        }
      })
    }
  ];
  // console.log('잉', getValues('lefs02'));

  const watchSumValues = () => {
    const values = rows.map(({ id }) => {
      if (props.watch(`${id}`) === undefined) {
        return 0;
      } else {
        return Number(props.watch(`${id}`));
      }
    });
    return sumValues(values);
  };

  const 점수 = watchSumValues();

  return (
    <div>
      <Typography
        sx={{ fontSize: '20px', fontWeight: 700, whiteSpace: 'nowrap' }}
      >
        Lower Extremity Functional Scale (LEFS)
      </Typography>
      <Grid item xs={12}>
        <MuiTable columns={columns} rows={rows} />
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ width: '100%', marginTop: '40px', marginBottom: '12px' }}>
          <S.ContentContainer>
            <S.ItemWrap>
              <S.ItemsHead>통증점수</S.ItemsHead>
              <MuiTextField
                required={false}
                value={점수}
                sx={{
                  minWidth: '130px',
                  width: '130px'
                }}
                InputProps={{ ...adornment('', '점'), readOnly: true }}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증부위</S.ItemsHead>
              <MuiTextField
                required={false}
                placeholder="직접 입력"
                {...register('lefsWay')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <Body />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증양상</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.PATTERN"
                values={[1, 2]}
                defaultValue={getValues('lefsPattern')}
                onChange={v => setValue('lefsPattern', v)}
                width="100px"
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증빈도</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.FREQUENCY"
                values={[1, 2, 3]}
                defaultValue={getValues('lefsFrequency')}
                onChange={v => setValue('lefsFrequency', v)}
                width="100px"
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증기간</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.LONG"
                values={[1, 2]}
                defaultValue={getValues('lefsLong')}
                onChange={v => setValue('lefsLong', v)}
                width="100px"
              />
              <MuiTextField
                required={false}
                placeholder="직접 입력"
                {...register('lefsLongInput')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증중재</S.ItemsHead>
              <span className="medi">비약물적 중재</span>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('lefsNoneMediChecked1'))}
                  {...register('lefsNoneMediChecked1')}
                  onChange={(e, checked) => {
                    setValue('lefsNoneMediChecked1', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>심호흡 방법 교육</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('lefsNoneMediChecked2'))}
                  {...register('lefsNoneMediChecked2')}
                  onChange={(e, checked) => {
                    setValue('lefsNoneMediChecked2', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>냉찜질 시행</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('lefsNoneMediChecked3'))}
                  {...register('lefsNoneMediChecked3')}
                  onChange={(e, checked) => {
                    setValue('lefsNoneMediChecked3', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>휴식</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('lefsNoneMediChecked4'))}
                  {...register('lefsNoneMediChecked4')}
                  onChange={(e, checked) => {
                    setValue('lefsNoneMediChecked4', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>기타</span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('lefsNoneMediInput')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHeadDummy></S.ItemsHeadDummy>
              <span className="none_medi">약물적 중재</span>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('lefsMediChecked'))}
                  {...register('lefsMediChecked')}
                  onChange={(e, checked) => {
                    setValue('lefsMediChecked', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>약물명 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('lefsMediInput1')}
              />
              <S.CheckboxWrap>
                <span>용량 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('lefsMediInput2')}
              />
              <S.CheckboxWrap>
                <span>투여방법 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('lefsMediInput3')}
              />
            </S.ItemWrap>
          </S.ContentContainer>
        </Box>
      </Grid>
    </div>
  );
}
