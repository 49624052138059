import * as S from '../../styles/NursingStyles/InfoNursingStyles';

import { Stack } from '@mui/system';
import adornment from 'form/adornment';
import MuiRadioGroup from './components/MuiRadioGroup';
import MuiRadioGroupTooth from './components/MuiRadioGroupTooth';
import MuiRadioGroupAnti from './components/MuiRadioGroupAnti';
import MuiRadioGroupSkinTest from './components/MuiRadioGroupSkinTest';
import MuiTextField from 'form/MuiTextField';
import RowContainer from 'form/RowContainer';
import RowContent from 'form/RowContent';
import { IFormRegister, IFormValues, IFormWatch } from '../type';
import { Box, Radio, RadioGroup, Typography } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { AccessTime } from '@mui/icons-material';
import { useState } from 'react';
import CheckboxGroup from './components/CheckboxGroup';
import CheckboxGroupPostCheck from './components/CheckboxGroupPostCheck';

interface Props extends IFormRegister, IFormValues, IFormWatch {
  disabled?: boolean;
}

export default function Operation(props: Props) {
  const { disabled, register, getValues, setValue, watch } = props;

  const [npo, setNpo] = useState(getValues('default_info.status.npo'));

  return (
    <S.LayoutNoneBackground>
      <S.Container3>
        <S.HeaderBox>
          <span className="title_sub">
            수술 전 확인 (병동 / 응급 / 외래 / DEC)
          </span>
          <Box sx={{ display: 'flex', padding: '5px', gap: '5px' }}>
            <Typography
              sx={{ fontSize: '12px', fontWeight: 500, paddingTop: '8px' }}
            >
              부서출발
            </Typography>
            <MuiTextField
              type="date"
              required={false}
              disabled={npo === '1' ? false : true}
              sx={{ width: '110px' }}
              placeholder="직접 입력"
              {...register('default_info.joo_ho_so')}
            />
            <MobileTimePicker
              value={watch('default_info.arrival_time') || null}
              onChange={value => setValue('default_info.arrival_time', value)}
              disabled={npo === '1' ? false : true}
              renderInput={params => (
                <MuiTextField
                  {...params}
                  fullWidth
                  InputProps={{ endAdornment: <AccessTime /> }}
                  size="small"
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: '12px'
                    },
                    width: '110px'
                  }}
                />
              )}
            />
          </Box>
        </S.HeaderBox>
        <RowContainer sx={{ paddingLeft: '10px' }} xs={12}>
          <RowContent
            title="환자 확인을 시행하였는가? (ID band)"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <MuiRadioGroup
              disabled={disabled}
              i18nNullKey="ETC"
              i18nKey="YESORNO"
              values={[1]}
              defaultValue={getValues(
                'default_info.hospitalization_path.value'
              )}
              onChange={v =>
                setValue('default_info.hospitalization_path.value', v)
              }
            />
          </RowContent>
          <RowContent
            title="수술동의서가 있는가?"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <Stack direction="row" spacing={1}>
              <MuiRadioGroup
                disabled={disabled}
                i18nKey="YESORNO"
                values={[1, 2]}
                defaultValue={getValues('default_info.hospitalization_way')}
                onChange={v => setValue('default_info.hospitalization_way', v)}
              />
              <MuiTextField
                required={false}
                disabled={disabled}
                sx={{ width: '150px' }}
                placeholder="직접 입력"
                {...register('default_info.joo_ho_so')}
              />
            </Stack>
          </RowContent>
          <RowContent
            title="PCA동의서가 있는가?"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <MuiRadioGroup
              disabled={disabled}
              i18nKey="YESORNOT"
              values={[1, 2]}
              defaultValue={getValues('default_info.status')}
              onChange={v => setValue('default_info.status', v)}
            />
          </RowContent>
          <RowContent
            title="중환자실입실동의서가 있는가?"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <MuiRadioGroup
              disabled={disabled}
              i18nKey="YESORNOT"
              values={[1, 2]}
              defaultValue={getValues('default_info.status02')}
              onChange={v => setValue('default_info.status02', v)}
            />
          </RowContent>
          <RowContent
            title="금식하였는가?"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <Stack direction="row" spacing={1}>
              <MuiRadioGroup
                disabled={disabled}
                i18nKey="YESORNO"
                values={[1, 2]}
                defaultValue={getValues('default_info.status.npo')}
                onChange={v => {
                  setValue('default_info.status.npo', v);
                  setNpo(v);
                }}
              />
            </Stack>
          </RowContent>
          <RowContent title="" titleRatio={3.7} childrenRatio={8}>
            <Stack direction="row" spacing={1}>
              <Typography
                sx={{
                  fontSize: '12px',
                  paddingTop: '8.5px',
                  whiteSpace: 'nowrap'
                }}
              >
                (시작일시
              </Typography>
              <MuiTextField
                type="date"
                required={false}
                disabled={npo === '1' ? false : true}
                sx={{ width: '120px' }}
                placeholder="직접 입력"
                {...register('default_info.joo_ho_so')}
              />
              <MobileTimePicker
                value={watch('default_info.arrival_time') || null}
                onChange={value => setValue('default_info.arrival_time', value)}
                disabled={npo === '1' ? false : true}
                renderInput={params => (
                  <MuiTextField
                    {...params}
                    fullWidth
                    InputProps={{ endAdornment: <AccessTime /> }}
                    size="small"
                    sx={{
                      '& .MuiInputBase-input': {
                        fontSize: '12px'
                      },
                      width: '120px'
                    }}
                  />
                )}
              />
              <Typography
                sx={{
                  fontSize: '12px',
                  paddingTop: '8.5px'
                }}
              >
                )
              </Typography>
            </Stack>
          </RowContent>
          <RowContent
            title="수술부위 피부준비가 되었는가?"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <Stack direction="row" spacing={1}>
              <MuiRadioGroup
                disabled={disabled}
                i18nKey="YESORNOORNOT"
                values={[1, 2, 3]}
                defaultValue={getValues('default_info.status.npo')}
                onChange={v => {
                  setValue('default_info.status.npo', v);
                  setNpo(v);
                }}
              />
            </Stack>
          </RowContent>
          <RowContent title="" titleRatio={3.5} childrenRatio={8.5}>
            <Stack direction="row" spacing={1}>
              <Typography sx={{ fontSize: '12px', lineHeight: '35px' }}>
                (
              </Typography>
              <CheckboxGroup
                i18nNullKey="ETC"
                // disabled={cycle === '1'? true : false}
                i18nKey="PRE.OPERATION"
                values={[1, 2, 3, 4, 0]}
                defaultValue={getValues('disease_historys.history.checked')}
                onChange={v => setValue('disease_historys.history.checked', v)}
              />
              <MuiTextField
                required={false}
                fullWidth={false}
                disabled={disabled}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('disease_historys.history.input')}
              />
              <Typography sx={{ fontSize: '12px', lineHeight: '35px' }}>
                )
              </Typography>
            </Stack>
          </RowContent>
          <RowContent
            title="수술부위가 정확히 표시되었는가?"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <Stack direction="row" spacing={1}>
              <MuiRadioGroup
                disabled={disabled}
                i18nKey="YESORNOORNOT"
                values={[1, 2, 3]}
                defaultValue={getValues('default_info.hospitalization_way')}
                onChange={v => setValue('default_info.hospitalization_way', v)}
              />
            </Stack>
          </RowContent>
          <RowContent title="" titleRatio={3.65} childrenRatio={8.25}>
            <Stack direction="row" spacing={1}>
              <MuiTextField
                required={false}
                fullWidth
                disabled={disabled}
                placeholder="직접 입력"
                // sx={{ width: '84px' }}
                {...register('disease_historys.history.input')}
              />
            </Stack>
          </RowContent>
          <RowContent
            title="보조기를 제거하였는가? (의치, 안경 등)"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <Stack direction="row" spacing={1}>
              <Stack direction="row" spacing={1}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nKey="YESORNO"
                  values={[1, 2]}
                  defaultValue={getValues('default_info.hospitalization_way')}
                  onChange={v =>
                    setValue('default_info.hospitalization_way', v)
                  }
                />
                <MuiTextField
                  required={false}
                  disabled={disabled}
                  sx={{ width: '150px' }}
                  placeholder="직접 입력"
                  {...register('default_info.joo_ho_so')}
                />
              </Stack>
            </Stack>
          </RowContent>
          <RowContent
            title="장신구를 제거하였는가? (핀, 반지, 시계 등)"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <Stack direction="row" spacing={1}>
              <MuiRadioGroup
                disabled={disabled}
                i18nKey="YESORNO"
                values={[1, 2]}
                defaultValue={getValues('default_info.hospitalization_way')}
                onChange={v => setValue('default_info.hospitalization_way', v)}
              />
              <MuiTextField
                required={false}
                disabled={disabled}
                sx={{ width: '150px' }}
                placeholder="직접 입력"
                {...register('default_info.joo_ho_so')}
              />
            </Stack>
          </RowContent>
          <RowContent
            title="화장을 제거하였는가? (입술, 네일아트 등)"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <Stack direction="row" spacing={1}>
              <Stack direction="row" spacing={1}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nKey="YESORNO"
                  values={[1, 2]}
                  defaultValue={getValues('default_info.hospitalization_way')}
                  onChange={v =>
                    setValue('default_info.hospitalization_way', v)
                  }
                />
                <MuiTextField
                  required={false}
                  disabled={disabled}
                  sx={{ width: '150px' }}
                  placeholder="직접 입력"
                  {...register('default_info.joo_ho_so')}
                />
              </Stack>
            </Stack>
          </RowContent>
          <RowContent title="치아상태" titleRatio={3.5} childrenRatio={8.5}>
            <Stack direction="row" spacing={1}>
              <Stack direction="row" spacing={1}>
                <MuiRadioGroupTooth
                  disabled={disabled}
                  i18nKey="PRE.OPERATION.TOOTH"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('default_info.hospitalization_way')}
                  onChange={v =>
                    setValue('default_info.hospitalization_way', v)
                  }
                />
              </Stack>
            </Stack>
          </RowContent>
          <RowContent
            title="수술복 착용하였는가?"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <MuiRadioGroup
              disabled={disabled}
              i18nKey="YESORNOT"
              values={[1, 2]}
              defaultValue={getValues('default_info.status02')}
              onChange={v => setValue('default_info.status02', v)}
            />
          </RowContent>
          <RowContent
            title="피부손상 위험요인이 있는가?"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <MuiRadioGroup
              disabled={disabled}
              i18nKey="YESORNO"
              values={[1, 2]}
              defaultValue={getValues('default_info.status02')}
              onChange={v => setValue('default_info.status02', v)}
            />
          </RowContent>
          <RowContent
            title="피부상태에 문제가 있는가? (욕창 등)"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <Stack direction="row" spacing={1}>
              <MuiRadioGroup
                disabled={disabled}
                i18nKey="YESORNO"
                i18nNullKey="ETC"
                values={[1, 2, 0]}
                defaultValue={getValues('default_info.hospitalization_way')}
                onChange={v => setValue('default_info.hospitalization_way', v)}
              />
              <MuiTextField
                required={false}
                disabled={disabled}
                sx={{ width: '150px' }}
                placeholder="직접 입력"
                {...register('default_info.joo_ho_so')}
              />
            </Stack>
          </RowContent>
          <RowContent title="" titleRatio={3.7} childrenRatio={8.2}>
            <Stack direction="row" spacing={1}>
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '35px',
                  whiteSpace: 'nowrap'
                }}
              >
                부위 1
              </Typography>
              <MuiTextField
                required={false}
                disabled={disabled}
                placeholder="직접 입력"
                {...register('default_info.joo_ho_so')}
              />
            </Stack>
          </RowContent>
          <RowContent title="" titleRatio={3.7} childrenRatio={8.2}>
            <Stack direction="row" spacing={1}>
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '35px',
                  whiteSpace: 'nowrap'
                }}
              >
                부위 2
              </Typography>
              <MuiTextField
                required={false}
                disabled={disabled}
                placeholder="직접 입력"
                {...register('default_info.joo_ho_so')}
              />
            </Stack>
          </RowContent>
          <RowContent title="" titleRatio={3.7} childrenRatio={8.2}>
            <Stack direction="row" spacing={1}>
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '35px',
                  whiteSpace: 'nowrap'
                }}
              >
                부위 3
              </Typography>
              <MuiTextField
                required={false}
                disabled={disabled}
                placeholder="직접 입력"
                {...register('default_info.joo_ho_so')}
              />
            </Stack>
          </RowContent>
          <RowContent
            title="수술 전 투약을 시행하였는가?"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <MuiRadioGroup
              disabled={disabled}
              i18nKey="YESORNOT"
              values={[1, 2]}
              defaultValue={getValues('default_info.status02')}
              onChange={v => setValue('default_info.status02', v)}
            />
          </RowContent>
          <RowContent title="" titleRatio={3.65} childrenRatio={8.25}>
            <Stack direction="row" spacing={1}>
              <MuiTextField
                required={false}
                fullWidth
                disabled={disabled}
                placeholder="직접 입력"
                // sx={{ width: '84px' }}
                {...register('disease_historys.history.input')}
              />
            </Stack>
          </RowContent>
          <RowContent
            title="수술 예방적 항생제가 준비되었는가?"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <MuiRadioGroupAnti
              disabled={disabled}
              i18nKey="PRE.OPERATION.ANTI"
              values={[1, 2, 3]}
              defaultValue={getValues('default_info.status02')}
              onChange={v => setValue('default_info.status02', v)}
            />
          </RowContent>
          <RowContent title="" titleRatio={3.65} childrenRatio={8.25}>
            <Stack direction="row" spacing={1}>
              <MuiTextField
                required={false}
                fullWidth
                disabled={disabled}
                placeholder="직접 입력"
                // sx={{ width: '84px' }}
                {...register('disease_historys.history.input')}
              />
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '39px',
                  whiteSpace: 'nowrap'
                }}
              >
                ( Skin test
              </Typography>
              <MuiRadioGroupSkinTest
                disabled={disabled}
                i18nKey="PRE.OPERATION.SKINTEST"
                values={[1, 2]}
                defaultValue={getValues('default_info.status02')}
                onChange={v => setValue('default_info.status02', v)}
              />
              <Typography sx={{ fontSize: '12px', lineHeight: '39px' }}>
                )
              </Typography>
            </Stack>
          </RowContent>
          <RowContent
            title="보내는 물품 및 약물이 준비되었는가?"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <Stack direction="row" spacing={1}>
              <MuiRadioGroup
                disabled={disabled}
                i18nKey="YESORNOT"
                i18nNullKey="ETC"
                values={[1, 2]}
                defaultValue={getValues('default_info.hospitalization_way')}
                onChange={v => setValue('default_info.hospitalization_way', v)}
              />
              <MuiTextField
                required={false}
                disabled={disabled}
                sx={{ width: '150px' }}
                placeholder="직접 입력"
                {...register('default_info.joo_ho_so')}
              />
            </Stack>
          </RowContent>
          <RowContent title="" titleRatio={3.7} childrenRatio={8.2}>
            <Stack direction="row" spacing={1}>
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '35px',
                  whiteSpace: 'nowrap'
                }}
              >
                물품
              </Typography>
              <MuiTextField
                required={false}
                disabled={disabled}
                placeholder="직접 입력"
                {...register('default_info.joo_ho_so')}
              />
            </Stack>
          </RowContent>
          <RowContent title="" titleRatio={3.7} childrenRatio={8.2}>
            <Stack direction="row" spacing={1}>
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '35px',
                  whiteSpace: 'nowrap'
                }}
              >
                약품
              </Typography>
              <MuiTextField
                required={false}
                disabled={disabled}
                placeholder="직접 입력"
                {...register('default_info.joo_ho_so')}
              />
            </Stack>
          </RowContent>
          <RowContent title="간호사 서명" titleRatio={3.7} childrenRatio={8.2}>
            <MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              {...register('default_info.joo_ho_so')}
            />
          </RowContent>
        </RowContainer>
      </S.Container3>

      <S.Container3_right>
        <S.HeaderBox>
          <span className="title_sub">수술 전 확인(수술실)</span>
          <Box sx={{ display: 'flex', padding: '5px', gap: '5px' }}>
            <Typography
              sx={{ fontSize: '12px', fontWeight: 500, paddingTop: '8px' }}
            >
              대기실 도착
            </Typography>
            <MuiTextField
              type="date"
              required={false}
              disabled={npo === '1' ? false : true}
              sx={{ width: '110px' }}
              placeholder="직접 입력"
              {...register('default_info.joo_ho_so')}
            />
            <MobileTimePicker
              value={watch('default_info.arrival_time') || null}
              onChange={value => setValue('default_info.arrival_time', value)}
              disabled={npo === '1' ? false : true}
              renderInput={params => (
                <MuiTextField
                  {...params}
                  fullWidth
                  InputProps={{ endAdornment: <AccessTime /> }}
                  size="small"
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: '12px'
                    },
                    width: '110px'
                  }}
                />
              )}
            />
          </Box>
        </S.HeaderBox>

        <RowContainer sx={{ mb: 'auto', paddingLeft: '10px' }} xs={12}>
          <RowContent
            title="환자 확인을 시행하였는가? (ID band)"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <MuiRadioGroup
              disabled={disabled}
              i18nNullKey="ETC"
              i18nKey="YESORNO"
              values={[1]}
              defaultValue={getValues(
                'default_info.hospitalization_path.value'
              )}
              onChange={v =>
                setValue('default_info.hospitalization_path.value', v)
              }
            />
          </RowContent>
          <RowContent
            title="수술동의서가 있는가?"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <MuiRadioGroup
              disabled={disabled}
              i18nNullKey="ETC"
              i18nKey="YESORNO"
              values={[1]}
              defaultValue={getValues(
                'default_info.hospitalization_path.value'
              )}
              onChange={v =>
                setValue('default_info.hospitalization_path.value', v)
              }
            />
          </RowContent>
          <RowContent
            title="금식하였는가?"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <Stack direction="row" spacing={1}>
              <MuiRadioGroup
                disabled={disabled}
                i18nKey="YESORNO"
                values={[1, 2]}
                defaultValue={getValues('default_info.hospitalization_way')}
                onChange={v => setValue('default_info.hospitalization_way', v)}
              />
              <MuiTextField
                required={false}
                disabled={disabled}
                sx={{ width: '150px' }}
                placeholder="직접 입력"
                {...register('default_info.joo_ho_so')}
              />
            </Stack>
          </RowContent>
          <RowContent
            title="수술부위가 정확히 표시되었는가?"
            titleRatio={3.5}
            childrenRatio={8.4}
          >
            <Stack direction="row" spacing={1}>
              <CheckboxGroupPostCheck
                i18nNullKey="ETC"
                // disabled={cycle === '1'? true : false}
                i18nKey="POST.OPERATION.CHECK"
                values={[1]}
                defaultValue={getValues('disease_historys.history.checked')}
                onChange={v => setValue('disease_historys.history.checked', v)}
              />
              <MuiTextField
                required={false}
                disabled={disabled}
                fullWidth
                placeholder="직접 입력"
                {...register('default_info.joo_ho_so')}
              />
            </Stack>
          </RowContent>
          <RowContent title="" titleRatio={3.5} childrenRatio={8.4}>
            <Stack direction="row" spacing={1}>
              <CheckboxGroupPostCheck
                i18nNullKey="ETC"
                // disabled={cycle === '1'? true : false}
                i18nKey="POST.OPERATION.CHECK"
                values={[2]}
                defaultValue={getValues('disease_historys.history.checked')}
                onChange={v => setValue('disease_historys.history.checked', v)}
              />
              <MuiTextField
                required={false}
                disabled={disabled}
                fullWidth
                placeholder="직접 입력"
                {...register('default_info.joo_ho_so')}
              />
            </Stack>
          </RowContent>
          <RowContent
            title="보조기를 제거하였는가? (의치, 안경 등)"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <Stack direction="row" spacing={1}>
              <Stack direction="row" spacing={1}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nKey="YESORNO"
                  values={[1, 2]}
                  defaultValue={getValues('default_info.hospitalization_way')}
                  onChange={v =>
                    setValue('default_info.hospitalization_way', v)
                  }
                />
                <MuiTextField
                  required={false}
                  disabled={disabled}
                  sx={{ width: '150px' }}
                  placeholder="직접 입력"
                  {...register('default_info.joo_ho_so')}
                />
              </Stack>
            </Stack>
          </RowContent>
          <RowContent
            title="장신구를 제거하였는가? (핀, 반지, 시계 등)"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <Stack direction="row" spacing={1}>
              <MuiRadioGroup
                disabled={disabled}
                i18nKey="YESORNO"
                values={[1, 2]}
                defaultValue={getValues('default_info.hospitalization_way')}
                onChange={v => setValue('default_info.hospitalization_way', v)}
              />
              <MuiTextField
                required={false}
                disabled={disabled}
                sx={{ width: '150px' }}
                placeholder="직접 입력"
                {...register('default_info.joo_ho_so')}
              />
            </Stack>
          </RowContent>
          <RowContent
            title="화장을 제거하였는가? (입술, 네일아트 등)"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <Stack direction="row" spacing={1}>
              <MuiRadioGroup
                disabled={disabled}
                i18nKey="YESORNO"
                values={[1, 2]}
                defaultValue={getValues('default_info.hospitalization_way')}
                onChange={v => setValue('default_info.hospitalization_way', v)}
              />
              <MuiTextField
                required={false}
                disabled={disabled}
                sx={{ width: '150px' }}
                placeholder="직접 입력"
                {...register('default_info.joo_ho_so')}
              />
            </Stack>
          </RowContent>
          <RowContent
            title="피부상태에 문제가 있는가? (욕창 등)"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <Stack direction="row" spacing={1}>
              <MuiRadioGroup
                disabled={disabled}
                i18nNullKey="ETC"
                i18nKey="YESORNO"
                values={[1, 2, 0]}
                defaultValue={getValues('default_info.hospitalization_way')}
                onChange={v => setValue('default_info.hospitalization_way', v)}
              />
            </Stack>
          </RowContent>
          <RowContent title="" titleRatio={3.65} childrenRatio={8.25}>
            <MuiTextField
              required={false}
              fullWidth
              disabled={disabled}
              placeholder="직접 입력"
              // sx={{ width: '84px' }}
              {...register('disease_historys.history.input')}
            />
          </RowContent>
          <RowContent
            title="수술부위가 정확히 표시되었는가?"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <Stack direction="row" spacing={1}>
              <MuiRadioGroup
                disabled={disabled}
                i18nKey="POST.OPERATION.ANTI"
                values={[1, 2, 3]}
                defaultValue={getValues('default_info.hospitalization_way')}
                onChange={v => setValue('default_info.hospitalization_way', v)}
              />
            </Stack>
          </RowContent>
          <RowContent title="" titleRatio={3.65} childrenRatio={8.25}>
            <MuiTextField
              required={false}
              fullWidth
              disabled={disabled}
              placeholder="직접 입력"
              // sx={{ width: '84px' }}
              {...register('disease_historys.history.input')}
            />
          </RowContent>
          <RowContent
            title="수술 예방적 항생제가 준비되었는가?"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <MuiRadioGroup
              disabled={disabled}
              i18nKey="POST.OPERATION.ANTI"
              values={[1, 2, 3]}
              defaultValue={getValues('default_info.hospitalization_way')}
              onChange={v => setValue('default_info.hospitalization_way', v)}
            />
          </RowContent>
          <RowContent
            title="보내는 물품 및 약물이 준비되었는가?"
            titleRatio={3.5}
            childrenRatio={8.5}
          >
            <MuiRadioGroup
              disabled={disabled}
              i18nKey="YESORNOT"
              values={[1, 2]}
              defaultValue={getValues('default_info.hospitalization_way')}
              onChange={v => setValue('default_info.hospitalization_way', v)}
            />
          </RowContent>
          <RowContent title="" titleRatio={3.65} childrenRatio={8.25}>
            <MuiTextField
              required={false}
              fullWidth
              disabled={disabled}
              placeholder="직접 입력"
              // sx={{ width: '84px' }}
              {...register('disease_historys.history.input')}
            />
          </RowContent>
          <RowContent
            title="간호사 서명"
            titleRatio={3.65}
            childrenRatio={8.25}
          >
            <MuiTextField
              required={false}
              disabled={disabled}
              placeholder="직접 입력"
              {...register('default_info.joo_ho_so')}
            />
          </RowContent>
        </RowContainer>
      </S.Container3_right>
    </S.LayoutNoneBackground>
  );
}
