import { useEffect, useState } from 'react';

import { ListItemButton, Typography } from '@mui/material';

import { getPatients } from 'apis/admin';
import usePatient from 'store/patient/usePatient';
import MuiAutocomplete from 'form/MuiAutocomplete';

interface IOption {
  patient_id: string;
  name: string;
  age: string;
}

interface Props {
  user_id: number;
}

const PatientsList = ({ user_id }: Props) => {
  const { onSelectedPatient } = usePatient();

  const optionLabel = ({ patient_id, name }: IOption) =>
    `${patient_id} ${name}`;

  const Option = ({ patient_id, name, age, ...props }: IOption) => {
    {
      return (
        <ListItemButton key={patient_id} {...props} sx={{ gap: 0.2 }}>
          <Typography
            variant="subtitle2"
            fontSize="14px"
            sx={{ whiteSpace: 'nowrap' }}
          >
            {patient_id}
          </Typography>
          <Typography variant="caption" fontSize="14px" color={'#000000B2'}>
            {name}
          </Typography>
          <Typography
            variant="caption"
            fontSize="14px"
            color={'#000000B2'}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {age}
          </Typography>
        </ListItemButton>
      );
    }
  };

  return (
    <MuiAutocomplete
      variant="standard"
      valueKey="patient_id"
      listKey="xr_admin_patients"
      placeholder="Patient Search"
      noOptionsText="No patient found. Please enter another patient name"
      getOptionLabel={optionLabel}
      renderOption={(props, option) => {
        if (!user_id) {
          return null;
        } else return <Option {...props} {...option} />;
      }}
      onChange={onSelectedPatient}
      getApi={params => getPatients({ ...params, user_id })}
    />
  );
};

export default PatientsList;
