import MuiTextField from 'form/MuiTextField';
import * as S from '../../styles/FilloStyles/Info1Styles';
import { IFormRegister, IFormValues, IFormWatch } from '../type';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { AccessTime } from '@mui/icons-material';
import MuiRadioGroup from 'form/MuiRadioGroup';
import MuiCheckbox from 'form/MuiCheckbox';

interface Props extends IFormRegister, IFormValues, IFormWatch {
  disabled?: boolean;

  // list: FilloType;
}

export default function Info1(props: Props) {
  const { disabled, register, getValues, setValue, watch } = props;

  // const data = list !== null ? list.default_info : list;
  // console.log('list datadatadata ', data);

  // console.log(list.default_info);

  return (
    <S.Layout>
      <S.Container>
        <span className="title">I. 일반정보</span>
        <S.ContentsContainer>
          <S.ContentsBox>
            <span className="sub_title">도착시간</span>
            <MuiTextField
              type="date"
              disabled={disabled}
              fullWidth
              required={false}
              {...register('default_info.arrival_date')}
            />
            <MobileTimePicker
              value={watch('default_info.arrival_time') || null}
              onChange={value => setValue('default_info.arrival_time', value)}
              // disabled={!isStudent}
              renderInput={params => (
                <MuiTextField
                  {...params}
                  fullWidth
                  InputProps={{ endAdornment: <AccessTime /> }}
                  size="small"
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: '12px'
                    }
                  }}
                />
              )}
            />
          </S.ContentsBox>
          <S.ContentsBox>
            <span className="sub_title">작성시간</span>
            <MuiTextField
              type="date"
              disabled={disabled}
              fullWidth
              required={false}
              {...register('default_info.creation_date')}
            />
            <MobileTimePicker
              value={watch('default_info.creation_time') || null}
              onChange={value => setValue('default_info.creation_time', value)}
              // disabled={!isStudent}
              renderInput={params => (
                <MuiTextField
                  {...params}
                  fullWidth
                  InputProps={{ endAdornment: <AccessTime /> }}
                  size="small"
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: '12px'
                    }
                  }}
                />
              )}
            />
          </S.ContentsBox>
        </S.ContentsContainer>
      </S.Container>
      <S.Container>
        <span className="title">Ⅱ. 음주 / 흡연</span>
        <S.ContentsContainer>
          <S.ContentsBox>
            <span className="sub_title2">음주</span>

            <MuiRadioGroup
              i18nKey="EXIST.SHORT"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('default_info.drink.value')}
              onChange={v => setValue('default_info.drink.value', v)}
              width="70px"
            />
            <MuiTextField
              required={false}
              disabled={disabled}
              sx={{
                minWidth: '84px',
                width: '84px',
                marginRight: '20px'
              }}
              placeholder="기간"
              {...register('default_info.drink.input1')}
            />
            <MuiCheckbox
              label="금주"
              disabled={disabled}
              defaultValue={getValues('default_info.drink.checked')}
              {...register('default_info.drink.checked')}
            />
            <MuiTextField
              required={false}
              disabled={disabled}
              placeholder="기간"
              sx={{
                width: '84px'
              }}
              {...register('default_info.drink.input2')}
            />
          </S.ContentsBox>
          <S.ContentsBox>
            <span className="sub_title2">흡연</span>

            <MuiRadioGroup
              i18nKey="EXIST.SHORT"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('default_info.smoke.value')}
              onChange={v => setValue('default_info.smoke.value', v)}
              width="70px"
            />
            <MuiTextField
              required={false}
              disabled={disabled}
              sx={{
                minWidth: '84px',
                width: '84px',
                marginRight: '20px'
              }}
              placeholder="기간"
              {...register('default_info.smoke.input1')}
            />
            <MuiCheckbox
              label="금연"
              disabled={disabled}
              defaultValue={getValues('default_info.smoke.checked')}
              {...register('default_info.smoke.checked')}
            />
            <MuiTextField
              required={false}
              disabled={disabled}
              placeholder="기간"
              sx={{
                width: '84px'
              }}
              {...register('default_info.smoke.input2')}
            />
          </S.ContentsBox>
        </S.ContentsContainer>
      </S.Container>
    </S.Layout>
  );
}
