import styled from 'styled-components';

export const Layout = styled.div`
  width: 100%;
  /* margin-top: 240px; */
  height: calc(100% - 240px);
  min-height: 684px;
  background: #fff;
`;

export const Container = styled.div`
  margin: 12px;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const ContainerWrap = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;
  width: 100%;
  flex: 6;
`;

export const ContainerWrapTwo = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;
  width: 100%;
  flex: 4;
`;

export const InfoContainer = styled.div`
  width: 100%;
  flex: 3;
  background: ${props => props.theme.color.brand_100};
  border-radius: 4px;
`;

export const HistoryContainer = styled.div`
  width: 100%;
  flex: 7;
  background: ${props => props.theme.color.brand_100};
  border-radius: 4px;
`;

export const SBARContainer = styled.div`
  width: 100%;
  background: ${props => props.theme.color.brand_100};
  border-radius: 4px;
  flex: 5.5;
`;

export const SpecialContainer = styled.div`
  width: 100%;
  flex: 4.5;
  background: ${props => props.theme.color.brand_100};
  border-radius: 4px;
`;
