import * as S from '../../styles/RecordsStyles/NANDAStyles';
import { INames } from 'apis/records/type';
import { useEffect, useState } from 'react';
import { getNandaClass, getNandaDiagnosis } from 'apis/records/recordsApi';
import MuiSelect from 'form/MuiSelect';

interface Props {
  watch: any;
  setValue: any;
  register: any;
  disabled?: boolean;
  domainNames?: INames[];
}

export default function NANDA(props: Props) {
  const { watch, register, setValue, domainNames, disabled } = props;

  const m_domain = watch('domain');

  // GetNandaClass
  const m_class = watch('class');
  const [classNames, setClassNames] = useState<INames[]>([]);
  useEffect(() => {
    if (!m_domain || m_domain === 'none') return setClassNames([]);
    getNandaClass({ domain: m_domain }).then(({ data }) => {
      setClassNames(data.names);
      setDiagnosisNames([]);
    });
  }, [m_domain, setValue]);

  // GetNandaDiagnosis
  const [diagnosisNames, setDiagnosisNames] = useState<INames[]>([]);
  useEffect(() => {
    if (!m_class || m_class === 'none') return setDiagnosisNames([]);
    getNandaDiagnosis({ domain: m_domain, class: m_class }).then(({ data }) => {
      setDiagnosisNames(data.names);
    });
  }, [m_domain, m_class, setValue]);

  // Render Select Options
  const getOptionValue = (option: INames) => option.kor;
  const getOptionLabel = (option: INames) => `${option.kor} (${option.eng})`;

  return (
    <S.Layout>
      <S.Container>
        <S.SelectWrap>
          <S.Title>영역 (Domain)</S.Title>
          <S.SelectBox>
            <MuiSelect
              isNone
              // disabled={disabled}
              options={domainNames}
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionLabel}
              {...register('domain')}
            />
          </S.SelectBox>
        </S.SelectWrap>

        <S.SelectWrap>
          <S.Title>분류 (Class)</S.Title>
          <S.SelectBox>
            <MuiSelect
              isNone
              // disabled={disabled}
              options={classNames}
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionLabel}
              {...register('class')}
            />
          </S.SelectBox>
        </S.SelectWrap>

        <S.SelectWrap>
          <S.Title>진단명 (Dignosis)</S.Title>
          <S.SelectBox>
            <MuiSelect
              isNone
              // disabled={disabled}
              options={diagnosisNames}
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionLabel}
              {...register('diagnosis')}
            />
          </S.SelectBox>
        </S.SelectWrap>
        <S.IntputWrap>
          <S.Title>자료 수집 주관적 / 객관적</S.Title>
          <S.Input
            style={{
              height: '32px'
            }}
            {...register('collectingData')}
          />
        </S.IntputWrap>
        <S.IntputWrap>
          <S.Title>간호목표 단기/장기 (Goal)</S.Title>
          <S.Input
            style={{
              height: '32px'
            }}
            {...register('goal')}
          />
        </S.IntputWrap>
        <S.IntputWrap>
          <S.Title>간호계획 (Plan)</S.Title>
          <S.Input
            style={{
              height: '57px'
            }}
            {...register('plan')}
          />
        </S.IntputWrap>
        <S.IntputWrap>
          <S.Title>간호수행/중재/이론적 근거 (Interventions)</S.Title>
          <S.Input
            style={{
              height: '85px'
            }}
            {...register('interventions')}
          />
        </S.IntputWrap>
        <S.IntputWrap>
          <S.Title>간호평가 (Evaluation)</S.Title>
          <S.Input
            style={{
              height: '57px'
            }}
            {...register('evaluation')}
          />
        </S.IntputWrap>
      </S.Container>
    </S.Layout>
  );
}
