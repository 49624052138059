import { Fragment, useState } from 'react';
import { useSnackbar } from 'notistack';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { deleteNursingRecord } from 'apis/records/recordsApi';
import usePatient from 'store/patient/usePatient';
import { IUpdateClinicalObs } from 'apis/observation/type';

interface Props extends IUpdateClinicalObs {
  refetch: () => void;
}

const ActionButtons = ({
  refetch,
  content,
  option_type,
  check_time,
  ...otherProps
}: Props) => {
  const { onSelectClinicalObs } = usePatient();

  const onConfig = () => {
    onSelectClinicalObs({
      content,
      option_type,
      check_time,
      ...otherProps
    });
  };

  // const onDelete = () => {
  //   const isConfirm = window.confirm('간호기록을 삭제하시겠습니까?');
  //   if (!isConfirm) return handleClose();

  //   deleteNursingRecord({
  //     ...otherProps,
  //     record_id: otherProps.xr_clinical_observations
  //   })
  //     .then(() => {
  //       refetch();
  //       const message = '간호기록을 삭제하였습니다.';
  //       enqueueSnackbar(message, { variant: 'success' });
  //     })
  //     .catch(e => {
  //       const message = `간호기록 삭제 실패하였습니다.\n오류: ${e}`;
  //       enqueueSnackbar(message, { variant: 'error' });
  //     })
  //     .finally(handleClose);
  // };

  return (
    <Fragment>
      <IconButton size="small" onClick={onConfig}>
        <ModeEditOutlineOutlinedIcon sx={{ height: '18px' }} />
      </IconButton>
      <IconButton size="small" onClick={onConfig}>
        <DeleteOutlineOutlinedIcon sx={{ height: '18px' }} />
      </IconButton>
    </Fragment>
  );
};

export default ActionButtons;
