import { getFillo } from 'apis/fillo/filloApi';
import useNotification from 'hook/useNotification';
import { findKeyValueToObj, findKeyValueToObjNoParse } from 'apis/convert';

import { initialFillo } from 'components/Fillo/initialStates';
import { MENU } from 'components/Common/Header';
import { getObservation } from 'apis/observation/observationApi';

interface Props {
  user_id: number;
  setDefaultValues: (v: any) => void;
}

const useDefaultValues = ({ setDefaultValues, user_id }: Props) => {
  const { onFail } = useNotification();
  const convertDataToStates = (data: any, states: any) => {
    const values = states;
    const keys = Object.keys(states);

    for (const key of keys) {
      const getValue = data[key];

      if (typeof getValue !== 'object' || Array.isArray(getValue)) {
        values[key] = getValue;
      } else if (getValue) {
        try {
          values[key] = findKeyValueToObj(getValue, Object.keys(getValue));
        } catch {
          values[key] = findKeyValueToObjNoParse(
            getValue,
            Object.keys(getValue)
          );
        }
      }
    }
    setDefaultValues(values);
  };

  const onGetDefaultValues = (patient_id: number, type: string) => {
    switch (type) {
      case MENU.INFO:
        convertDataToStates(initialFillo, initialFillo);
        break;
      case MENU.EHR:
        convertDataToStates(initialFillo, initialFillo);
        break;
      case MENU.FILLO:
        getFillo({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, fillo_survey } = data;
            convertDataToStates({ update_at, ...fillo_survey }, initialFillo);
          })
          .catch(e => onFail('알 수 없는 오류가 발생했습니다.', e));
        break;
      case MENU.OBSERVATION:
        convertDataToStates(initialFillo, initialFillo);
        break;
      case MENU.TESTRESULT:
        convertDataToStates(initialFillo, initialFillo);
        break;
      case MENU.RECORDS:
        convertDataToStates(initialFillo, initialFillo);
        break;
      case MENU.NURSING:
        convertDataToStates(initialFillo, initialFillo);
        break;
      case MENU.SAFETY:
        convertDataToStates(initialFillo, initialFillo);
        break;
      case MENU.AGREEMENT:
        convertDataToStates(initialFillo, initialFillo);
        break;

      default:
        setDefaultValues(null);
        break;
    }
  };

  return { onGetDefaultValues };
};

export default useDefaultValues;
