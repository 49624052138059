import * as S from '../../styles/FilloStyles/FilloStyles';
import Info1 from './Info1';
import Info2 from './Info2';
import Info3 from './Info3';
import Info4 from './Info4';
import Info5 from './Info5';
import Info6 from './Info6';

import useNotification from 'hook/useNotification';
import usePatient from 'store/patient/usePatient';
import { Skeleton } from '@mui/material';
import { useForm } from 'react-hook-form';

import { updateFillo } from 'apis/fillo/filloApi';
import { findKeyValueToStr } from 'apis/convert';
import { SurveyDialogProps, TFilloDefaultValues } from 'components/Main/type';
import useUser from 'store/user/useUser';

export default function Fillo(props: SurveyDialogProps<TFilloDefaultValues>) {
  const { defaultValues } = props;

  const { student_uuid: user_id } = useUser();

  const { onSuccess, onFail, onResultCode } = useNotification();

  const { patientInfo } = usePatient();

  if (!patientInfo) {
    return <Skeleton variant="rectangular" sx={{ flex: 1 }} />;
  }

  const { patient_id, name, age, gender, room, department } = patientInfo;

  const { register, watch, setValue, getValues, handleSubmit } = useForm({
    defaultValues
  });

  const onSubmit = (data: TFilloDefaultValues) => {
    const { patient_id } = patientInfo;
    const {
      default_info,
      information,
      family_historys,
      disease_historys,
      hospitalization,
      more_details
    } = data;
    const request = {
      user_id,
      patient_id,
      fillo_survey: {
        default_info: findKeyValueToStr(default_info),
        information: findKeyValueToStr(information),
        family_historys: findKeyValueToStr(family_historys),
        disease_historys: findKeyValueToStr(disease_historys),
        hospitalization,
        more_details
      }
    };

    updateFillo(request)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);
        onSuccess('저장성공.');
      })
      .catch(e => onFail('저장실패.', e));
  };

  // console.log('defaultValues', defaultValues);

  const formProps = {
    watch,
    register,
    getValues,
    setValue
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.Layout>
          <S.HeaderBox>
            <S.Title>
              <span>{name}</span>
              <span>/</span>
              <span>{gender === 1 ? '여자' : '남자'}</span>
              <span>/</span>
              <span>
                {age} ({patient_id}) {department}
              </span>
              <span>/</span>
              {/* 이 10-1은 병동이라고함 */}
              <span>{room}</span>
            </S.Title>
            <S.ButtonWithTimeBox>
              <span>최근 저장한 시간 : 2022-10-12 13:30 PM </span>
              <S.Button type="submit">저장</S.Button>
            </S.ButtonWithTimeBox>
          </S.HeaderBox>
          <S.Container>
            <Info1 {...formProps} />
            <Info2 {...formProps} />
            <Info3 {...formProps} />
            <Info4 {...formProps} />
            <Info5 {...formProps} />
            <Info6 {...formProps} />
          </S.Container>
        </S.Layout>
      </form>
    </>
  );
}
