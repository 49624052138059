import * as S from '../../styles/EHRStyles/SBARStyles';

export default function SBAR() {
  return (
    <S.Layout>
      <S.Container>
        <span className="title">인계 SBAR</span>
        <S.ContentWrap>
          <S.Initial>S</S.Initial>
          <S.Desc>
            <span>① 입원 동기</span>
            <span>ㄴ Chemo cycle or OP(2023. 11/20) 표기 해주기!</span>
          </S.Desc>
        </S.ContentWrap>
        <S.ContentWrap>
          <S.Initial>B</S.Initial>
          <S.Desc>
            <span>① 과거력</span>
            <span>ㄴ 관련 지참약</span>
            <span>ㄴ AVF 등 특이사항 메모</span>
          </S.Desc>
        </S.ContentWrap>
        <S.ContentWrap>
          <S.Initial>A</S.Initial>
          <S.Desc>
            <span>① 현재상태</span>
            <span>ㄴ mental change, event</span>
            <span>② 검사결과</span>
            <span>
              ㄴ replace, CRE, Culture 등 검사 소요기간 긴 검사들 확인해야할 것
            </span>
          </S.Desc>
        </S.ContentWrap>
        <S.ContentWrap>
          <S.Initial>R</S.Initial>
          <S.Desc>
            <span>① 치료계획</span>
            <span>ㄴ anti 사용 관련, 투석 일정 등</span>
            <span>② 간호계획</span>
            <span>
              ㄴ Arm save, 약물 투약 관련 알레르기, 수혈 시 pre medi 등 다음
              인계번 할일
            </span>
          </S.Desc>
        </S.ContentWrap>
        <S.ContentWrap style={{ paddingBottom: '10px' }}>
          <S.Initial>H</S.Initial>
          <S.Desc>
            <span>① 환자안전</span>
            <span>ㄴ 욕창 - 관련간호</span>
            <span>ㄴ 낙상 - 낙상 방지 매트 등 사용</span>
            <span>ㄴ 억제대 관련</span>
            <span>② 기타</span>
            <span>ㄴ 환자 특이사항(식사), 퇴원약, 퇴원서류</span>
          </S.Desc>
        </S.ContentWrap>
      </S.Container>
    </S.Layout>
  );
}
