import styled from 'styled-components';

export const Layout = styled.div`
  width: 200px;
  height: 200px;
  margin: auto;
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeaderContents = styled.div`
  width: 40px;
  text-align: center;
  font-size: 8px;
  font-weight: 400;
  color: white;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BodyBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BodyContents = styled.div`
  width: 40px;
  text-align: center;
  font-size: 8px;
  font-weight: 400;
  padding: 20px 5px 20px 5px;
`;
