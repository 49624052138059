import MuiTextField from 'form/MuiTextField';
import MuiRadioGroup from 'form/MuiRadioGroup';
import * as S from '../../../styles/ObservationStyles/ModalStyles';
import { IFormRegister, IFormValues } from '../../type';
import { ReactComponent as Body } from 'assets/img/body.svg';

interface Props extends IFormRegister, IFormValues {}

export default function ChestTube(props: Props) {
  const { register, getValues, setValue } = props;
  return (
    <S.ContentContainer>
      <S.Title>ChestTube</S.Title>
      <S.ItemWrap>
        <S.ItemsHead>크기</S.ItemsHead>
        <MuiTextField
          required={false}
          sx={{
            minWidth: '130px',
            width: '130px',
            marginLeft: '10px'
          }}
          placeholder="직접 입력"
          {...register('chestSize')}
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>위치</S.ItemsHead>
        <MuiTextField
          required={false}
          sx={{
            minWidth: '130px',
            width: '130px',
            marginLeft: '10px'
          }}
          placeholder="직접 입력"
          {...register('chestLocation')}
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <Body />
      </S.ItemWrap>
      <S.Line>
        <S.ItemWrap>
          <S.ItemsHead>Dressing</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="EXIST.DRESSING"
            values={[1, 2]}
            defaultValue={getValues('chestDressing')}
            onChange={v => setValue('chestDressing', v)}
            width="100px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>Air leakage</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="EXIST.SHORT"
            values={[1, 2]}
            defaultValue={getValues('chestAirLeakage')}
            onChange={v => setValue('chestAirLeakage', v)}
            width="100px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>부종</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="EXIST.SHORT"
            values={[1, 2]}
            defaultValue={getValues('chestEdema')}
            onChange={v => setValue('chestEdema', v)}
            width="100px"
          />
        </S.ItemWrap>
      </S.Line>
      <S.Line>
        <S.ItemWrap>
          <S.ItemsHead>Bleeding</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="EXIST.SHORT"
            values={[1, 2]}
            defaultValue={getValues('chestBleeding')}
            onChange={v => setValue('chestBleeding', v)}
            width="100px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>Osillation</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="EXIST.SHORT"
            values={[1, 2]}
            defaultValue={getValues('chestOsillation')}
            onChange={v => setValue('chestOsillation', v)}
            width="100px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>삼출물</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="EXIST.EXUDATION"
            values={[1, 2]}
            defaultValue={getValues('chestWater')}
            onChange={v => setValue('chestWater', v)}
            width="100px"
          />
          <MuiTextField
            required={false}
            sx={{
              minWidth: '130px',
              width: '120px',
              marginLeft: '-40px'
            }}
            placeholder="직접 입력"
            {...register('chestWaterInput')}
          />
        </S.ItemWrap>
      </S.Line>
      <S.Line>
        <S.ItemWrap>
          <S.ItemsHead>배액</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="EXIST.DRAINAGE"
            values={[1, 2]}
            defaultValue={getValues('chestSwelling')}
            onChange={v => setValue('chestSwelling', v)}
            width="100px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>피하기종</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="EXIST.SHORT"
            values={[1, 2]}
            defaultValue={getValues('chestExudate')}
            onChange={v => setValue('chestExudate', v)}
            width="100px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>배액압력</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="EXIST.SHORT"
            values={[1, 2]}
            defaultValue={getValues('chestPress')}
            onChange={v => setValue('chestPress', v)}
            width="100px"
          />
        </S.ItemWrap>
      </S.Line>
      <S.Line>
        <S.ItemWrap>
          <S.ItemsHead>색</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="EXIST.COLOR"
            values={[1, 2]}
            defaultValue={getValues('chestColor')}
            onChange={v => setValue('chestColor', v)}
            width="100px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>발적</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="EXIST.SHORT"
            values={[1, 2]}
            defaultValue={getValues('chestRedness')}
            onChange={v => setValue('chestRedness', v)}
            width="100px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>Bubbling</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="EXIST.SHORT"
            values={[1, 2]}
            defaultValue={getValues('chestBubbling')}
            onChange={v => setValue('chestBubbling', v)}
            width="100px"
          />
        </S.ItemWrap>
      </S.Line>
    </S.ContentContainer>
  );
}
