import { forwardRef } from 'react';
import { Checkbox, CheckboxProps, FormControlLabel, Box } from '@mui/material';

interface Props extends CheckboxProps {
  label: string;
  defaultValue: string[];
}

const MuiCheckbox = forwardRef(
  ({ label, defaultValue, ...props }: Props, ref) => (
    <FormControlLabel
      label={
        <Box component="div" fontSize={14} fontFamily={'Spoqa'}>
          {label}
        </Box>
      }
      inputRef={ref}
      control={
        <Checkbox
          size="small"
          value={label}
          defaultChecked={defaultValue.includes(label)}
          sx={{
            '&.Mui-checked': {
              color: '#604180'
            }
          }}
          {...props}
        />
      }
      sx={{ flexWrap: 'nowrap', whiteSpace: 'nowrap', display: 'inline-flex' }}
    />
  )
);

export default MuiCheckbox;
