import adornment from 'form/adornment';
import MuiTextField from 'form/MuiTextField';
import MuiRadioGroup from 'form/MuiRadioGroup';
import * as S from '../../../styles/ObservationStyles/ModalStyles';
import { IFormRegister, IFormValues } from '../../type';
import { ReactComponent as Body } from 'assets/img/body.svg';

interface Props extends IFormRegister, IFormValues {}

export default function Ttube(props: Props) {
  const { register, getValues, setValue } = props;
  return (
    <S.ContentContainer>
      <S.Title>T-Tube</S.Title>
      <S.ItemWrap>
        <S.ItemsHead>크기</S.ItemsHead>
        <MuiTextField
          required={false}
          sx={{
            minWidth: '130px',
            width: '130px',
            marginLeft: '10px'
          }}
          InputProps={{ ...adornment('', 'Fr') }}
          {...register('tSize')}
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>위치 이상</S.ItemsHead>
        <MuiRadioGroup
          i18nKey="EXIST.SHORT"
          values={[1, 2]}
          defaultValue={getValues('tLocation')}
          onChange={v => setValue('tLocation', v)}
          width="70px"
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <Body />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>Ballooning 이상</S.ItemsHead>
        <MuiRadioGroup
          i18nKey="EXIST.SHORT"
          values={[1, 2]}
          defaultValue={getValues('tSwelling')}
          onChange={v => setValue('tRedness', v)}
          width="70px"
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>삼출물</S.ItemsHead>
        <MuiRadioGroup
          i18nKey="EXIST.EXUDATION"
          values={[1, 2]}
          defaultValue={getValues('tExudate')}
          onChange={v => setValue('tExudate', v)}
          width="70px"
        />
        <MuiTextField
          required={false}
          sx={{
            minWidth: '130px',
            width: '400px',
            marginLeft: '-10px'
          }}
          placeholder="직접 입력"
          {...register('tExudateInput')}
        />
      </S.ItemWrap>
    </S.ContentContainer>
  );
}
