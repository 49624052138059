import Header from 'components/Common/Header';
import * as S from '../../styles/InfoStyles/InfoStyles';
import History from './History';
import PatientInfo from './PatientInfo';
import Vital from './Vital';
import Special from './Special';

export default function Info() {
  return (
    <>
      <></>
      <S.Layout>
        <S.Container>
          <S.ContainerWrap>
            <S.InfoContainer>
              <PatientInfo />
            </S.InfoContainer>
            <S.HistoryContainer>
              <History />
            </S.HistoryContainer>
          </S.ContainerWrap>
          <S.ContainerWrapTwo>
            <S.SBARContainer>
              <Vital />
            </S.SBARContainer>
            <S.SpecialContainer>
              <Special />
            </S.SpecialContainer>
          </S.ContainerWrapTwo>
        </S.Container>
      </S.Layout>
    </>
  );
}
