import * as S from '../../../../styles/ObservationStyles/ObservationStyles';

import ActionButtons from './ActionButtons';
import { IClinicalObs } from 'apis/observation/type';

interface Props extends IClinicalObs {
  activeId?: number;
  studentNo: string | undefined;
  nurseName: string;
  refetch?: () => void;
}

const TunnelItem = (props: Props) => {
  const {
    activeId,
    studentNo,
    nurseName,
    content,
    create_at,
    option_type,
    refetch,
    xr_clinical_observation_id,
    ...otherProps
  } = props;

  const actionButtons = refetch ? (
    <ActionButtons
      {...otherProps}
      content={content}
      option_type={option_type}
      xr_clinical_observation_id={xr_clinical_observation_id}
      refetch={refetch}
    />
  ) : null;

  const contents = JSON.parse(content);
  const contentKeys = Object.keys(contents) as any[];

  return (
    <div
      style={{ width: '286px', display: 'flex', backgroundColor: '#F2EDFB' }}
    >
      <S.ItemWrap>
        <S.CategoryItemWrap>
          <S.ItemDesc key={contentKeys[0]}>
            <span className="buttontack">
              <span className="timetack">{props.check_time}</span>
              <span>{actionButtons}</span>
            </span>
            <span>
              {contents.tunnelSize === 1
                ? 'Hickman cath'
                : contents.tunnelSize === 2
                  ? 'Cook cath'
                  : contents.tunnelSize === 3
                    ? 'Groshong cath'
                    : contents.tunnelSize === 4
                      ? 'Jugular cath'
                      : contents.tunnelSize === 5
                        ? 'Subclavian cath'
                        : 'Femoral cath'}
              :
            </span>
            <span>
              위치 : {contents.tunnelLocation} / 발적 :{' '}
              {contents.tunnelRedness === '1' ? '무' : '유'} / 부종 :{''}
              {contents.tunnelSwelling === '1' ? '무' : '유'} / 삼출물 :{''}
              {contents.tunnelExudate === '1'
                ? '무'
                : contents.tunnelExudateInput}
            </span>
          </S.ItemDesc>
        </S.CategoryItemWrap>
      </S.ItemWrap>
    </div>
  );
};

export default TunnelItem;
