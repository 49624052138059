import * as S from '../../../styles/ObservationStyles/PainConditionStyle';

import {
  Checkbox,
  FormHelperText,
  Box,
  Grid,
  Typography,
  useTheme,
  TableBody,
  TableCell,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  Table
} from '@mui/material';
import { IFormRegister, IFormValues, IFormWatch } from '../../type';
import { ReactComponent as Body } from 'assets/img/body.svg';
import useTableForm from 'form/useTableForm';
import MuiTable from 'form/MuiTable';
import MuiTextField from 'form/MuiTextField';
import MuiRadioGroup from 'form/MuiRadioGroup';
import adornment from 'form/adornment';
import { useState } from 'react';

interface Props extends IFormRegister, IFormValues, IFormWatch {}
export default function STarT(props: Props) {
  const { register, getValues, setValue } = props;
  const { radioGroup, sumValues } = useTableForm(props);

  const columns = [
    {
      fieldId: 'title',
      label: ''
    },
    {
      fieldId: '0',
      label: '',
      sx: { width: '200px' }
    },
    {
      fieldId: '1',
      label: '',
      sx: { width: '200px' }
    }
  ];

  const rows = [
    {
      id: 'sb01',
      title:
        '1. My back pain has spread down my leg(s) at some time in the last 2 weeks.',
      ...radioGroup({
        key: 'sb01',
        options: [0, 1],
        i18nKey: 'STARTSCREENING.SCORE',
        width: '150px',
        sx: { whiteSpace: 'nowrap' }
      })
    },
    {
      id: 'sb02',
      title:
        '2. I have had pain in the shoulder or neck at some time in the last 2 weeks.',
      ...radioGroup({
        key: 'sb02',
        options: [0, 1],
        i18nKey: 'STARTSCREENING.SCORE',
        sx: { whiteSpace: 'nowrap' },
        width: '150px'
      })
    },
    {
      id: 'sb03',
      title:
        '3. I have only walked short distances because of my back pain in the last 2 weeks.',
      ...radioGroup({
        key: 'sb03',
        options: [0, 1],
        i18nKey: 'STARTSCREENING.SCORE',
        sx: { whiteSpace: 'nowrap' },
        width: '150px'
      })
    },
    {
      id: 'sb04',
      title:
        '4. In the last 2 weeks, I have dressed more slowly than usual because of back pain.',
      ...radioGroup({
        key: 'sb04',
        options: [0, 1],
        i18nKey: 'STARTSCREENING.SCORE',
        sx: { whiteSpace: 'nowrap' },
        width: '150px'
      })
    },
    {
      id: 'sb05',
      title:
        '5. It is not really safe for a person with a costartbacktion like mine to be physically active.',
      ...radioGroup({
        key: 'sb05',
        options: [0, 1],
        i18nKey: 'STARTSCREENING.SCORE',
        sx: { whiteSpace: 'nowrap' },
        width: '150px'
      })
    },
    {
      id: 'sb06',
      title:
        '6. Worrying thoughts have been going through my mind a lot of the time.',
      ...radioGroup({
        key: 'sb06',
        options: [0, 1],
        i18nKey: 'STARTSCREENING.SCORE',
        sx: { whiteSpace: 'nowrap' },
        width: '150px'
      })
    },
    {
      id: 'sb07',
      title:
        "7. I feel that my back pain is terrible and it's never going to get any better.",
      ...radioGroup({
        key: 'sb07',
        options: [0, 1],
        i18nKey: 'STARTSCREENING.SCORE',
        sx: { whiteSpace: 'nowrap' },
        width: '150px'
      })
    },
    {
      id: 'sb08',
      title:
        '8. In general, I have not enjoyed all the things I used to enjoy.',
      ...radioGroup({
        key: 'sb08',
        options: [0, 1],
        i18nKey: 'STARTSCREENING.SCORE',
        sx: { whiteSpace: 'nowrap' },
        width: '150px'
      })
    }
  ];

  const [nine, setNine] = useState(getValues('sb09') ? getValues('sb09') : 0);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (Number(e.target.value) === 3) {
      return setNine('1'), setValue('sb09', '3');
    } else if (Number(e.target.value) === 4) {
      return setNine('1'), setValue('sb09', '4');
    } else if (Number(e.target.value) === 2) {
      return setNine('0'), setValue('sb09', '2');
    } else if (Number(e.target.value) === 1) {
      return setNine('0'), setValue('sb09', '1');
    } else {
      return setNine('0'), setValue('sb09', '0');
    }
  };

  return (
    <div>
      <Typography
        sx={{ fontSize: '20px', fontWeight: 700, whiteSpace: 'nowrap' }}
      >
        STarT(Subgroups for Targeted Treatment) Back Screening
      </Typography>
      <Grid item xs={12}>
        <MuiTable columns={columns} rows={rows} />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: '400',
                    width: '500px'
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  9. Overall, how bothersome has your back pain been in the last
                  2 weeks?
                </Typography>
                <RadioGroup
                  name={'sb09'}
                  defaultValue={
                    Number(getValues('sb09')) < 5
                      ? Number(getValues('sb09'))
                      : 5
                  }
                >
                  <TableRow
                    sx={{
                      height: '44px',
                      lineHeight: '44px',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    <Box sx={{ display: 'block' }}>
                      <FormControlLabel
                        sx={{ width: '15.3vw' }}
                        label={
                          <Typography sx={{ fontSize: '12px' }}>
                            Not at all (0 point)
                          </Typography>
                        }
                        control={
                          <Radio
                            size="small"
                            name={`sb09`}
                            value={0}
                            onChange={handleChange}
                            sx={{
                              '&.Mui-checked': {
                                color: '#604180'
                              }
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        sx={{ width: '15.3vw' }}
                        label={
                          <Typography sx={{ fontSize: '12px' }}>
                            Slightly (0 point)
                          </Typography>
                        }
                        control={
                          <Radio
                            size="small"
                            name={'sb09'}
                            value={1}
                            onChange={handleChange}
                            sx={{
                              '&.Mui-checked': {
                                color: '#604180'
                              }
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        sx={{ width: '15.3vw' }}
                        label={
                          <Typography sx={{ fontSize: '12px' }}>
                            Moderately (0 point)
                          </Typography>
                        }
                        control={
                          <Radio
                            size="small"
                            name={'sb09'}
                            value={2}
                            onChange={handleChange}
                            sx={{
                              '&.Mui-checked': {
                                color: '#604180'
                              }
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        sx={{ width: '15.3vw' }}
                        label={
                          <Typography sx={{ fontSize: '12px' }}>
                            Very much (1 point)
                          </Typography>
                        }
                        control={
                          <Radio
                            size="small"
                            name={'sb09'}
                            value={3}
                            onChange={handleChange}
                            sx={{
                              '&.Mui-checked': {
                                color: '#604180'
                              }
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        sx={{ width: '150px', whiteSpace: 'nowrap' }}
                        label={
                          <Typography sx={{ fontSize: '12px' }}>
                            Extremely (1 point)
                          </Typography>
                        }
                        control={
                          <Radio
                            size="small"
                            name={'sb09'}
                            value={4}
                            onChange={handleChange}
                          />
                        }
                      />
                    </Box>
                  </TableRow>
                </RadioGroup>
              </TableCell>
              <TableCell>
                <Typography
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  textAlign={'right'}
                ></Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
          <Typography
            minWidth={115}
            variant="caption"
            sx={{ color: '#604180', marginTop: ' 20px' }}
          >
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                Low risk:
              </Box>
              Total score-3 or less
            </Typography>
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                Medium risk:
              </Box>
              Total score-4 or more, Sub score(Q5~9)-3 or less
            </Typography>
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                high risk:
              </Box>
              Total score-4 or more, Sub score(Q5~9)-4 or more
            </Typography>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ width: '100%', marginTop: '40px', marginBottom: '12px' }}>
          <S.ContentContainer>
            <S.ItemWrap>
              <S.ItemsHead>통증점수</S.ItemsHead>
              <MuiTextField
                required={false}
                sx={{
                  minWidth: '130px',
                  width: '130px'
                }}
                InputProps={{ ...adornment('', '점') }}
                {...register('startbackScore')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증부위</S.ItemsHead>
              <MuiTextField
                required={false}
                placeholder="직접 입력"
                {...register('startbackWay')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <Body />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증양상</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.PATTERN"
                values={[1, 2]}
                defaultValue={getValues('startbackPattern')}
                onChange={v => setValue('startbackPattern', v)}
                width="100px"
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증빈도</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.FREQUENCY"
                values={[1, 2, 3]}
                defaultValue={getValues('startbackFrequency')}
                onChange={v => setValue('startbackFrequency', v)}
                width="100px"
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증기간</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.LONG"
                values={[1, 2]}
                defaultValue={getValues('startbackLong')}
                onChange={v => setValue('startbackLong', v)}
                width="100px"
              />
              <MuiTextField
                required={false}
                placeholder="직접 입력"
                {...register('startbackLongInput')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증중재</S.ItemsHead>
              <span className="medi">비약물적 중재</span>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(
                    getValues('startbackNoneMediChecked1')
                  )}
                  {...register('startbackNoneMediChecked1')}
                  onChange={(e, checked) => {
                    setValue('startbackNoneMediChecked1', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>심호흡 방법 교육</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(
                    getValues('startbackNoneMediChecked2')
                  )}
                  {...register('startbackNoneMediChecked2')}
                  onChange={(e, checked) => {
                    setValue('startbackNoneMediChecked2', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>냉찜질 시행</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(
                    getValues('startbackNoneMediChecked3')
                  )}
                  {...register('startbackNoneMediChecked3')}
                  onChange={(e, checked) => {
                    setValue('startbackNoneMediChecked3', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>휴식</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(
                    getValues('startbackNoneMediChecked4')
                  )}
                  {...register('startbackNoneMediChecked4')}
                  onChange={(e, checked) => {
                    setValue('startbackNoneMediChecked4', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>기타</span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('startbackNoneMediInput')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHeadDummy></S.ItemsHeadDummy>
              <span className="none_medi">약물적 중재</span>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('startbackMediChecked'))}
                  {...register('startbackMediChecked')}
                  onChange={(e, checked) => {
                    setValue('startbackMediChecked', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>약물명 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('startbackMediInput1')}
              />
              <S.CheckboxWrap>
                <span>용량 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('startbackMediInput2')}
              />
              <S.CheckboxWrap>
                <span>투여방법 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('startbackMediInput3')}
              />
            </S.ItemWrap>
          </S.ContentContainer>
        </Box>
      </Grid>
    </div>
  );
}
