import styled from 'styled-components';
import { TableCell } from '@mui/material';

export const ModalContainer = styled.div<{ $showModal: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 82%;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 102;
  visibility: ${props => (props.$showModal ? 'visible' : 'hidden')};
  display: ${props => (props.$showModal ? 'block' : 'none')};
  .modal-title {
    font-size: 20px;
    font-weight: 700;
  }
  max-height: 90vh;
  overflow-y: scroll;
`;

export const ModalHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  img {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
`;

export const HeadText = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const ModalSave = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  span {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
  }
  color: gray;
`;

export const SaveBtn = styled.button`
  width: 58px;
  height: 32px;
  color: #fff;
  border: none;
  border-radius: 4px;
  background: ${props => props.theme.color.brand_300};
  font-family: 'Spoqa', sans-serif;
  cursor: pointer;
`;

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  .total-div {
    display: flex;
    justify-content: flex-end;
  }
  .total {
    display: flex;
    justify-content: flex-end;
  }
  .score {
    display: flex;
    flex-direction: column;
    justify-content: right;
    gap: 4px;
    font-size: 12px;
    color: ${props => props.theme.color.brand_300};
  }
  .standard {
    font-weight: 700;
  }
`;

export const DateTimeWrap = styled.div`
  display: flex;
  gap: 12px;
`;

export const BtnWrap = styled.div`
  display: flex;
  gap: 12px;
`;

export const CategoryBtn = styled.button<{ selected: boolean }>`
  background: ${props =>
    props.selected ? props.theme.color.brand_300 : '#979797'};
  color: #fff;
  width: 140px;
  height: 36px;
  font-size: 12px;
  line-height: 15px;
  font-family: 'Spoqa', sans-serif;
  border: ${props => (props.selected ? 'none' : '1px solid #979797')};
  border-radius: 4px;
  cursor: pointer;
`;

export const IndexTitle = styled.div`
  display: flex;
  width: 100%;
  background: #f0f0f0;
  border-top: 1px solid #414040;
  font-size: 13px;
  font-weight: 700;
  span {
    padding: 8px;
  }
`;

export const IndexContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ItemWrap = styled.div`
  display: flex;
  width: 100%;
  padding: 4px;
  align-items: center;
  border-bottom: 1px solid #8d8d8d;
`;

export const CheckWrap = styled.div`
  display: flex;
  width: 100%;
  padding: 4px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #8d8d8d;
`;

export const ItemsHead = styled.span`
  display: flex;
  align-items: center;
  width: 250px;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
`;

export const ScoreWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  span {
    width: 250px;
    text-align: center;
  }
`;

export const BedsoreContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const BedsoreImgWrap = styled.div`
  display: flex;
  width: 100%;
  min-height: 600px;
  flex-direction: column;
  align-items: center;
  position: relative;
  img {
    position: absolute;
    top: 20%;
  }
`;

export const BedsoreRecordWrap = styled.div`
  display: flex;
  width: 100%;
  padding: 4px;
  align-items: center;
`;

export const RestraintItemWrap = styled.div`
  display: flex;
`;

export const StatusWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  .status {
    font-size: 14px;
  }
`;
