import styled from 'styled-components';

export const Layout = styled.div`
  width: 100%;
  height: calc(100% - 240px);
  /* margin-top: 240px; */
  min-height: 684px;
  background: #fff;
`;

export const Container = styled.div`
  margin: 12px;
  height: 100%;
  display: flex;
  gap: 8px;
`;

export const ContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const InfoContainer = styled.div`
  width: 100%;
  flex: 3;
  background: ${props => props.theme.color.brand_100};
  border-radius: 4px;
`;

export const PrescriptionContainer = styled.div`
  width: 100%;
  flex: 7;
  background: ${props => props.theme.color.brand_100};
  border-radius: 4px;
`;

export const SBARContainer = styled.div`
  width: 70%;
  height: 100%;
  background: ${props => props.theme.color.brand_100};
  border-radius: 4px;
`;
