import styled from 'styled-components';

export const Layout = styled.div`
  width: 100%;
  /* margin-top: 240px; */
  height: 100%;
  min-height: 750px;
  background: ${props => props.theme.color.brand_100};
`;

export const Container = styled.div`
  padding: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .patient {
    font-weight: 500;
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 4px;
    margin-left: 8px;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
`;

export const CategoryBtn = styled.button<{ $selected: boolean }>`
  background: ${props =>
    props.$selected ? props.theme.color.brand_300 : '#979797'};
  color: #fff;
  width: 100px;
  height: 36px;
  font-size: 12px;
  line-height: 15px;
  font-family: 'Spoqa', sans-serif;
  border: ${props => (props.$selected ? 'none' : '1px solid #a7a7a8')};
  border-radius: 4px;
  cursor: pointer;
`;

export const ContainerWrap = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const LeftLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2.5;
`;

export const RightLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 7.5;
`;

/* Left : 욕창 부위, 억제대 부위 */
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
`;

export const ViewTypeTitle = styled.div`
  display: flex;
  align-items: center;
  border: 4px;
  background: #fff;
  width: 100%;
  font-weight: 700;
  font-size: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
  span {
    margin-left: 12px;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 600px;
  border: 4px;
  background: #fff;
  align-items: center;
  justify-content: center;
  img {
    width: 190px;
    height: 100%;
  }
  padding: 10px;
`;

/* Right : 기록 섹션 */
export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
`;

export const AddBtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RecordWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  div {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  img {
    width: 20px;
    height: 20px;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const Separator = styled.span`
  height: 12px;
  margin: 0;
  padding: 0;
  border: 0.5px solid ${props => props.theme.color.black_800};
`;

export const AddBtn = styled.button<{ background: string }>`
  background: ${props => props.background};
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px;
  height: 36px;
  font-family: 'Spoqa', sans-serif;
  cursor: pointer;
`;

export const FallCardContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

export const FallCardWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  padding: 4px;
  .date-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
  }
  .icon-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  img {
    cursor: pointer;
  }
  width: 336px;
`;

export const ContentWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  padding: 6px 0px 6px 2px;
`;

export const BedsoreContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const DamagePrevention = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 0px 6px 2px;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4px;
  }
`;

export const Overlay = styled.div<{ $show: boolean }>`
  display: ${({ $show }) => ($show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 101;
`;
