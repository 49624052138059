import * as S from '../../../styles/ObservationStyles/ModalStyles';
import X from '../../../assets/img/x-icon.png';
import { useCallback, useEffect, useState } from 'react';
import NRS from './NRS';
import FLACC from './FLACC';
import CNPS from './CNPS';
import FFI from './FFI';
import KOOS from './KOOS';
import LEFS from './LEFS';
import NDI from './NDI';
import STarT from './STarT';

import { MobileTimePicker } from '@mui/x-date-pickers';
import { AccessTime } from '@mui/icons-material';
import MuiTextField from 'form/MuiTextField';
import { initialClinicalObservation } from './initialState';
import { useForm } from 'react-hook-form';
import { RECORD_TYPE_PAIN } from 'apis/observation/type';
import useUser from 'store/user/useUser';
import useNotification from 'hook/useNotification';
import usePatient from 'store/patient/usePatient';
import { initialPain } from './initialStates';
import { findKeyValue } from 'apis/convert';
import { createObservation } from 'apis/observation/observationApi';
import MuiRadioGroup from 'form/MuiRadioGroup';

interface OwnProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AddPain({ showModal, setShowModal }: OwnProps) {
  const curDate = new Date();
  const curDateStr = curDate.toISOString().split('T')[0];
  const curDateTime = curDate;

  const [recordType, setRecordType] = useState<string>(RECORD_TYPE_PAIN.NRS);
  const [recordDate, setRecordDate] = useState<string | null>(curDateStr);
  const [recordTime, setRecordTime] = useState<Date | null>(curDateTime);
  const [painYN, setPainYN] = useState(1);

  const { student_uuid: user_id } = useUser();
  const { onSuccess, onFail, onResultCode } = useNotification();
  const { patient, onClearClinicalObs, xrClinicalObservation } = usePatient();
  const { register, watch, setValue, getValues, handleSubmit, reset } =
    useForm();

  const handleCategoryClick = (num: string) => {
    setRecordType(num);
  };

  const onRestNursingRecord = useCallback(() => {
    setRecordTime(new Date());
    setRecordType(RECORD_TYPE_PAIN.NRS);
    reset(initialPain.nrs);
    onClearClinicalObs();
  }, [reset, onClearClinicalObs]);

  useEffect(() => {
    if (!xrClinicalObservation) return onRestNursingRecord();

    const { check_time, option_type, content } = xrClinicalObservation;
    reset(JSON.parse(content));
    setRecordType(`${option_type}`);
    setRecordTime(new Date(check_time));
  }, [xrClinicalObservation, reset, onClearClinicalObs]);

  const onSubmit = (data: any) => {
    let contentKeys: string[] = [];

    // update content
    switch (recordType) {
      case RECORD_TYPE_PAIN.NRS: {
        contentKeys = Object.keys(initialPain.nrs);
        break;
      }
      case RECORD_TYPE_PAIN.FLACC: {
        contentKeys = Object.keys(initialPain.flacc);
        break;
      }
      case RECORD_TYPE_PAIN.CNPS: {
        contentKeys = Object.keys(initialPain.cnps);
        break;
      }
      case RECORD_TYPE_PAIN.FFI: {
        contentKeys = Object.keys(initialPain.ffi);
        break;
      }
      case RECORD_TYPE_PAIN.KOOS: {
        contentKeys = Object.keys(initialPain.koos);
        break;
      }
      case RECORD_TYPE_PAIN.LEFS: {
        contentKeys = Object.keys(initialPain.lefs);
        break;
      }
      case RECORD_TYPE_PAIN.NDI: {
        contentKeys = Object.keys(initialPain.ndi);
        break;
      }
      case RECORD_TYPE_PAIN.START_BACK: {
        contentKeys = Object.keys(initialPain.startback);
        break;
      }
    }

    const content = findKeyValue(data, contentKeys);

    const checkTime =
      recordDate && recordTime
        ? `${recordDate} ${recordTime.getHours()}:${recordTime.getMinutes()}`
        : null;

    const request = {
      option_type: Number(recordType),
      check_time: checkTime,
      content: JSON.stringify(content)
    };

    createObservation({
      user_id,
      patient_id: patient!.patient_id,
      ...request
    })
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);
        onClearClinicalObs();
        location.reload();
        onSuccess('저장성공.');
        setShowModal(false);
      })
      .catch(e => onFail('저장실패.', e));
  };

  const formProps = {
    register,
    watch,
    getValues,
    setValue
  };

  return (
    <S.ModalContainer $showModal={showModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.ModalHead>
          <S.HeadText>
            <img
              src={X}
              alt="cancel"
              onClick={() => {
                setShowModal(false);
              }}
            />
            <span>통증 사정 추가</span>
          </S.HeadText>
          <S.ModalSave>
            <span>최근 저장한 시간 : 2022-10-12 13:30 PM</span>
            <S.SaveBtn type="submit">저장</S.SaveBtn>
          </S.ModalSave>
        </S.ModalHead>
        <S.ModalContentContainer>
          <S.DateTimeWrap>
            <MuiTextField
              type="date"
              // disabled={disabled}
              fullWidth
              required={false}
              sx={{ width: '120px' }}
              value={recordDate}
              onChange={e => setRecordDate(e.target.value)}
            />
            <MobileTimePicker
              value={recordTime}
              onChange={setRecordTime}
              // disabled={!isStudent}
              renderInput={params => (
                <MuiTextField
                  {...params}
                  fullWidth
                  InputProps={{ endAdornment: <AccessTime /> }}
                  size="small"
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: '12px'
                    },
                    width: '120px'
                  }}
                />
              )}
            />
          </S.DateTimeWrap>
          <S.ItemWrap>
            <S.ItemsHead>통증 여부</S.ItemsHead>
            <S.RadioWrap>
              <MuiRadioGroup
                i18nKey="EXIST.SHORT"
                values={[1, 2]}
                defaultValue={painYN}
                onChange={v => setPainYN(v)}
                width="70px"
              />
            </S.RadioWrap>
          </S.ItemWrap>
          <S.BtnWrap>
            <S.CategoryBtn
              type="button"
              selected={recordType === RECORD_TYPE_PAIN.NRS}
              onClick={() => handleCategoryClick(RECORD_TYPE_PAIN.NRS)}
            >
              NRS
            </S.CategoryBtn>
            <S.CategoryBtn
              type="button"
              selected={recordType === RECORD_TYPE_PAIN.FLACC}
              onClick={() => handleCategoryClick(RECORD_TYPE_PAIN.FLACC)}
            >
              FLACC
            </S.CategoryBtn>
            <S.CategoryBtn
              type="button"
              selected={recordType === RECORD_TYPE_PAIN.CNPS}
              onClick={() => handleCategoryClick(RECORD_TYPE_PAIN.CNPS)}
            >
              CNPS
            </S.CategoryBtn>
            <S.CategoryBtn
              type="button"
              selected={recordType === RECORD_TYPE_PAIN.FFI}
              onClick={() => handleCategoryClick(RECORD_TYPE_PAIN.FFI)}
            >
              FFI
            </S.CategoryBtn>
            <S.CategoryBtn
              type="button"
              selected={recordType === RECORD_TYPE_PAIN.KOOS}
              onClick={() => handleCategoryClick(RECORD_TYPE_PAIN.KOOS)}
            >
              KOOS
            </S.CategoryBtn>
            <S.CategoryBtn
              type="button"
              selected={recordType === RECORD_TYPE_PAIN.LEFS}
              onClick={() => handleCategoryClick(RECORD_TYPE_PAIN.LEFS)}
            >
              LEFS
            </S.CategoryBtn>
            <S.CategoryBtn
              type="button"
              selected={recordType === RECORD_TYPE_PAIN.NDI}
              onClick={() => handleCategoryClick(RECORD_TYPE_PAIN.NDI)}
            >
              NDI
            </S.CategoryBtn>
            <S.CategoryBtn
              type="button"
              selected={recordType === RECORD_TYPE_PAIN.START_BACK}
              onClick={() => handleCategoryClick(RECORD_TYPE_PAIN.START_BACK)}
            >
              STarT Back Screening
            </S.CategoryBtn>
          </S.BtnWrap>
          {recordType === RECORD_TYPE_PAIN.NRS && <NRS {...formProps} />}
          {recordType === RECORD_TYPE_PAIN.FLACC && <FLACC {...formProps} />}
          {recordType === RECORD_TYPE_PAIN.CNPS && <CNPS {...formProps} />}
          {recordType === RECORD_TYPE_PAIN.FFI && <FFI {...formProps} />}
          {recordType === RECORD_TYPE_PAIN.KOOS && <KOOS {...formProps} />}
          {recordType === RECORD_TYPE_PAIN.LEFS && <LEFS {...formProps} />}
          {recordType === RECORD_TYPE_PAIN.NDI && <NDI {...formProps} />}
          {recordType === RECORD_TYPE_PAIN.START_BACK && (
            <STarT {...formProps} />
          )}
        </S.ModalContentContainer>
      </form>
    </S.ModalContainer>
  );
}
