import * as S from '../../styles/SafetyStyles/ModalStyles';
import MuiRadioGroup from 'form/MuiRadioGroup';
import MuiCheckbox from 'form/MuiCheckbox';
import { IFormRegister, IFormValues } from '../type';
interface Props extends IFormRegister, IFormValues {}

export default function KidMFS(props: Props) {
  const { register, getValues, setValue } = props;

  return (
    <>
      <S.IndexTitle>
        <span>소아 낙상위험 평가</span>
      </S.IndexTitle>
      <S.IndexContent>
        <S.ItemWrap>
          <S.ItemsHead>평가 항목</S.ItemsHead>
          <S.ScoreWrap>
            <span style={{ marginLeft: '-28px' }}>1점</span>
            <span>2점</span>
            <span>3점</span>
            <span style={{ marginLeft: '15px' }}>4점</span>
          </S.ScoreWrap>
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>나이</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="SAFETY.AGE"
            values={[1, 2, 3, 4]}
            defaultValue={getValues('kid_mfs.age.value')}
            onChange={v => setValue('kid_mfs.age.value', v)}
            width="250px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>성별</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="SAFETY.GENDER"
            values={[1, 2]}
            defaultValue={getValues('kid_mfs.gender.value')}
            onChange={v => setValue('kid_mfs.gender.value', v)}
            width="250px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>진단</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="SAFETY.DIAGNOSIS"
            values={[1, 2, 3, 4]}
            defaultValue={getValues('kid_mfs.diagnosis.value')}
            onChange={v => setValue('kid_mfs.diagnosis.value', v)}
            width="250px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>인지장애</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="SAFETY.COGNITIVE.IMPAIRMENT"
            values={[1, 2, 3]}
            defaultValue={getValues('kid_mfs.cognitive_impairment.value')}
            onChange={v => setValue('kid_mfs.cognitive_impairment.value', v)}
            width="250px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>환경요인</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="SAFETY.ENVIRONMENTAL"
            values={[1, 2, 3]}
            defaultValue={getValues('kid_mfs.environmental.value')}
            onChange={v => setValue('kid_mfs.environmental.value', v)}
            width="250px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>수술 / 진정 / 마취요인</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="SAFETY.SURGICAL"
            values={[1, 2, 3]}
            defaultValue={getValues('kid_mfs.surgical.value')}
            onChange={v => setValue('kid_mfs.surgical.value', v)}
            width="250px"
          />
        </S.ItemWrap>
        <S.ItemWrap>
          <S.ItemsHead>약물 사용</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="SAFETY.DRUG.USE"
            values={[1, 2, 3]}
            defaultValue={getValues('kid_mfs.drug_use.value')}
            onChange={v => setValue('kid_mfs.drug_use.value', v)}
            width="250px"
          />
        </S.ItemWrap>
      </S.IndexContent>
      <span className="total">합계 : -- 점</span>
      <div className="total-div">
        <div className="score">
          <div>
            <span className="standard">7점~11점</span>
            <span>: 저위험군</span>
          </div>
          <div>
            <span>(낙상예방활동 기록지 작성 안함)</span>
          </div>
          <div>
            <span className="standard">12점 이상</span>
            <span>: High Risk</span>
          </div>
          <div>
            <span>(낙상예방활동 기록지 작성 3회/1일)</span>
          </div>
        </div>
      </div>
    </>
  );
}
