import { useEffect } from 'react';

import * as S from '../../styles/RecordsStyles/RecordsStyles';
import CreateRecord from './CreateRecord';
import HistoryRecord from './HistoryRecord';

import { Skeleton } from '@mui/material';

import usePatient from 'store/patient/usePatient';

export default function Records() {
  const { patientInfo } = usePatient();

  if (!patientInfo) {
    return <Skeleton variant="rectangular" sx={{ flex: 1 }} />;
  }

  const { patient_id, name, age, gender, department, room } = patientInfo;

  return (
    <>
      <S.Layout>
        <S.Container>
          <S.Title>
            <span>{name}</span>
            <span>/</span>
            <span>{gender === 1 ? '여자' : '남자'}</span>
            <span>/</span>
            <span>
              {age} ({patient_id}) {department}
            </span>
            <span>/</span>
            {/* 이 10-1은 뭔지모르겠음 .. 이게맞나*/}
            <span>{room}</span>
          </S.Title>

          <S.ContainerWrap>
            <S.HistoryContainer>
              <HistoryRecord />
            </S.HistoryContainer>
            <S.CreateContainer>
              <CreateRecord />
            </S.CreateContainer>
          </S.ContainerWrap>
        </S.Container>
      </S.Layout>
    </>
  );
}
