import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  gap: 15px;
  margin: 10px 10px 0 10px;
  flex-direction: column;
  overflow: scroll;
`;

export const ContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  /* border-bottom: 1px solid ${props => props.theme.color.black_200}; */
  .contents {
    font-size: 14px;
    font-weight: 300;
    margin-left: 8px;
    color: ${props => props.theme.color.black_600};
  }
`;

export const Title = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 1px;
`;

export const SubTitleContatiner = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

export const SubTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-right: 7px;
`;

export const SubNameAndTime = styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-right: 7px;
  color: ${props => props.theme.color.black_500};
`;

export const Contents = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-left: 8px;
`;

export const ContentsBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;
