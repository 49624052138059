import styled from 'styled-components';

export const Layout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 10px;
`;

export const Container = styled.div`
  flex: 5;
  background-color: white;
  padding: 10px;
  height: 117px;
  border-radius: 4px;

  .title {
    font-size: 14px;
    font-weight: 500;
    margin: 10px;
  }
`;

export const ContentsContainer = styled.div`
  width: 100%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ContentsBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 95%;
  .sub_title {
    font-size: 12px;
    font-weight: 500;
    width: 150px;
    min-width: 150px;
    padding: 10px;
  }
  .sub_title2 {
    font-size: 12px;
    font-weight: 500;
    /* width: 190px; */
    min-width: 160px;
    max-width: 160px;
    padding: 2px 10px 2px 10px;
  }
  .dummy_box {
    width: 170px;
    min-width: 170px;
  }
`;

export const CheckBoxWrap = styled.div`
  display: flex;
`;
