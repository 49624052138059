import * as S from '../../styles/RecordsStyles/RemarkStyles';
import { IFormRegister } from '../type';

interface Props extends IFormRegister {
  disabled?: boolean;
}

export default function Remark({ register, disabled }: Props) {
  return (
    <S.Layout>
      <S.Container>
        <S.IntputWrap>
          <S.Title>서술기록</S.Title>
          <S.Input {...register('narrativeNote')} />
        </S.IntputWrap>
      </S.Container>
    </S.Layout>
  );
}
