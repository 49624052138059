import * as S from '../../styles/CommonStyles/HeaderStyles';
import logo from '../../assets/img/logo_white.png';
import info from '../../assets/img/info.svg';
import EHR from '../../assets/img/EHR.svg';
import fillo from '../../assets/img/fillo.svg';
import observation from '../../assets/img/observation.svg';
import testResult from '../../assets/img/testResult.svg';
import records from '../../assets/img/records.svg';
import masks from '../../assets/img/masks.svg';
import safety from '../../assets/img/safety.svg';
import agreement from '../../assets/img/agreement.svg';
import DropdownMenu from './DropdownMenu';

import SearchToolbar from './SearchToolbar';
import usePatient from 'store/patient/usePatient';

import useSurvey from 'store/survey/useSurvey';

// 메뉴 아이템 배열
const menuItems = [
  { path: '/', icon: info, text: '환자 정보 요약' },
  { path: '/EHR', icon: EHR, text: '간호 EHR' },
  { path: '/fillo', icon: fillo, text: '입원 초기 사정' },
  { path: '/observation', icon: observation, text: '임상관찰' },
  { path: '/testResult', icon: testResult, text: '검사결과' },
  { path: '/records', icon: records, text: '간호기록' },
  { path: '/nursing', icon: masks, text: '수술간호' },
  { path: '/safety', icon: safety, text: '환자안전' },
  { path: '/agreement', icon: agreement, text: '동의서', disabled: true }
];

export enum MENU {
  INFO = '환자 정보 요약',
  EHR = '간호 EHR',
  FILLO = '입원 초기 사정',
  OBSERVATION = '임상관찰',
  TESTRESULT = '검사결과',
  RECORDS = '간호기록',
  NURSING = '수술간호',
  SAFETY = '환자안전',
  AGREEMENT = '동의서'
}

interface Props {
  children: React.ReactNode;
}

export default function Header(props: Props) {
  const { children } = props;
  const { patientInfo } = usePatient();
  const { onUpdateSurveyType, surveyType } = useSurvey();

  const handleNav = (label: string) => {
    if (!patientInfo) {
      return alert('환자를 선택해주세요');
    }
    // navigate(path);
    onUpdateSurveyType(label);
  };

  return (
    <S.Layout
      style={{
        backgroundColor:
          surveyType === '환자 정보 요약' ||
          surveyType === '간호 EHR' ||
          surveyType === '검사결과'
            ? 'white'
            : '#F2EDFB'
      }}
    >
      <S.Container>
        <S.Inner>
          <S.LogoWrap>
            <img src={logo} alt="logo" />
            <S.DropDown>
              <DropdownMenu target="" />
            </S.DropDown>
          </S.LogoWrap>

          <S.ToolbarBox>
            <SearchToolbar />
          </S.ToolbarBox>
          <S.MenuContainer>
            <S.MenuWrap>
              {menuItems.map(item => (
                <S.Menu
                  key={item.path}
                  $isSelected={surveyType === item.text}
                  onClick={() => {
                    !item.disabled && handleNav(item.text);
                  }}
                  $isDisabled={item.disabled}
                >
                  <img src={item.icon} alt="menu-icon" />
                  <span>{item.text}</span>
                </S.Menu>
              ))}
            </S.MenuWrap>
          </S.MenuContainer>
        </S.Inner>
      </S.Container>
      <S.BodyContainer>{children}</S.BodyContainer>
    </S.Layout>
  );
}
