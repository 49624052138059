import * as S from '../../../../styles/ObservationStyles/ObservationStyles';

import ActionButtons from './ActionButtons';
import { IClinicalObs } from 'apis/observation/type';

interface Props extends IClinicalObs {
  activeId?: number;
  studentNo: string | undefined;
  nurseName: string;
  refetch?: () => void;
}

const TtubeItem = (props: Props) => {
  const {
    activeId,
    studentNo,
    nurseName,
    content,
    create_at,
    option_type,
    refetch,
    xr_clinical_observation_id,
    ...otherProps
  } = props;

  const actionButtons = refetch ? (
    <ActionButtons
      {...otherProps}
      content={content}
      option_type={option_type}
      xr_clinical_observation_id={xr_clinical_observation_id}
      refetch={refetch}
    />
  ) : null;

  const contents = JSON.parse(content);
  const contentKeys = Object.keys(contents) as any[];

  console.log('contents', contents);

  return (
    <div
      style={{ width: '286px', display: 'flex', backgroundColor: '#F2EDFB' }}
    >
      <S.ItemWrap>
        <S.CategoryItemWrap>
          <S.ItemDesc key={contentKeys[0]}>
            <span className="buttontack">
              <span className="timetack">{props.check_time}</span>
              <span>{actionButtons}</span>
            </span>
            <span>{contents.tSize}G :</span>
            <span>
              위치이상: {contents.tLocation === '1' ? '무' : '유'} / Ballooning
              : {contents.tRedness === '1' ? '무' : '유'} / 삼출물 :{' '}
              {contents.tExudate === '1' ? '무' : contents.tExudateInput}
            </span>
          </S.ItemDesc>
        </S.CategoryItemWrap>
      </S.ItemWrap>
    </div>
  );
};

export default TtubeItem;
