import * as S from '../../styles/InfoStyles/HistoryStyles';
import usePatient from 'store/patient/usePatient';
import { Skeleton } from '@mui/material';

export default function History() {
  const { patientInfo } = usePatient();

  if (!patientInfo) {
    return <Skeleton variant="rectangular" sx={{ flex: 1 }} />;
  }

  const { name, age, gender, medical_note } = patientInfo;

  return (
    <S.Layout>
      <S.Container>
        <S.TitleBtnWrap>
          <span className="title"> 사례 개요</span>
          {/* <S.AdditionalBtn>추가 처방</S.AdditionalBtn> */}
        </S.TitleBtnWrap>

        <S.Contents>
          <S.Inner>
            <span className="content">
              {name} ({gender === 1 ? '여자' : '남자'} / {age})
            </span>
            <S.Medication>
              <span className="medi">{medical_note}</span>
            </S.Medication>
          </S.Inner>
        </S.Contents>
      </S.Container>
    </S.Layout>
  );
}
