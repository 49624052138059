export const initialNursingRecord = {
  nanda: {
    domain: '',
    class: '',
    diagnosis: '',
    collectingData: '',
    goal: '',
    plan: '',
    interventions: '',
    evaluation: ''
  },
  soapie: {
    subjective: '',
    objective: '',
    assessment: '',
    planning: '',
    interventions: '',
    evaluation: ''
  },
  focusDar: { focus: '', data: '', action: '', response: '' },
  narrativeRecord: { narrativeNote: '' },
  remarks: {
    assessment: '',
    diagnosisRelate: '',
    diagnosis: '',
    goal: '',
    plan: '',
    interventions: '',
    evaluation: ''
  },
  remarks_two: {},
  omaha: {
    domain: '',
    problem: '',
    modifier1: '',
    modifier2: '',
    priority: '',

    sign_sx: '',
    sign_sx_etc: '',
    omaha_target1: '',
    omaha_target2: '',
    omaha_target3: '',
    omaha_target4: '',

    omaha_knowledge_selct: '',
    omaha_knowledge: '',
    omaha_behavior_selct: '',
    omaha_behavior: '',
    omaha_status_selct: '',
    omaha_status: ''
  },
  icnp: {},
  icnp_browser: {}
};
