import * as S from '../../styles/TestStyles/TestDateStyles';
import DateProgressBar from './DateProgressBar';
import { useState } from 'react';

export default function TestDate() {
  const [filledCount] = useState<number>(0);
  return (
    <S.Layout>
      <S.Container>
        <span className="date">검사기록 날짜</span>
        <DateProgressBar filledCount={filledCount} />
        <span className="date">날짜별 검사 항목</span>
        <S.CardContainer>
          <S.Card>
            <S.CardWrap>
              <span className="day">2023-05-27</span>
              <S.Items>
                <span>일반 혈액</span>
                <S.Btn>조회</S.Btn>
              </S.Items>
              <S.Items>
                <span>혈액응고</span>
                <S.Btn>조회</S.Btn>
              </S.Items>
              <S.Items>
                <span>일반화학</span>
                <S.Btn>조회</S.Btn>
              </S.Items>
              <S.Items>
                <span>요경검</span>
                <S.Btn>조회</S.Btn>
              </S.Items>
            </S.CardWrap>
          </S.Card>
          <S.Card>
            <S.CardWrap>
              <span className="day">2023-05-26</span>
              <S.Items>
                <span>Chest AP</span>
                <S.Btn>조회</S.Btn>
              </S.Items>
              <S.Items>
                <span>혈액응고</span>
                <S.Btn>조회</S.Btn>
              </S.Items>
              <S.Items>
                <span>일반 혈액</span>
                <S.Btn>조회</S.Btn>
              </S.Items>
              <S.Items>
                <span>요경검</span>
                <S.Btn>조회</S.Btn>
              </S.Items>
            </S.CardWrap>
          </S.Card>
          <S.Card>
            <S.CardWrap>
              <span className="day">2023-05-25</span>
              <S.Items>
                <span>일반 혈액</span>
                <S.Btn>조회</S.Btn>
              </S.Items>
              <S.Items>
                <span>혈액응고</span>
                <S.Btn>조회</S.Btn>
              </S.Items>
              <S.Items>
                <span>일반화학</span>
                <S.Btn>조회</S.Btn>
              </S.Items>
              <S.Items>
                <span>요경검</span>
                <S.Btn>조회</S.Btn>
              </S.Items>
            </S.CardWrap>
          </S.Card>
          <S.Card>
            <S.CardWrap>
              <span className="day">2023-05-24</span>
              <S.Items>
                <span>일반 혈액</span>
                <S.Btn>조회</S.Btn>
              </S.Items>
              <S.Items>
                <span>혈액응고</span>
                <S.Btn>조회</S.Btn>
              </S.Items>
              <S.Items>
                <span>일반화학</span>
                <S.Btn>조회</S.Btn>
              </S.Items>
              <S.Items>
                <span>요경검</span>
                <S.Btn>조회</S.Btn>
              </S.Items>
            </S.CardWrap>
          </S.Card>
        </S.CardContainer>
      </S.Container>
    </S.Layout>
  );
}
