export const initialPain = {
  nrs: {
    nrsScore: '',
    nrsWay: '',
    nrsPattern: '',
    nrsFrequency: '',
    nrsLong: '',
    nrsLongInput: '',
    nrsNoneMediChecked1: '',
    nrsNoneMediChecked2: '',
    nrsNoneMediChecked3: '',
    nrsNoneMediChecked4: '',
    nrsNoneMediInput: '',
    nrsMediChecked: '',
    nrsMediInput1: '',
    nrsMediInput2: '',
    nrsMediInput3: ''
  },
  flacc: {
    flaccFace: '',
    flaceLegs: '',
    flaceActivity: '',
    flaceCry: '',
    flaceConsolability: '',
    flaceScore: '',
    flaceWay: '',
    flacePattern: '',
    flaceFrequency: '',
    flaceLong: '',
    flaceLongInput: '',
    flaceNoneMediChecked1: '',
    flaceNoneMediChecked2: '',
    flaceNoneMediChecked3: '',
    flaceNoneMediChecked4: '',
    flaceNoneMediInput: '',
    flaceMediChecked: '',
    flaceMediInput1: '',
    flaceMediInput2: '',
    flaceMediInput3: ''
  },
  cnps: {
    cnpsFace: '',
    cnpsBody: '',
    cnpsRes: '',
    cnpsSound: '',
    cnpsScore: '',
    cnpsWay: '',
    cnpsPattern: '',
    cnpsFrequency: '',
    cnpsLong: '',
    cnpsLongInput: '',
    cnpsNoneMediChecked1: '',
    cnpsNoneMediChecked2: '',
    cnpsNoneMediChecked3: '',
    cnpsNoneMediChecked4: '',
    cnpsNoneMediInput: '',
    cnpsMediChecked: '',
    cnpsMediInput1: '',
    cnpsMediInput2: '',
    cnpsMediInput3: ''
  },
  ffi: {
    ffiSlicer1: '',
    ffiSlicer2: '',
    ffiSlicer3: '',
    ffiSlicer4: '',
    ffiSlicer5: '',
    ffiSlicer6: '',
    ffiSlicer7: '',
    ffiSlicer8: '',
    ffiSlicer9: '',
    ffiSlicer10: '',
    ffiSlicer11: '',
    ffiSlicer12: '',
    ffiSlicer13: '',
    ffiSlicer14: '',
    ffiSlicer15: '',
    ffiSlicer16: '',
    ffiSlicer17: '',
    ffiSlicer18: '',
    ffiSlicer19: '',
    ffiSlicer20: '',
    ffiSlicer21: '',
    ffiSlicer22: '',
    ffiSlicer23: '',

    ffiScore: '',
    ffiWay: '',
    ffiPattern: '',
    ffiFrequency: '',
    ffiLong: '',
    ffiLongInput: '',
    ffiNoneMediChecked1: '',
    ffiNoneMediChecked2: '',
    ffiNoneMediChecked3: '',
    ffiNoneMediChecked4: '',
    ffiNoneMediInput: '',
    ffiMediChecked1: '',
    ffiMediChecked2: '',
    ffiMediChecked: '',
    ffiMediInput1: '',
    ffiMediInput2: '',
    ffiMediInput3: ''
  },
  koos: {
    spt01: '',
    spt02: '',
    spt03: '',
    spt04: '',
    spt05: '',

    stf01: '',
    stf02: '',

    pain01: '',
    pain02: '',
    pain03: '',
    pain04: '',
    pain05: '',
    pain06: '',
    pain07: '',
    pain08: '',
    pain09: '',

    daily01: '',
    daily02: '',
    daily03: '',
    daily04: '',
    daily05: '',
    daily06: '',
    daily07: '',
    daily08: '',
    daily09: '',
    daily10: '',
    daily11: '',
    daily12: '',
    daily13: '',
    daily14: '',
    daily15: '',
    daily16: '',
    daily17: '',

    actv01: '',
    actv02: '',
    actv03: '',
    actv04: '',
    actv05: '',

    qol01: '',
    qol02: '',
    qol03: '',
    qol04: '',

    koosScore: '',
    koosWay: '',
    koosPattern: '',
    koosFrequency: '',
    koosLong: '',
    koosLongInput: '',
    koosNoneMediChecked1: '',
    koosNoneMediChecked2: '',
    koosNoneMediChecked3: '',
    koosNoneMediChecked4: '',
    koosNoneMediInput: '',
    koosMediChecked: '',
    koosMediInput1: '',
    koosMediInput2: '',
    koosMediInput3: ''
  },
  lefs: {
    lefs01: '',
    lefs02: '',
    lefs03: '',
    lefs04: '',
    lefs05: '',
    lefs06: '',
    lefs07: '',
    lefs08: '',
    lefs09: '',
    lefs10: '',
    lefs11: '',
    lefs12: '',
    lefs13: '',
    lefs14: '',
    lefs15: '',
    lefs16: '',
    lefs17: '',
    lefs18: '',
    lefs19: '',
    lefs20: '',

    lefsScore: '',
    lefsWay: '',
    lefsPattern: '',
    lefsFrequency: '',
    lefsLong: '',
    lefsLongInput: '',
    lefsNoneMediChecked1: '',
    lefsNoneMediChecked2: '',
    lefsNoneMediChecked3: '',
    lefsNoneMediChecked4: '',
    lefsNoneMediInput: '',
    lefsMediChecked: '',
    lefsMediInput1: '',
    lefsMediInput2: '',
    lefsMediInput3: ''
  },
  ndi: {
    ndi01: '',
    ndi02: '',
    ndi03: '',
    ndi04: '',
    ndi05: '',
    ndi06: '',
    ndi07: '',
    ndi08: '',
    ndi09: '',
    ndi10: '',

    ndiScore: '',
    ndiWay: '',
    ndiPattern: '',
    ndiFrequency: '',
    ndiLong: '',
    ndiLongInput: '',
    ndiNoneMediChecked1: '',
    ndiNoneMediChecked2: '',
    ndiNoneMediChecked3: '',
    ndiNoneMediChecked4: '',
    ndiNoneMediInput: '',
    ndiMediChecked: '',
    ndiMediInput1: '',
    ndiMediInput2: '',
    ndiMediInput3: ''
  },
  startback: {
    sb01: '',
    sb02: '',
    sb03: '',
    sb04: '',
    sb05: '',
    sb06: '',
    sb07: '',
    sb08: '',
    sb09: '',

    startbackScore: '',
    startbackWay: '',
    startbackPattern: '',
    startbackFrequency: '',
    startbackLong: '',
    startbackLongInput: '',
    startbackNoneMediChecked1: '',
    startbackNoneMediChecked2: '',
    startbackNoneMediChecked3: '',
    startbackNoneMediChecked4: '',
    startbackNoneMediInput: '',
    startbackMediChecked: '',
    startbackMediInput1: '',
    startbackMediInput2: '',
    startbackMediInput3: ''
  }
};
