import * as S from '../../../styles/ObservationStyles/ModalStyles';
import X from '../../../assets/img/x-icon.png';
import { useCallback, useEffect, useState } from 'react';
import IVcath from './IVcath';
import TunnelCath from './TunnelCath';
import Ttube from './Ttube';
import Ltube from './Ltube';
import ChestTube from './ChestTube';
import JP from './JP';

import { MobileTimePicker } from '@mui/x-date-pickers';
import { AccessTime } from '@mui/icons-material';
import MuiTextField from 'form/MuiTextField';
import { initialClinicalObs } from './initialStates';
import { useForm } from 'react-hook-form';
import usePatient from 'store/patient/usePatient';
import useNotification from 'hook/useNotification';
import { createObservation } from 'apis/observation/observationApi';
import { RECORD_TYPE_CLINICAL } from 'apis/observation/type';
import { findKeyValue } from 'apis/convert';
import { format } from 'path';
import useUser from 'store/user/useUser';

interface OwnProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Options({ showModal, setShowModal }: OwnProps) {
  const curDate = new Date();
  const curDateStr = curDate.toISOString().split('T')[0];
  const curDateTime = curDate;

  const [recordType, setRecordType] = useState<string>(RECORD_TYPE_CLINICAL.IV);
  const [recordDate, setRecordDate] = useState<string | null>(curDateStr);
  const [recordTime, setRecordTime] = useState<Date | null>(curDateTime);

  const { student_uuid: user_id } = useUser();
  const { onSuccess, onFail, onResultCode } = useNotification();
  const { patient, onClearClinicalObs, xrClinicalObservation } = usePatient();
  const { register, watch, setValue, getValues, handleSubmit, reset } =
    useForm();

  const handleCategoryClick = (num: string) => {
    setRecordType(num);
  };

  const onRestNursingRecord = useCallback(() => {
    setRecordTime(new Date());
    setRecordType(RECORD_TYPE_CLINICAL.IV);
    reset(initialClinicalObs.ivCath);
    onClearClinicalObs();
  }, [reset, onClearClinicalObs]);

  useEffect(() => {
    if (!xrClinicalObservation) return onRestNursingRecord();

    const { check_time, option_type, content } = xrClinicalObservation;
    reset(JSON.parse(content));
    setRecordType(`${option_type}`);
    setRecordTime(new Date(check_time));
  }, [xrClinicalObservation, reset, onClearClinicalObs]);

  const onSubmit = (data: any) => {
    let contentKeys: string[] = [];

    // update content
    switch (recordType) {
      case RECORD_TYPE_CLINICAL.IV: {
        contentKeys = Object.keys(initialClinicalObs.ivCath);
        break;
      }
      case RECORD_TYPE_CLINICAL.TUNNEL: {
        contentKeys = Object.keys(initialClinicalObs.tunnel);
        break;
      }
      case RECORD_TYPE_CLINICAL.T_TUBE: {
        contentKeys = Object.keys(initialClinicalObs.tTube);
        break;
      }
      case RECORD_TYPE_CLINICAL.L_TUBE: {
        contentKeys = Object.keys(initialClinicalObs.lTube);
        break;
      }
      case RECORD_TYPE_CLINICAL.CHEST_TUBE: {
        contentKeys = Object.keys(initialClinicalObs.chestTube);
        break;
      }
      case RECORD_TYPE_CLINICAL.JP_BAG: {
        contentKeys = Object.keys(initialClinicalObs.jpBagHemovac);
        break;
      }
    }

    const content = findKeyValue(data, contentKeys);

    const checkTime =
      recordDate && recordTime
        ? `${recordDate} ${recordTime.getHours()}:${recordTime.getMinutes()}`
        : null;

    const request = {
      option_type: Number(recordType),
      check_time: checkTime,
      content: JSON.stringify(content)
    };

    createObservation({
      user_id,
      patient_id: patient!.patient_id,
      ...request
    })
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);
        onClearClinicalObs();
        location.reload();
        onSuccess('저장성공.');
        setShowModal(false);
      })
      .catch(e => onFail('저장실패.', e));
  };

  const formProps = {
    register,
    watch,
    getValues,
    setValue
  };

  return (
    <S.ModalContainer $showModal={showModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.ModalHead>
          <S.HeadText>
            <img
              src={X}
              alt="cancel"
              onClick={() => {
                setShowModal(false);
              }}
            />
            <span>Option 추가</span>
          </S.HeadText>
          <S.ModalSave>
            <span>최근 저장한 시간 : 2022-10-12 13:30 PM</span>
            <S.SaveBtn type="submit">저장</S.SaveBtn>
          </S.ModalSave>
        </S.ModalHead>
        <S.ModalContentContainer>
          <S.DateTimeWrap>
            <MuiTextField
              type="date"
              fullWidth
              required={false}
              sx={{ width: '120px' }}
              value={recordDate}
              onChange={e => setRecordDate(e.target.value)}
            />
            <MobileTimePicker
              value={recordTime}
              onChange={setRecordTime}
              renderInput={params => (
                <MuiTextField
                  {...params}
                  fullWidth
                  InputProps={{ endAdornment: <AccessTime /> }}
                  size="small"
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: '12px'
                    },
                    width: '120px'
                  }}
                />
              )}
            />
          </S.DateTimeWrap>
          <S.BtnWrap>
            <S.CategoryBtn
              type="button"
              selected={recordType === RECORD_TYPE_CLINICAL.IV}
              onClick={() => handleCategoryClick(RECORD_TYPE_CLINICAL.IV)}
            >
              IV cath.
            </S.CategoryBtn>
            <S.CategoryBtn
              type="button"
              selected={recordType === RECORD_TYPE_CLINICAL.TUNNEL}
              onClick={() => handleCategoryClick(RECORD_TYPE_CLINICAL.TUNNEL)}
            >
              Tunnel cath.
            </S.CategoryBtn>
            <S.CategoryBtn
              type="button"
              selected={recordType === RECORD_TYPE_CLINICAL.T_TUBE}
              onClick={() => handleCategoryClick(RECORD_TYPE_CLINICAL.T_TUBE)}
            >
              T-tube
            </S.CategoryBtn>
            <S.CategoryBtn
              type="button"
              selected={recordType === RECORD_TYPE_CLINICAL.L_TUBE}
              onClick={() => handleCategoryClick(RECORD_TYPE_CLINICAL.L_TUBE)}
            >
              L-tube
            </S.CategoryBtn>
            <S.CategoryBtn
              type="button"
              selected={recordType === RECORD_TYPE_CLINICAL.CHEST_TUBE}
              onClick={() =>
                handleCategoryClick(RECORD_TYPE_CLINICAL.CHEST_TUBE)
              }
            >
              Chest Tube
            </S.CategoryBtn>
            <S.CategoryBtn
              type="button"
              selected={recordType === RECORD_TYPE_CLINICAL.JP_BAG}
              onClick={() => handleCategoryClick(RECORD_TYPE_CLINICAL.JP_BAG)}
            >
              JP-bag/Hemovac
            </S.CategoryBtn>
          </S.BtnWrap>
          {recordType === RECORD_TYPE_CLINICAL.IV && <IVcath {...formProps} />}
          {recordType === RECORD_TYPE_CLINICAL.TUNNEL && (
            <TunnelCath {...formProps} />
          )}
          {recordType === RECORD_TYPE_CLINICAL.T_TUBE && (
            <Ttube {...formProps} />
          )}
          {recordType === RECORD_TYPE_CLINICAL.L_TUBE && (
            <Ltube {...formProps} />
          )}
          {recordType === RECORD_TYPE_CLINICAL.CHEST_TUBE && (
            <ChestTube {...formProps} />
          )}
          {recordType === RECORD_TYPE_CLINICAL.JP_BAG && <JP {...formProps} />}
        </S.ModalContentContainer>
      </form>
    </S.ModalContainer>
  );
}
