import * as S from '../../styles/TestStyles/TestStyles';
import TestDate from './TestDate';
import TestContents from './TestContents';
import { Skeleton } from '@mui/material';
import usePatient from 'store/patient/usePatient';

export default function TestResult() {
  const { patientInfo } = usePatient();

  if (!patientInfo) {
    return <Skeleton variant="rectangular" sx={{ flex: 1 }} />;
  }

  const { patient_id, name, age, gender, department, room } = patientInfo;

  return (
    <S.Layout>
      <S.Container>
        <span className="patient">
          {name} / {gender === 1 ? '여자' : '남자'} /{age} ({patient_id})
          {department} / {room}
        </span>
        <S.ContainerWrap>
          <S.DateContainer>
            <TestDate />
          </S.DateContainer>

          <S.ContentContainer>
            <TestContents />
          </S.ContentContainer>
        </S.ContainerWrap>
      </S.Container>
    </S.Layout>
  );
}
