import * as S from '../../styles/SafetyStyles/ModalStyles';
import X from '../../assets/img/x-icon.png';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { initialSafety } from './initialState';
import usePatient from 'store/patient/usePatient';
import useNotification from 'hook/useNotification';
import MuiTextField from 'form/MuiTextField';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { AccessTime } from '@mui/icons-material';
import MuiRadioGroup from 'form/MuiRadioGroup';

interface OwnProps {
  showAddRestraint: boolean;
  setShowAddRestraint: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AddRestraintBand({
  showAddRestraint,
  setShowAddRestraint
}: OwnProps) {
  const curDate = new Date();
  const curDateStr = curDate.toISOString().split('T')[0];
  const curDateTime = curDate;
  const [recordDate, setRecordDate] = useState<string | null>(curDateStr);
  const [recordTime, setRecordTime] = useState<Date | null>(curDateTime);
  const { register, watch, setValue, getValues, handleSubmit } = useForm({
    defaultValues: initialSafety
  });

  return (
    <S.ModalContainer $showModal={showAddRestraint}>
      {/*   <form onSubmit={handleSubmit(onSubmit)}> */}
      <S.ModalHead>
        <S.HeadText>
          <img
            src={X}
            alt="cancel"
            onClick={() => {
              setShowAddRestraint(false);
            }}
          />
          <span>억제대 적용 추가</span>
        </S.HeadText>
        <S.ModalSave>
          <span>최근 저장한 시간 : 2022-10-12 13:30 PM</span>
          <S.SaveBtn type="submit">저장</S.SaveBtn>
        </S.ModalSave>
      </S.ModalHead>
      <S.ModalContentContainer>
        <S.DateTimeWrap>
          <MuiTextField
            type="date"
            fullWidth
            required={false}
            sx={{ width: '120px' }}
            value={recordDate}
            onChange={e => setRecordDate(e.target.value)}
          />
          <MobileTimePicker
            value={recordTime}
            onChange={setRecordTime}
            renderInput={params => (
              <MuiTextField
                {...params}
                fullWidth
                InputProps={{ endAdornment: <AccessTime /> }}
                size="small"
                sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '12px'
                  },
                  width: '120px'
                }}
              />
            )}
          />
        </S.DateTimeWrap>
        <span className="modal-title">억제대 적용</span>
        <S.IndexContent>
          <S.RestraintItemWrap>
            <S.ItemsHead>동의서</S.ItemsHead>
            <MuiRadioGroup
              i18nKey="EXIST.SHORT"
              values={[1, 2]}
              defaultValue={getValues('restraint_band.agreement.value')}
              onChange={v => setValue('restraint_band.agreement.value', v)}
              width="180px"
            />
          </S.RestraintItemWrap>
          <S.RestraintItemWrap>
            <S.ItemsHead>억제대 적용부위</S.ItemsHead>
            <S.StatusWrap>
              <S.Status>
                <span className="status">순환상태(2hr)</span>
                <MuiRadioGroup
                  i18nKey="EXIST.SHORT"
                  values={[1, 2]}
                  defaultValue={getValues(
                    'restraint_band.restraint_band_part1.value'
                  )}
                  onChange={v =>
                    setValue('restraint_band.restraint_band_part1.value', v)
                  }
                  width="180px"
                />
              </S.Status>
              <S.Status>
                <span className="status">피부상태(2hr)</span>
                <MuiRadioGroup
                  i18nKey="EXIST.SHORT"
                  values={[1, 2]}
                  defaultValue={getValues(
                    'restraint_band.restraint_band_part2.value'
                  )}
                  onChange={v =>
                    setValue('restraint_band.restraint_band_part2.value', v)
                  }
                  width="180px"
                />
              </S.Status>
            </S.StatusWrap>
          </S.RestraintItemWrap>
          <S.RestraintItemWrap>
            <S.ItemsHead>적용 사유</S.ItemsHead>
            <MuiRadioGroup
              i18nKey="SAFETY.RESTRAINT.REASON"
              values={[1, 2]}
              defaultValue={getValues('restraint_band.agreement.value')}
              onChange={v => setValue('restraint_band.agreement.value', v)}
              width="180px"
            />
          </S.RestraintItemWrap>
          <S.RestraintItemWrap>
            <S.ItemsHead>적용부위</S.ItemsHead>
            <MuiTextField
              required={false}
              sx={{
                minWidth: '130px',
                width: '50%'
              }}
              placeholder="직접 입력"
              {...register('restraint_band.application_part')}
            />
          </S.RestraintItemWrap>
          <S.RestraintItemWrap>
            <S.ItemsHead>제거사유</S.ItemsHead>
            <MuiTextField
              required={false}
              sx={{
                minWidth: '130px',
                width: '50%'
              }}
              placeholder="직접 입력"
              {...register('restraint_band.remove_reason')}
            />
          </S.RestraintItemWrap>
          <S.RestraintItemWrap>
            <S.ItemsHead>적용 사유</S.ItemsHead>
            <MuiRadioGroup
              i18nKey="SAFETY.INTERVENTION"
              values={[1, 2, 3]}
              defaultValue={getValues('restraint_band.intervention.value')}
              onChange={v => setValue('restraint_band.intervention.value', v)}
              width="180px"
            />
          </S.RestraintItemWrap>
        </S.IndexContent>
      </S.ModalContentContainer>
    </S.ModalContainer>
  );
}
