import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReducerType } from '../reducer';
import { initSign, updateSign, IOState } from '.';

const useIO = () => {
  const dispatch = useDispatch();

  const intakteoutput = useSelector<ReducerType, IOState>(
    state => state.intakeoutput
  );

  const onInitSign = useCallback(() => dispatch(initSign()), [dispatch]);

  const onUpdateSign = useCallback(
    (value: IOState) => dispatch(updateSign(value)),
    [dispatch]
  );

  return {
    intakteoutput,
    onInitSign,
    onUpdateSign
  };
};

export default useIO;
