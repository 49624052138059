import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { IPatient, IPatientInfo } from 'apis/info/type';

import {
  resetPatient,
  selectedPatient,
  selectedPatientInfo,
  selectedNursingRecord,
  clearNursingRecord,
  selectedClinicalObs,
  clearClinicalObs
} from '.';
import { IUpdateNursingRecord } from 'apis/records/type';
import useSelectorTyped from 'store/useSelectorTyped';
import { IUpdateClinicalObs } from 'apis/observation/type';

const usePatient = () => {
  const dispatch = useDispatch();

  const { patient, patientInfo, nursingRecord, xrClinicalObservation } =
    useSelectorTyped(state => state.patient);

  const onResetPatient = useCallback(
    () => dispatch(resetPatient()),
    [dispatch]
  );

  const onSelectedPatient = useCallback(
    (patient: IPatient | null) => dispatch(selectedPatient(patient)),
    [dispatch]
  );

  const onSelectedPatientInfo = useCallback(
    (patientInfo: IPatientInfo | null) =>
      dispatch(selectedPatientInfo(patientInfo)),
    [dispatch]
  );

  const onSelectedNursingRecord = useCallback(
    (value: IUpdateNursingRecord) => dispatch(selectedNursingRecord(value)),
    [dispatch]
  );

  const onClearNursingRecord = useCallback(
    () => dispatch(clearNursingRecord()),
    [dispatch]
  );

  const onSelectClinicalObs = useCallback(
    (value: IUpdateClinicalObs) => dispatch(selectedClinicalObs(value)),
    [dispatch]
  );

  const onClearClinicalObs = useCallback(
    () => dispatch(clearClinicalObs()),
    [dispatch]
  );

  return {
    patient,
    onResetPatient,
    onSelectedPatient,
    patientInfo,
    onSelectedPatientInfo,
    nursingRecord,
    onSelectedNursingRecord,
    onClearNursingRecord,
    xrClinicalObservation,
    onSelectClinicalObs,
    onClearClinicalObs
  };
};

export default usePatient;
