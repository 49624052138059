import * as S from '../../styles/NursingStyles/InfoNursingStyles';
import { IFormRegister, IFormValues } from '../type';
import MuiTextField from 'form/MuiTextField';
import RowContainer from 'form/RowContainer';
import RowContent from 'form/RowContent';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

export default function InfoOP1(props: Props) {
  const { disabled, register, getValues, setValue } = props;

  const contents = [
    {
      label: '환자구분',
      element: (
        <MuiTextField
          {...register('operation_information.operating_department')}
          required={false}
          disabled={disabled}
        />
      )
    },
    {
      label: '환자이름',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '환자번호',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '병실',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '수술장소',
      element: (
        <MuiTextField
          {...register('operation_information.main_operation_name')}
          required={false}
          disabled={disabled}
        />
      )
    },
    {
      label: '수술일자',
      element: (
        <MuiTextField
          type="date"
          {...register('operation_information.minor_operation_name')}
          required={false}
          disabled={disabled}
        />
      )
    },
    {
      label: '마취종류',
      element: (
        <MuiTextField
          {...register('operation_information.past_history')}
          required={false}
          disabled={disabled}
        />
      )
    },
    {
      label: '진단명',
      element: (
        <MuiTextField
          {...register('operation_information.allergy')}
          required={false}
          disabled={disabled}
        />
      )
    }
  ];

  return (
    <S.Layout1>
      <S.Container>
        <span className="title">수술 기본 정보</span>
        <RowContainer xs={12}>
          {contents.map(({ label, element }) => (
            <>
              <RowContent title={label} titleRatio={1} childrenRatio={2}>
                {element}
              </RowContent>
            </>
          ))}
        </RowContainer>
      </S.Container>
    </S.Layout1>
  );
}
