const locale = {
  ko: {
    ETC: '기타',

    'EXIST.SHORT.1': '무',
    'EXIST.SHORT.2': '유',
    'YESORNO.1': '예',
    'YESORNO.2': '아니오',
    'YESORNOT.1': '예',
    'YESORNOT.2': '해당없음',
    'YESORNOORNOT.1': '예',
    'YESORNOORNOT.2': '아니오',
    'YESORNOORNOT.3': '해당없음',

    // Clinical Observation Options
    'EXIST.EXUDATION.1': '무',
    'EXIST.EXUDATION.2': '유 - 양상:',
    'EXIST.TUNNELCATH.1': 'Hickman cath',
    'EXIST.TUNNELCATH.2': 'Cook cath',
    'EXIST.TUNNELCATH.3': 'Groshong cath',
    'EXIST.TUNNELCATH.4': 'Jugular cath',
    'EXIST.TUNNELCATH.5': 'Subclavian cath',
    'EXIST.TUNNELCATH.6': 'Femoral cath',
    'EXIST.DRESSING.1': 'Intact',
    'EXIST.DRESSING.2': 'Cloud',
    'EXIST.DRAINAGE.1': 'Clear',
    'EXIST.DRAINAGE.2': '막힘',
    'EXIST.COLOR.1': 'Bloody',
    'EXIST.COLOR.2': 'Serous',
    'EXIST.ASPECT.1': 'Clear',
    'EXIST.ASPECT.2': 'Cloud',
    // error message
    'ERROR.CODE.0': '알 수 없는 오류가 발생했습니다. ErrorCode: {rc}',
    'ERROR.CODE.100':
      '잘못된 아이디이거나 존재하지 않는 아이디입니다.\n스마트널스 홈페이지에서만 가입한 경우, ENR에서의 회원가입이 별도로 필요합니다.',
    'ERROR.CODE.101': '이메일 인증 코드가 일치하지 않습니다.',
    'ERROR.CODE.102': '가상환자의 ID가 올바르지 않습니다.',
    'ERROR.CODE.104': '기록지 ID가 올바르지 않습니다.',
    'ERROR.CODE.105': '간호기록지 정보를 찾을 수 없습니다.',
    'ERROR.CODE.106': '승인되지 않은 유저입니다.\n 관리자에게 문의해주세요',
    'ERROR.CODE.107': '비밀번호가 일치하지 않습니다.',
    'ERROR.CODE.108':
      '이미 가입된 Email 주소 입니다. 다른 Email을 입력해주세요.',
    'ERROR.CODE.109':
      '이미 존재하는 가상환자 ID 입니다. 다른 ID를 입력해주세요.',
    'ERROR.CODE.110': '학교 정보를 찾지 못했습니다. 관리자에게 문의해주세요.',
    'ERROR.CODE.111': '권한이 없습니다. 관리자에게 문의해주세요.',
    'ERROR.CODE.201': '수가코드 정보를 찾을 수 없습니다.',
    'ERROR.CODE.202': '약품코드 정보를 찾을 수 없습니다.',
    'ERROR.CODE.702': '유효한 파일이 아닙니다. 파일을 다시 한번 확인해주세요',

    // nursing record
    'RECORD.0': 'NANDA',
    'RECORD.1': 'DAR',
    'RECORD.2': '서술기록',

    'RECORD.TYPE.0': 'NANDA',
    'RECORD.TYPE.1': 'DAR',
    'RECORD.TYPE.2': 'NARRATIVE',

    'NANDA.DOMAIN': '영역 Domain',
    'NANDA.CLASS': '분류 Class',
    'NANDA.DIAGNOSIS': '진단명 Diagnosis',
    'NANDA.COLLECTINGDATA': '자료 수집 주관적 / 객관적',
    'NANDA.GOAL': '간호목표 단기/장기 Goal',
    'NANDA.PLAN': '간호계획 Plan',
    'NANDA.INTERVENTIONS': '간호수행/중재/이론적 근거 Interventions',
    'NANDA.EVALUATION': '간호평가 Evaluation',

    'DAR.SUBJECTIVE': '데이터 Data',
    'DAR.OBJECTIVE': '활동 Action',
    'DAR.ASSESSMENT': '반응 Response',

    'NARRATIVE.NARRATIVENOTE': '서술 기록 Narrative Notes',
    'REMARKS.ASSESSMENT': '간호사정 (Assessment)',
    'REMARKS.DIAGNOSIS': '간호진단 (Diagnosis)',
    'REMARKS.DIAGNOSISRELATE': '와/과 관련된',
    'REMARKS.GOAL': '간호목표 (Goal)',
    'REMARKS.PLAN': '간호계획 (Plan)',
    'REMARKS.INTERVENTIONS': '간호수행/중재/이론적 근거 (Interventions)',
    'REMARKS.EVALUATION': '간호평가 (Evaluation)',
    'REQUIRED.DOMAIN': '영역을 선택해주세요',
    'REQUIRED.CLASS': '분류를 선택해주세요',
    'REQUIRED.DIAGNOSIS': '진단명을 선택해주세요',
    'REQUIRED.RECORD.TIME': '간호기록 시간을 입력해주세요',

    // hospitalization
    'HOSPITALIZATION.PATH.1': '외래',
    'HOSPITALIZATION.PATH.2': '응급실',
    'HOSPITALIZATION.WAY.1': '도보',
    'HOSPITALIZATION.WAY.2': '휠체어',
    'HOSPITALIZATION.WAY.3': '이동침대',

    'HOSPITALIZATION.FAMILLY.1': '부',
    'HOSPITALIZATION.FAMILLY.2': '모',
    'HOSPITALIZATION.FAMILLY.3': '조부',
    'HOSPITALIZATION.FAMILLY.4': '조모',
    'HOSPITALIZATION.FAMILLY.5': '배우자',

    'HOSPITALIZATION.DISEASE.1': '고혈압',
    'HOSPITALIZATION.DISEASE.2': '당뇨',
    'HOSPITALIZATION.DISEASE.3': '결핵',
    'HOSPITALIZATION.DISEASE.4': '천식',
    'HOSPITALIZATION.DISEASE.5': '아토피',
    'HOSPITALIZATION.DISEASE.6': '알레르기성 비염',

    // patient safety - mfs
    'SAFETY.HISTORY.1': '없음 (0점)',
    'SAFETY.HISTORY.2': '있음 (25점)',
    'SAFETY.SECONDARY.DIAGNOSIS.1': '없음 (0점)',
    'SAFETY.SECONDARY.DIAGNOSIS.2': '있음 (15점)',
    'SAFETY.AMBULATORY.AID.1': '없음/침상안정/간호보조 (0점)',
    'SAFETY.AMBULATORY.AID.2': '목발/지팡이/보행기 (15점)',
    'SAFETY.AMBULATORY.AID.3': '기구를 잡고 이동 (30점)',
    'SAFETY.IV.1': '없음 (0점)',
    'SAFETY.IV.2': '있음 (20점)',
    'SAFETY.GAIT.1': '정상/침상안정/부동 (0점)',
    'SAFETY.GAIT.2': '허약 (10점)',
    'SAFETY.GAIT.3': '장애 (20점)',
    'SAFETY.MENTAL.STATUS.1': '자신의 기능수준을 인지함 (0점)',
    'SAFETY.MENTAL.STATUS.2': '자신의 기능수준을 인지하지 못함 (15점)',
    // patient safety - kid mfs
    'SAFETY.AGE.1': '3개월 미만, 13세 이상',
    'SAFETY.AGE.2': '7세 이상~13세 미만',
    'SAFETY.AGE.3': '3세 이상~7세 미만',
    'SAFETY.AGE.4': '3개월 이상~3세 미만',
    'SAFETY.GENDER.1': '여아',
    'SAFETY.GENDER.2': '남아',
    'SAFETY.DIAGNOSIS.1': '그 밖의 다른 진단',
    'SAFETY.DIAGNOSIS.2': '정신/행동 장애 관련 진단',
    'SAFETY.DIAGNOSIS.3':
      '산소량 변화 진단\n(호흡기적 진단, 탈수, 빈혈,\n식욕감퇴, 실신, 어지러움증 등)',
    'SAFETY.DIAGNOSIS.4': '신경학적 진단',
    'SAFETY.COGNITIVE.IMPAIRMENT.1': '자기 능력을 알고 있음',
    'SAFETY.COGNITIVE.IMPAIRMENT.2': '능력의 한계를 알고 있지만\n시행하지않음',
    'SAFETY.COGNITIVE.IMPAIRMENT.3':
      '4세 이상이나 능력의 한계를 알지못함\n또는 4세 미만 환자',
    'SAFETY.ENVIRONMENTAL.1': '해당없음',
    'SAFETY.ENVIRONMENTAL.2': '보조기(휠체어, 워커, 목발) 사용함',
    'SAFETY.ENVIRONMENTAL.3': '낙상 경험 있음',
    'SAFETY.SURGICAL.1': '시행 후 48시간 이상/ 해당 없음',
    'SAFETY.SURGICAL.2': '시행 후 48시간 이내',
    'SAFETY.SURGICAL.3': '시행 후 24시간 이내',
    'SAFETY.DRUG.USE.1': '다른 약물/ 해당 없음',
    'SAFETY.DRUG.USE.2': '위 약물중 하나',
    'SAFETY.DRUG.USE.3':
      '2가지 이상\n(진정제, 수면제, 최면제, 정신안정제\n항불안제, 완화제, 이뇨제, 진통제)',
    // patient safety - branden scale
    'SAFETY.SENSE.1': '완전 제한',
    'SAFETY.SENSE.2': '매우 제한',
    'SAFETY.SENSE.3': '약간 제한',
    'SAFETY.SENSE.4': '제한 없음',
    'SAFETY.HUMIDITY.1': '항상 촉촉함',
    'SAFETY.HUMIDITY.2': '촉촉함',
    'SAFETY.HUMIDITY.3': '가끔 촉촉함',
    'SAFETY.HUMIDITY.4': '거의 촉촉하지 않음',
    'SAFETY.ACTIVITY.1': '침대에만 있음',
    'SAFETY.ACTIVITY.2': '주로 앉아 있음',
    'SAFETY.ACTIVITY.3': '가끔 보행함',
    'SAFETY.ACTIVITY.4': '자주 보행함',
    'SAFETY.MOBILITY.1': '완전 부동',
    'SAFETY.MOBILITY.2': '매우 제한',
    'SAFETY.MOBILITY.3': '약간 제한',
    'SAFETY.MOBILITY.4': '제한 없음',
    'SAFETY.NUTRITION.1': '매우 불량',
    'SAFETY.NUTRITION.2': '불량함',
    'SAFETY.NUTRITION.3': '적절함',
    'SAFETY.NUTRITION.4': '우수함',
    'SAFETY.FRICTION.SHEARING.1': '문제가 있음',
    'SAFETY.FRICTION.SHEARING.2': '잠재적 문제',
    'SAFETY.FRICTION.SHEARING.3': '문제 없음',
    'SAFETY.SKIN.DAMAGE.1': 'Stage 1',
    'SAFETY.SKIN.DAMAGE.2': 'Stage 2',
    'SAFETY.SKIN.DAMAGE.3': 'Stage 3',
    'SAFETY.SKIN.DAMAGE.4': 'Stage 4',
    'SAFETY.SKIN.DAMAGE.5': '측정불가',
    'SAFETY.SKIN.DAMAGE.6': '기타',
    // restraint
    'SAFETY.RESTRAINT.REASON.1': '자/타해 위험',
    'SAFETY.RESTRAINT.REASON.2': '치료적 목적',
    'SAFETY.INTERVENTION.1': '적용부위 예방적 드레싱',
    'SAFETY.INTERVENTION.2': '2시간마다 제거 후 재사용',
    'SAFETY.INTERVENTION.3': '끈이 풀어지지 않게 묶임',

    'PRE.OPERATION.1': 'Clipper',
    'PRE.OPERATION.2': '제모제',
    'PRE.OPERATION.3': '면도',
    'PRE.OPERATION.4': '자가',

    'PRE.OPERATION.TOOTH.1': '정상',
    'PRE.OPERATION.TOOTH.2': '흔들리는치아',
    'PRE.OPERATION.TOOTH.3': '틀니(의치)제거',
    'PRE.OPERATION.TOOTH.4': '영구틀니',
    'PRE.OPERATION.TOOTH.5': '임플란트',

    'PRE.OPERATION.ANTI.1': '예',
    'PRE.OPERATION.ANTI.2': '아니오 (정규항생제 투약, 기타)',
    'PRE.OPERATION.ANTI.3': '해당없음',

    'PRE.OPERATION.SKINTEST.1': '+',
    'PRE.OPERATION.SKINTEST.2': '-',

    'POST.OPERATION.CHECK.1': '도착시점',
    'POST.OPERATION.CHECK.2': '정확한 수술부위 표시',

    'POST.OPERATION.ANTI.1': '예',
    'POST.OPERATION.ANTI.2': '아니오',
    'POST.OPERATION.ANTI.3': '투약중임',

    'RECORDOBS.1': 'IV cath',
    'RECORDOBS.2': 'Tunnel cath',
    'RECORDOBS.3': 'T-Tube',
    'RECORDOBS.4': 'L-tube',
    'RECORDOBS.5': 'Chest Tube',
    'RECORDOBS.6': 'JP-bag/Hemovac',

    'RECORDOBS.TYPE.1': 'IV cath',
    'RECORDOBS.TYPE.2': 'Tunnel cath',
    'RECORDOBS.TYPE.3': 'T-Tube',
    'RECORDOBS.TYPE.4': 'L-tube',
    'RECORDOBS.TYPE.5': 'Chest Tube',
    'RECORDOBS.TYPE.6': 'JP-bag/Hemovac',

    // 임상관찰 통증사정
    'RECORDOBS.TYPE.PAIN.1': 'NRS',
    'RECORDOBS.TYPE.PAIN.2': 'FLACC',
    'RECORDOBS.TYPE.PAIN.3': 'CNPS',
    'RECORDOBS.TYPE.PAIN.4': 'FFI',
    'RECORDOBS.TYPE.PAIN.5': 'KOOS',
    'RECORDOBS.TYPE.PAIN.6': 'LEFS',
    'RECORDOBS.TYPE.PAIN.7': 'NDI',
    'RECORDOBS.TYPE.PAIN.8': 'STarT Back Screening',

    'CLINICAL.PAIN.PATTERN.1': '간헐적',
    'CLINICAL.PAIN.PATTERN.2': '지속적',

    'CLINICAL.PAIN.FREQUENCY.1': '10분이내',
    'CLINICAL.PAIN.FREQUENCY.2': '11~30분',
    'CLINICAL.PAIN.FREQUENCY.3': '1시간 이상',

    'CLINICAL.PAIN.LONG.1': '무',
    'CLINICAL.PAIN.LONG.2': '유 - 양상 : ',

    'CLINICAL.PAIN.NONEMEDI.1': '심호흡 방법 교육',
    'CLINICAL.PAIN.NONEMEDI.2': '냉찜질 시행',
    'CLINICAL.PAIN.NONEMEDI.3': '휴식',

    'CLINICAL.PAIN.MEDI.1': '약물명',

    // KOOS2
    'KOOS.SYMPTOMS.1': '전혀 없었음',
    'KOOS.SYMPTOMS.2': '거의 없었음',
    'KOOS.SYMPTOMS.3': '가끔 있었음',
    'KOOS.SYMPTOMS.4': '자주 있었음',
    'KOOS.SYMPTOMS.5': '항상 있었음',

    'KOOS.STIFFNESS.1': '전혀 없었음',
    'KOOS.STIFFNESS.2': '약간의 경직',
    'KOOS.STIFFNESS.3': '보통의 경직',
    'KOOS.STIFFNESS.4': '심한 경직',
    'KOOS.STIFFNESS.5': '극도의 경직',

    'KOOS.PAIN.1': '전혀 없었음',
    'KOOS.PAIN.2': '매달',
    'KOOS.PAIN.3': '매주',
    'KOOS.PAIN.4': '매일',
    'KOOS.PAIN.5': '항상',

    'KOOS.PAIN.TOW.1': '전혀 없음',
    'KOOS.PAIN.TOW.2': '약간의 통증',
    'KOOS.PAIN.TOW.3': '보통의 통증',
    'KOOS.PAIN.TOW.4': '심한 통증',
    'KOOS.PAIN.TOW.5': '극도의 통증',

    'KOOS.DAILYFUNCTION.1': '전혀 어려움 없음',
    'KOOS.DAILYFUNCTION.2': '약간의 어려움',
    'KOOS.DAILYFUNCTION.3': '보통의 어려움',
    'KOOS.DAILYFUNCTION.4': '심한 어려움',
    'KOOS.DAILYFUNCTION.5': '극심하게 어려움',

    'KOOS.SPORTS.1': '전혀 어려움 없음',
    'KOOS.SPORTS.2': '약간의 어려움',
    'KOOS.SPORTS.3': '보통의 어려움',
    'KOOS.SPORTS.4': '심한 어려움',
    'KOOS.SPORTS.5': '극심하게 어려움',

    'KOOS.LIFEQUALITY.1': '문제 없음',
    'KOOS.LIFEQUALITY.2': '매달',
    'KOOS.LIFEQUALITY.3': '매주',
    'KOOS.LIFEQUALITY.4': '매일',
    'KOOS.LIFEQUALITY.5': '항상',

    'KOOS.LIFEQUALITY.TWO.1': '개선하지 않았음',
    'KOOS.LIFEQUALITY.TWO.2': '약간 개선하였음',
    'KOOS.LIFEQUALITY.TWO.3': '조금 개선하였음',
    'KOOS.LIFEQUALITY.TWO.4': '많이 개선하였음',
    'KOOS.LIFEQUALITY.TWO.5': '모두 개선하였음',

    'KOOS.LIFEQUALITY.THREE.1': '전혀 어려움이 없음',
    'KOOS.LIFEQUALITY.THREE.2': '약간 어려움이 있었음',
    'KOOS.LIFEQUALITY.THREE.3': '보통의 어려움 있었음',
    'KOOS.LIFEQUALITY.THREE.4': '심한 어려움 있었음',
    'KOOS.LIFEQUALITY.THREE.5': '극도의 어려움 있었음',

    // STarTScreening
    'STARTSCREENING.SCORE.0': 'No(0point)',
    'STARTSCREENING.SCORE.1': 'Yes(1point)',

    'STARTSCREENING.SCORE.FIVE.0': 'Not at all (0 point)',
    'STARTSCREENING.SCORE.FIVE.1': 'Slightly (0 point)',
    'STARTSCREENING.SCORE.FIVE.2': 'Moderately (0 point)',
    'STARTSCREENING.SCORE.FIVE.3': 'Very much (1 point)',
    'STARTSCREENING.SCORE.FIVE.4': 'Extremely (1 point)'
  }
};
export default locale;
