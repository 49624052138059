import styled from 'styled-components';
import { TabPanel, TabList } from '@mui/lab';
import { Tab } from '@mui/material';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  gap: 15px;
  margin: 10px 10px 0 10px;
  flex-direction: column;
`;

export const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 1px;
`;

export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  margin-bottom: 10px;
`;

export const ContentsContainer = styled.div`
  height: 55vh;
  overflow: scroll;
`;

export const SaveButtonWrap = styled.div`
  display: flex;
  justify-content: right;
`;

export const SaveButton = styled.button`
  color: ${props => props.theme.color.brand_300};
  border: none;
  background-color: white;
  font-size: 13px;
  font-weight: 700;

  cursor: pointer;
  font-family: 'Spoqa', sans-serif;
`;

export const CancleButton = styled.button`
  color: ${props => props.theme.color.black_700};
  border: none;
  background-color: white;
  font-size: 13px;
  font-weight: 700;

  cursor: pointer;
  font-family: 'Spoqa', sans-serif;
`;

// mui styled
export const StyledTabPanel = styled(TabPanel)`
  flex: 1;
  padding: 10px 0;

  .MuiButtonGroup-root {
    color: #000000b2;
    margin-top: auto;
    padding-top: 16px;
    justify-content: flex-end;
  }
`;

export const StyledTab = styled(Tab)`
  font-family: 'Spoqa', sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  &:hover {
    background-color: ${props => props.theme.color.brand_200};
    color: white;
  }
  &:focus {
    color: ${props => props.theme.color.brand_300};
    background-color: ${props => props.theme.color.brand_100};
  }
  && {
    color: ${props => props.theme.color.brand_300};
    font-weight: 700;
    text-transform: none;
    font-family: 'Spoqa', sans-serif;
  }
  &.Mui-selected {
    color: ${props => props.theme.color.brand_300} !important;
    font-family: 'Spoqa', sans-serif !important;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: var(--darkGreen) !important;
  }
  .MuiTab-root {
    font-family: 'Spoqa', sans-serif;
  }
`;

export const StyleTabList = styled(TabList)`
  min-width: 400px;
  /* background-color: red; */
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  /* box-shadow: 0px 4px 30px green; */
`;
