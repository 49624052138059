import * as S from '../../../styles/ObservationStyles/VitalStyles';

import { Fragment, useState, useEffect, useRef } from 'react';
import { AccessTime, Delete } from '@mui/icons-material';
import { Button, FormHelperText, Grid, IconButton } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';

import { IVitalSign } from 'apis/vital/type';
import { IFormValues, IFormWatch } from '../../type';
import MuiTable from 'form/MuiTable';
import MuiTextField from 'form/MuiTextField';
import { formatStringToDate, formatTimeStrToNum } from 'apis/formatting';

import useVitalSign from 'store/vitalsign/useVitalsign';

// 아이콘
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

interface Props extends IFormValues, IFormWatch {}

const VitalSignRecord = (props: Props) => {
  const { vitalsign, onUpdateSign } = useVitalSign();
  const { watch, setValue } = props;
  const vitalSignList: IVitalSign[] = watch('vital_sign');

  const [date, setDate] = useState('');
  const [checkTime, setCheckTime] = useState(null);
  const [sbp, setSbp] = useState('');
  const [dbp, setDbp] = useState('');
  const [pr, setPr] = useState('');
  const [rr, setRr] = useState('');
  const [bt, setBt] = useState('');
  const [sp02, setSp02] = useState('');
  const [etc, setEtc] = useState('');
  const [errors, setErrors] = useState({
    sbp: 0,
    dbp: 0,
    pr: 0,
    rr: 0,
    bt: 0
  });

  const columns = [
    { fieldId: 'date', label: '날짜 및 시간', sx: { width: 130 } },
    { fieldId: 'checkTime', label: '', sx: { width: 130 } },
    { fieldId: 'sbp', label: 'SBP (mmHg)' },
    { fieldId: 'dbp', label: 'DBP (mmHg)' },
    { fieldId: 'pr', label: 'PR (회)' },
    { fieldId: 'rr', label: 'RR (회)' },
    { fieldId: 'bt', label: 'BT (℃)' },
    { fieldId: 'sp02', label: 'SPO2 (%)' },
    { fieldId: 'etc', label: '비고' },
    { fieldId: 'action', label: '', sx: { width: 100 } }
  ];

  const onAddRow = () => {
    const request = { date, checkTime, sbp, dbp, pr, rr, bt, sp02 };
    const requestValue = { date, checkTime, sbp, dbp, pr, rr, bt };

    if (Object.values(requestValue).filter(v => !v).length > 0) {
      return alert('입력해주세요');
    }
    // else if (vitalsign.data.length > 4) {
    //   return alert('4개이상안해요');
    // }

    alert('Vital Sign 추가되었습니다.');
    setValue('vital_sign', [...vitalSignList, { ...request, etc }]);
    // console.log('시간', checkTime);
    onUpdateSign({
      isUpdated: !vitalsign.isUpdated,
      data: [
        ...vitalsign.data,
        {
          date: date !== null ? formatStringToDate(date, 'yyyy-MM-dd') : '',
          checkTime:
            checkTime !== null ? formatStringToDate(checkTime, 'hh:mm a') : '',
          sbp: Number(sbp),
          dbp: Number(dbp),
          pr: Number(pr),
          rr: Number(rr),
          bt: Number(bt)
        }
      ].sort((a, b) => {
        const aTime = new Date('1970/01/01 ' + a.checkTime);
        const bTime = new Date('1970/01/01 ' + b.checkTime);

        return Number(aTime) - Number(bTime);
      })
    });

    setDate('');
    setCheckTime(null);
    setSbp('');
    setDbp('');
    setPr('');
    setRr('');
    setBt('');
    setSp02('');
    setEtc('');
  };

  const inputRow = {
    id: 'add-vital-sign',
    date: (
      <MuiTextField
        type="date"
        // disabled={disabled}
        fullWidth
        required={false}
        onChange={({ target: { value } }) => setDate(value)}
      />
    ),
    checkTime: (
      <MobileTimePicker
        value={checkTime}
        onChange={setCheckTime}
        renderInput={params => (
          <MuiTextField
            {...params}
            required={false}
            placeholder="00:00 pm"
            InputProps={{
              endAdornment: <AccessTime sx={{ height: '15px' }} />
            }}
            sx={{
              '& .MuiInputBase-input': {
                fontSize: '12px'
              }
            }}
          />
        )}
      />
    ),
    sbp: (
      <>
        <MuiTextField
          value={sbp}
          required={false}
          onChange={({ target: { value } }) => {
            setSbp(value);
          }}
        />
        {errors.sbp ? (
          <FormHelperText error={true}>SBP 값은 250 미만입니다</FormHelperText>
        ) : null}
      </>
    ),
    dbp: (
      <>
        <MuiTextField
          value={dbp}
          required={false}
          onChange={({ target: { value } }) => {
            setDbp(value);

            if (Number(value) >= 250) setErrors({ ...errors, dbp: 1 });
            else setErrors({ ...errors, dbp: 0 });
          }}
          error={Number(dbp) >= 250 ? true : false}
        />
        {errors.dbp ? (
          <FormHelperText error={true}>DBP 값은 250 미만입니다</FormHelperText>
        ) : null}
      </>
    ),
    pr: (
      <>
        <MuiTextField
          value={pr}
          required={false}
          onChange={({ target: { value } }) => {
            setPr(value);

            if (Number(value) >= 200) setErrors({ ...errors, pr: 1 });
            else setErrors({ ...errors, pr: 0 });
          }}
          error={Number(pr) >= 200 ? true : false}
        />
        {errors.pr ? (
          <FormHelperText error={true}>PR 값은 200 미만입니다</FormHelperText>
        ) : null}
      </>
    ),
    rr: (
      <>
        <MuiTextField
          value={rr}
          required={false}
          onChange={({ target: { value } }) => {
            setRr(value);

            if (Number(value) >= 200) setErrors({ ...errors, rr: 1 });
            else setErrors({ ...errors, rr: 0 });
          }}
          error={Number(rr) >= 200 ? true : false}
        />
        {errors.rr ? (
          <FormHelperText error={true}>RR 값은 200 미만입니다</FormHelperText>
        ) : null}
      </>
    ),
    bt: (
      <>
        <MuiTextField
          value={bt}
          required={false}
          onChange={({ target: { value } }) => {
            setBt(value);

            if (value === '' || (Number(value) > 30 && Number(value) < 43))
              setErrors({ ...errors, bt: 0 });
            else setErrors({ ...errors, bt: 1 });
          }}
          error={
            bt === '' || (Number(bt) > 30 && Number(bt) < 43) ? false : true
          }
        />
        {errors.bt ? (
          <FormHelperText error={true}>
            BT 값은 30 초과 43 미만입니다
          </FormHelperText>
        ) : null}
      </>
    ),
    sp02: (
      <MuiTextField
        value={sp02}
        required={false}
        onChange={({ target: { value } }) => setSp02(value)}
      />
    ),
    etc: (
      <MuiTextField
        value={etc}
        required={false}
        onChange={({ target: { value } }) => setEtc(value)}
      />
    ),
    action: (
      <Button
        variant="contained"
        size="small"
        onClick={onAddRow}
        sx={{
          backgroundColor: '#604180',
          ':hover': {
            color: 'white',
            backgroundColor: '#866EA0'
          }
        }}
      >
        추가
      </Button>
    )
  };

  const onDeleteRow = (index: number) => {
    setValue(
      'vital_sign',
      vitalSignList.filter((_, i) => i !== index)
    );
    onUpdateSign({
      isUpdated: !vitalsign.isUpdated,
      data: vitalsign.data.filter((_, i) => i !== index)
    });
  };

  const displayRows = vitalSignList
    .slice()
    .sort(
      (a, b) => Number(new Date(a.checkTime)) - Number(new Date(b.checkTime))
    )
    .map((item, i) => ({
      ...item,
      id: i,
      checkTime: formatStringToDate(item.checkTime, 'hh:mm a'),
      action: (
        <IconButton
          size="small"
          onClick={() => onDeleteRow(i)}
          // sx={{ display: disabled ? 'none' : 'block' }}
        >
          <Delete />
        </IconButton>
      )
    }));

  console.log(vitalSignList);

  // const tableRow = disabled ? displayRows : [inputRow, ...displayRows];
  const tableRow = [inputRow];

  useEffect(() => {
    const initialVitalsign = vitalSignList.map(obj => {
      return {
        date: obj.date,
        checkTime: formatStringToDate(obj.checkTime, 'hh:mm a'),
        bt: Number(obj.bt),
        pr: Number(obj.pr),
        rr: Number(obj.rr),
        sbp: Number(obj.sbp),
        dbp: Number(obj.dbp)
      };
    });
    onUpdateSign({
      isUpdated: !vitalsign.isUpdated,
      data: [...initialVitalsign].sort(
        (a, b) =>
          formatTimeStrToNum(a.checkTime) - formatTimeStrToNum(b.checkTime)
      )
    });
  }, []);

  const ref = useRef<HTMLElement>(null);

  const scrollHorizon = (width: number) => {
    if (ref.current) {
      ref.current.scrollLeft += width;
    }
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <S.RecordBox>
          <MuiTable columns={columns} rows={tableRow} />
        </S.RecordBox>
      </Grid>
      <Grid item xs={12}>
        <S.ButtonWrap>
          <S.ButtonStyle onClick={() => scrollHorizon(-120)}>
            <ArrowCircleLeftOutlinedIcon />
            <span className="leftButton">이전 기록</span>
          </S.ButtonStyle>
          <div className="dummy">|</div>

          <S.ButtonStyle onClick={() => scrollHorizon(120)}>
            <span className="rightButton">이후 기록</span>
            <ArrowCircleRightOutlinedIcon />
          </S.ButtonStyle>
        </S.ButtonWrap>
        <S.RecordWrap ref={ref as React.RefObject<HTMLDivElement>}>
          <div
            style={{ display: 'flex', flexDirection: 'row' }}
            ref={ref as React.RefObject<HTMLDivElement>}
          >
            {displayRows.map((item, i) => {
              return (
                <S.RecordContainer key={i}>
                  <S.VitalBox>
                    <S.TitleContentsNoneBoder>
                      <span className="checkTime">
                        {item.date} {item.checkTime}
                      </span>
                      <S.BunttonBox>
                        <button>
                          <CreateOutlinedIcon sx={{ height: '20px' }} />
                        </button>
                        <button onClick={() => onDeleteRow(i)}>
                          <DeleteOutlineOutlinedIcon sx={{ height: '20px' }} />
                        </button>
                      </S.BunttonBox>
                    </S.TitleContentsNoneBoder>
                    <S.Contents>
                      <span className="vital">SBP(mmHg) </span>
                      <span className="vital">{item.sbp}</span>
                    </S.Contents>
                    <S.Contents>
                      <span className="vital">DBP(mmHg)</span>
                      <span className="vital">{item.dbp}</span>
                    </S.Contents>
                    <S.Contents>
                      <span className="vital">PR(회/분) </span>
                      <span className="vital">{item.pr}</span>
                    </S.Contents>
                    <S.Contents>
                      <span className="vital">RR(회/분) </span>
                      <span className="vital">{item.rr}</span>
                    </S.Contents>
                    <S.Contents>
                      <span className="vital">SPO2(%)</span>
                      <span className="vital">{item.sp02}</span>
                    </S.Contents>
                  </S.VitalBox>
                  <S.O2Box>
                    <S.TitleContentsYesBoder>
                      <span>
                        {'<'} O2 Therapy {'>'}
                      </span>
                    </S.TitleContentsYesBoder>
                    <S.Contents>
                      <span>Device</span>
                      <span>
                        <input />
                      </span>
                    </S.Contents>
                    <S.Contents>
                      <span>O2용량(L/min)</span>
                      <span>
                        <input />
                      </span>
                    </S.Contents>
                  </S.O2Box>
                </S.RecordContainer>
              );
            })}
          </div>
        </S.RecordWrap>
      </Grid>
    </Fragment>
  );
};

export default VitalSignRecord;
