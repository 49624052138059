import {
  Box,
  Checkbox,
  Grid,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  CNPSFourStyledTableCell,
  CNPSStyledTableCell,
  CNPSStyledTableCellWithoutLeft,
  CNPSStyledTableCellWithoutLeftRight,
  CNPSStyledTableCellWithoutRight,
  CNPSThreeStyledTableCell
} from 'styles/ObservationStyles/PainMuiStyles';
import { IFormRegister, IFormValues } from '../../type';
import * as S from '../../../styles/ObservationStyles/PainConditionStyle';
import MuiTextField from 'form/MuiTextField';
import adornment from 'form/adornment';
import { ReactComponent as Body } from 'assets/img/body.svg';
import MuiRadioGroup from 'form/MuiRadioGroup';
import CheckboxGroup from 'form/CheckboxGroup';

interface Props extends IFormRegister, IFormValues {}

const radioId = ['cnpsFace', 'cnpsBody', 'cnpsRes', 'cnpsSound'];
const contentLabel = [
  {
    id: 1,
    ko: '얼굴 표정',
    desc: [
      '자연스러운 표정',
      '미간 찡그림, 눈살 찌푸림, 눈물 글썽거림',
      '눈물을 흘림, 눈을 꽉 감음, 입을 씰룩거리며 눈을 찡그림',
      '이를 악뭄, 기관 내관을 밀어내거나 깨뭄'
    ]
  },
  {
    id: 2,
    ko: '신체 반응',
    desc: [
      '편안한 자세, 움직임이 없음',
      '느리고 조심스러운 움직임, 몸을 뒤척임',
      '통증 부위를 만지려고 하거나 문지름, 온몸에 힘을 줌',
      '온몸을 흔들거나 비틀며 심하게 움직임, 공격적 행동'
    ]
  },
  {
    id: 3,
    ko: '기계 호흡',
    desc: [
      '경보가 울리지 않고, 잘 적응함',
      '경보가 울리지만 곧 멈춤',
      '경보가 자주 울림, 인공호흡기에 저항함',
      '기계 호흡과 Fighting'
    ]
  },
  {
    id: 4,
    ko: '순응도 발성',
    desc: [
      '정상적인 말투',
      '끙끙대며 신음소리를 냄',
      '훌쩍거리거나, 소리를 내어 흐느껴 울음',
      '큰소리를 지름, 폭언을 함, 울부짖음'
    ]
  }
];
const scoreLabel = [
  { score: '0', label: '통증없음' },
  { score: '1~3', label: '약간 불편함' },
  { score: '4~6', label: '중간정도 불편함' },
  { score: '7~10', label: '매우 불편하고 아픈상태' }
];

export default function CNPS(props: Props) {
  const { setValue, getValues, register } = props;

  const [sumValue, setSumValue] = useState(0);

  const calculateSumValue = () => {
    setSumValue(
      radioId.reduce((acc, cur) => {
        const value = Number(getValues(cur));
        return value ? acc + value : acc;
      }, 0)
    );
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setValue(e.target.name, e.target.value);
    calculateSumValue();
  };

  useEffect(() => {
    calculateSumValue();
  }, []);

  return (
    <div>
      <Typography
        sx={{ fontSize: '20px', fontWeight: 700, whiteSpace: 'nowrap' }}
      >
        CNPS
      </Typography>
      <Box sx={{ width: '1200px', margin: '30px auto' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <CNPSStyledTableCell
                colSpan={2.5}
                align="center"
                sx={{ padding: '16px' }}
              >
                지표
              </CNPSStyledTableCell>
              <CNPSStyledTableCellWithoutRight
                align="center"
                sx={{ padding: '16px' }}
              >
                점수
              </CNPSStyledTableCellWithoutRight>
              <CNPSStyledTableCellWithoutLeft
                colSpan={2}
                sx={{ padding: '16px', paddingLeft: '60px' }}
              >
                설명
              </CNPSStyledTableCellWithoutLeft>
            </TableRow>
          </TableHead>
          <TableBody>
            {contentLabel.map(
              (content: { id: number; ko: string; desc: string[] }, i) => {
                if (content.id === 3) {
                  return (
                    <TableRow>
                      <CNPSThreeStyledTableCell
                        align="center"
                        sx={{
                          width: '60px',
                          paddingTop: '160px'
                        }}
                      >
                        {content.id}
                      </CNPSThreeStyledTableCell>
                      <CNPSStyledTableCell
                        align="center"
                        sx={{ padding: '16px', width: '180px' }}
                      >
                        {content.ko}
                      </CNPSStyledTableCell>
                      <CNPSStyledTableCellWithoutRight>
                        {content.desc.map((_, i) => (
                          <TableRow
                            sx={{
                              lineHeight: '44px',
                              borderBottom:
                                i !== content.desc.length - 1
                                  ? '1px solid lightgray'
                                  : ''
                            }}
                          >
                            <Box
                              sx={{ minWidth: '130px', textAlign: 'center' }}
                            >
                              {i}점
                            </Box>
                          </TableRow>
                        ))}
                      </CNPSStyledTableCellWithoutRight>
                      <CNPSStyledTableCellWithoutLeftRight>
                        {content.desc.map((v, i) => (
                          <TableRow
                            sx={{
                              lineHeight: '44px',
                              borderBottom:
                                i !== content.desc.length - 1
                                  ? '1px solid lightgray'
                                  : ''
                            }}
                          >
                            <Box
                              sx={{ paddingLeft: '60px', minWidth: '650px' }}
                            >
                              {v}
                            </Box>
                          </TableRow>
                        ))}
                      </CNPSStyledTableCellWithoutLeftRight>
                      <CNPSStyledTableCellWithoutLeft>
                        <RadioGroup
                          name={radioId[content.id - 1]}
                          defaultValue={Number(
                            getValues(radioId[content.id - 1])
                          )}
                        >
                          {content.desc.map((_, i) => (
                            <TableRow
                              sx={{
                                lineHeight: '43.8px',
                                textAlign: 'center',
                                borderBottom:
                                  i !== content.desc.length - 1
                                    ? '1px solid lightgray'
                                    : ''
                              }}
                            >
                              <Box sx={{ width: '180px' }}>
                                <Radio
                                  name={radioId[content.id - 1]}
                                  value={i}
                                  onChange={handleChange}
                                />
                              </Box>
                            </TableRow>
                          ))}
                        </RadioGroup>
                      </CNPSStyledTableCellWithoutLeft>
                    </TableRow>
                  );
                } else if (content.id === 4) {
                  return (
                    <TableRow>
                      <CNPSFourStyledTableCell
                        align="center"
                        sx={{ padding: '16px', width: '60px' }}
                      ></CNPSFourStyledTableCell>
                      <CNPSStyledTableCell
                        align="center"
                        sx={{ padding: '16px', width: '180px' }}
                      >
                        {content.ko}
                      </CNPSStyledTableCell>
                      <CNPSStyledTableCellWithoutRight>
                        {content.desc.map((_, i) => (
                          <TableRow
                            sx={{
                              lineHeight: '44px',
                              borderBottom:
                                i !== content.desc.length - 1
                                  ? '1px solid lightgray'
                                  : ''
                            }}
                          >
                            <Box
                              sx={{ minWidth: '130px', textAlign: 'center' }}
                            >
                              {i}점
                            </Box>
                          </TableRow>
                        ))}
                      </CNPSStyledTableCellWithoutRight>
                      <CNPSStyledTableCellWithoutLeftRight>
                        {content.desc.map((v, i) => (
                          <TableRow
                            sx={{
                              lineHeight: '44px',
                              borderBottom:
                                i !== content.desc.length - 1
                                  ? '1px solid lightgray'
                                  : ''
                            }}
                          >
                            <Box
                              sx={{ paddingLeft: '60px', minWidth: '650px' }}
                            >
                              {v}
                            </Box>
                          </TableRow>
                        ))}
                      </CNPSStyledTableCellWithoutLeftRight>
                      <CNPSStyledTableCellWithoutLeft>
                        <RadioGroup
                          name={radioId[content.id - 1]}
                          defaultValue={Number(
                            getValues(radioId[content.id - 1])
                          )}
                        >
                          {content.desc.map((_, i) => (
                            <TableRow
                              sx={{
                                lineHeight: '43.8px',
                                textAlign: 'center',
                                borderBottom:
                                  i !== content.desc.length - 1
                                    ? '1px solid lightgray'
                                    : ''
                              }}
                            >
                              <Box sx={{ width: '180px' }}>
                                <Radio
                                  name={radioId[content.id - 1]}
                                  value={i}
                                  onChange={handleChange}
                                />
                              </Box>
                            </TableRow>
                          ))}
                        </RadioGroup>
                      </CNPSStyledTableCellWithoutLeft>
                    </TableRow>
                  );
                } else {
                  return (
                    <TableRow>
                      <CNPSStyledTableCell
                        align="center"
                        sx={{ padding: '16px', width: '60px' }}
                      >
                        {content.id}
                      </CNPSStyledTableCell>
                      <CNPSStyledTableCell
                        align="center"
                        sx={{ padding: '16px', width: '180px' }}
                      >
                        {content.ko}
                      </CNPSStyledTableCell>
                      <CNPSStyledTableCellWithoutRight>
                        {content.desc.map((_, i) => (
                          <TableRow
                            sx={{
                              lineHeight: '44px',
                              borderBottom:
                                i !== content.desc.length - 1
                                  ? '1px solid lightgray'
                                  : ''
                            }}
                          >
                            <Box
                              sx={{ minWidth: '130px', textAlign: 'center' }}
                            >
                              {i}점
                            </Box>
                          </TableRow>
                        ))}
                      </CNPSStyledTableCellWithoutRight>
                      <CNPSStyledTableCellWithoutLeftRight>
                        {content.desc.map((v, i) => (
                          <TableRow
                            sx={{
                              lineHeight: '44px',
                              borderBottom:
                                i !== content.desc.length - 1
                                  ? '1px solid lightgray'
                                  : ''
                            }}
                          >
                            <Box
                              sx={{ paddingLeft: '60px', minWidth: '650px' }}
                            >
                              {v}
                            </Box>
                          </TableRow>
                        ))}
                      </CNPSStyledTableCellWithoutLeftRight>
                      <CNPSStyledTableCellWithoutLeft>
                        <RadioGroup
                          name={radioId[content.id - 1]}
                          defaultValue={Number(
                            getValues(radioId[content.id - 1])
                          )}
                        >
                          {content.desc.map((_, i) => (
                            <TableRow
                              sx={{
                                lineHeight: '43.8px',
                                textAlign: 'center',
                                borderBottom:
                                  i !== content.desc.length - 1
                                    ? '1px solid lightgray'
                                    : ''
                              }}
                            >
                              <Box sx={{ width: '180px' }}>
                                <Radio
                                  name={radioId[content.id - 1]}
                                  value={i}
                                  onChange={handleChange}
                                />
                              </Box>
                            </TableRow>
                          ))}
                        </RadioGroup>
                      </CNPSStyledTableCellWithoutLeft>
                    </TableRow>
                  );
                }
              }
            )}
          </TableBody>
        </Table>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-end'}
          sx={{ marginTop: '20px' }}
        >
          <Typography
            gutterBottom
            minWidth={115}
            fontWeight={700}
            variant="subtitle1"
          >
            합계: {sumValue}점
          </Typography>
          <Typography
            minWidth={115}
            variant="caption"
            sx={{ color: '#604180' }}
          >
            {scoreLabel.map(({ score, label }) => (
              <Typography variant="inherit">
                <Box component={'strong'} mr={0.5}>
                  {score}점:
                </Box>
                {label}
              </Typography>
            ))}
          </Typography>
        </Box>
      </Box>
      <Grid item xs={12}>
        <Box sx={{ width: '100%', marginTop: '40px', marginBottom: '12px' }}>
          <S.ContentContainer>
            <S.ItemWrap>
              <S.ItemsHead>통증점수</S.ItemsHead>
              <MuiTextField
                required={false}
                value={sumValue}
                sx={{
                  minWidth: '130px',
                  width: '130px'
                }}
                InputProps={{ ...adornment('', '점'), readOnly: true }}
                {...register('cnpsScore')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증부위</S.ItemsHead>
              <MuiTextField
                required={false}
                placeholder="직접 입력"
                {...register('cnpsWay')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <Body />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증양상</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.PATTERN"
                values={[1, 2]}
                defaultValue={getValues('cnpsPattern')}
                onChange={v => setValue('cnpsPattern', v)}
                width="100px"
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증빈도</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.FREQUENCY"
                values={[1, 2, 3]}
                defaultValue={getValues('cnpsFrequency')}
                onChange={v => setValue('cnpsFrequency', v)}
                width="100px"
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증기간</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.LONG"
                values={[1, 2]}
                defaultValue={getValues('cnpsLong')}
                onChange={v => setValue('cnpsLong', v)}
                width="100px"
              />
              <MuiTextField
                required={false}
                placeholder="직접 입력"
                {...register('cnpsLongInput')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증중재</S.ItemsHead>
              <span className="medi">비약물적 중재</span>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('cnpsNoneMediChecked1'))}
                  {...register('cnpsNoneMediChecked1')}
                  onChange={(e, checked) => {
                    setValue('cnpsNoneMediChecked1', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>심호흡 방법 교육</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('cnpsNoneMediChecked2'))}
                  {...register('cnpsNoneMediChecked2')}
                  onChange={(e, checked) => {
                    setValue('cnpsNoneMediChecked2', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>냉찜질 시행</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('cnpsNoneMediChecked3'))}
                  {...register('cnpsNoneMediChecked3')}
                  onChange={(e, checked) => {
                    setValue('cnpsNoneMediChecked3', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>휴식</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('cnpsNoneMediChecked4'))}
                  {...register('cnpsNoneMediChecked4')}
                  onChange={(e, checked) => {
                    setValue('cnpsNoneMediChecked4', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>기타</span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('cnpsNoneMediInput')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHeadDummy></S.ItemsHeadDummy>
              <span className="none_medi">약물적 중재</span>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('cnpsMediChecked'))}
                  {...register('cnpsMediChecked')}
                  onChange={(e, checked) => {
                    setValue('cnpsMediChecked', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>약물명 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('cnpsMediInput1')}
              />
              <S.CheckboxWrap>
                <span>용량 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('cnpsMediInput2')}
              />
              <S.CheckboxWrap>
                <span>투여방법 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('cnpsMediInput3')}
              />
            </S.ItemWrap>
          </S.ContentContainer>
        </Box>
      </Grid>
    </div>
  );
}
