import * as S from '../../../styles/ObservationStyles/PainConditionStyle';
import left from '../../../assets/img/left.png';
import right from '../../../assets/img/right.png';
import edit from '../../../assets/img/edit.png';
import remove from '../../../assets/img/remove.png';
import AddPain from './AddPain';

interface OwnProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PainCondition({ showModal, setShowModal }: OwnProps) {
  const handleModal = () => {
    setShowModal(prev => !prev);
  };

  return (
    <S.Cotainer>
      <S.Wrap>
        <S.PainArea>
          <S.PainHead>
            <span>통증 위치</span>
          </S.PainHead>
          <S.PainDesc></S.PainDesc>
        </S.PainArea>
        <S.ItemContainer>
          <S.RecordDIV>
            <S.RecordWrap>
              <div>
                <img src={left} alt="left" />
                <span>이전 기록</span>
              </div>
              <S.Separator />
              <div>
                <span>이후 기록</span>
                <img src={right} alt="right" />
              </div>
            </S.RecordWrap>
            {/* <S.AddBtn onClick={handleModal}>통증 사정 추가</S.AddBtn> */}
            <S.AddBtn onClick={handleModal}>통증 사정 추가</S.AddBtn>
          </S.RecordDIV>
          <S.ItemWrap>
            <S.ItemVerticalWrap>
              <S.Item>
                <S.ItemInner>
                  <S.ItemHead>
                    <span className="time">2023-00-00 00:00 AM</span>
                    <div className="btn-wrap">
                      <img src={edit} alt="edit" />
                      <img src={remove} alt="remove" />
                    </div>
                  </S.ItemHead>
                  <S.Details>
                    <span>통증여부</span>
                    <span>130</span>
                  </S.Details>
                  <S.Details>
                    <span>통증척도</span>
                    <span>80</span>
                  </S.Details>
                  <S.Details>
                    <span>통증점수</span>
                    <span>70</span>
                  </S.Details>
                  <S.Details>
                    <span>통증부위</span>
                    <span>20</span>
                  </S.Details>
                  <S.Details>
                    <span>통증양상</span>
                    <span>98</span>
                  </S.Details>
                  <S.Details style={{ border: 'none', paddingBottom: '0px' }}>
                    <span>통증빈도</span>
                    <span>98</span>
                  </S.Details>
                </S.ItemInner>
              </S.Item>
              <S.Item>
                <S.ItemInner>
                  <S.ItemHead>
                    <span className="head">통증 중재</span>
                  </S.ItemHead>
                  <S.Details>
                    <span>비약물적 중재</span>
                    <span>-</span>
                  </S.Details>
                  <S.Details style={{ border: 'none' }}>
                    <span>약물적 중재</span>
                    <span>-</span>
                  </S.Details>
                </S.ItemInner>
              </S.Item>
            </S.ItemVerticalWrap>
          </S.ItemWrap>
        </S.ItemContainer>
      </S.Wrap>
      <AddPain showModal={showModal} setShowModal={setShowModal} />
    </S.Cotainer>
  );
}
