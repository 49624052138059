import adornment from 'form/adornment';
import MuiTextField from 'form/MuiTextField';
import MuiRadioGroup from 'form/MuiRadioGroup';
import * as S from '../../../styles/ObservationStyles/ModalStyles';
import { IFormRegister, IFormValues } from '../../type';
import { ReactComponent as Body } from 'assets/img/body.svg';

interface Props extends IFormRegister, IFormValues {}

export default function JP(props: Props) {
  const { register, getValues, setValue } = props;
  return (
    <S.ContentContainer>
      <S.Title>JP-bag</S.Title>
      <S.ItemWrap>
        <S.ItemsHead>위치</S.ItemsHead>
        <MuiTextField
          required={false}
          sx={{
            minWidth: '130px',
            width: '130px',
            marginLeft: '10px'
          }}
          placeholder="직접 입력"
          {...register('jpLocation')}
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <Body />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>위치 이상</S.ItemsHead>
        <MuiRadioGroup
          i18nKey="EXIST.SHORT"
          values={[1, 2]}
          defaultValue={getValues('jpLocationLisk')}
          onChange={v => setValue('jpLocationLisk', v)}
          width="80px"
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>배액량</S.ItemsHead>
        <MuiTextField
          required={false}
          sx={{
            minWidth: '130px',
            width: '130px',
            marginLeft: '10px'
          }}
          InputProps={{ ...adornment('', 'CC') }}
          {...register('jpAmount')}
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>양상</S.ItemsHead>
        <MuiRadioGroup
          i18nKey="EXIST.ASPECT"
          values={[1, 2]}
          defaultValue={getValues('jpPattern')}
          onChange={v => setValue('jpPattern', v)}
          width="80px"
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>색</S.ItemsHead>
        <MuiRadioGroup
          i18nKey="EXIST.COLOR"
          values={[1, 2]}
          defaultValue={getValues('jpColor')}
          onChange={v => setValue('jpColor', v)}
          width="80px"
        />
      </S.ItemWrap>
      <S.Title>Hemovac</S.Title>
      <S.ItemWrap>
        <S.ItemsHead>위치</S.ItemsHead>
        <MuiTextField
          required={false}
          sx={{
            minWidth: '130px',
            width: '130px',
            marginLeft: '10px'
          }}
          placeholder="직접 입력"
          {...register('hvLocation')}
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <Body />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>위치 이상</S.ItemsHead>
        <MuiRadioGroup
          i18nKey="EXIST.SHORT"
          values={[1, 2]}
          defaultValue={getValues('hvLocationLisk')}
          onChange={v => setValue('hvLocationLisk', v)}
          width="80px"
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>배액량</S.ItemsHead>
        <MuiTextField
          required={false}
          sx={{
            minWidth: '130px',
            width: '130px',
            marginLeft: '10px'
          }}
          InputProps={{ ...adornment('', 'CC') }}
          {...register('hvAmount')}
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>양상</S.ItemsHead>
        <MuiRadioGroup
          i18nKey="EXIST.ASPECT"
          values={[1, 2]}
          defaultValue={getValues('hvPattern')}
          onChange={v => setValue('hvPattern', v)}
          width="80px"
        />
      </S.ItemWrap>
      <S.ItemWrap>
        <S.ItemsHead>색</S.ItemsHead>
        <MuiRadioGroup
          i18nKey="EXIST.COLOR"
          values={[1, 2]}
          defaultValue={getValues('hvColor')}
          onChange={v => setValue('hvColor', v)}
          width="80px"
        />
      </S.ItemWrap>
    </S.ContentContainer>
  );
}
