import styled from 'styled-components';

// vital record 부분
export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 40px;
  .dummy {
    font-size: 25px;
    margin-top: 5px;
  }
`;

export const ButtonStyle = styled.button`
  border: none;
  font-size: 14px;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 5px;
  background-color: ${props => props.theme.color.brand_100};
  cursor: pointer;

  .leftButton {
    font-size: 14px;
    margin-left: 5px;
  }

  .rightButton {
    font-size: 14px;
    margin-right: 5px;
  }
`;

export const RecordWrap = styled.div`
  width: 100%;
  display: flex;

  flex-direction: row;
  overflow-x: scroll;
  gap: 10px;
`;

export const RecordContainer = styled.div`
  height: 374px;
  width: 360px;
  display: flex;
  flex-direction: column;
`;

export const IORecordContainer = styled.div`
  height: 150px;
  width: 360px;
  display: flex;
  flex-direction: column;
`;

export const VitalBox = styled.div`
  background-color: #fff;
  width: 340px;
  height: 226px;
  margin-bottom: 10px;
  border-radius: 4px;
`;

export const TitleContentsNoneBoder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px;
  margin: 7px;
  span {
    font-size: 14px;
  }
`;

export const TitleContentsYesBoder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px;
  margin: 7px;
  border-bottom: 1px solid #dbdbdb;

  span {
    font-size: 14px;
    height: 28px;
  }
`;

export const Contents = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #dbdbdb;
  padding: 3px;
  margin: 7px;
  span {
    font-size: 14px;
    height: 28px;
  }
  .vital {
    font-size: 14px;
    height: 22px;
  }

  input {
    border: 1px solid #a7a7a8;
    border-radius: 4px;
    height: 22px;
  }
`;

export const O2Box = styled.div`
  background-color: #fff;
  width: 340px;
  height: 138px;
  margin-bottom: 10px;
  border-radius: 4px;
`;

// vital 최상위컴포넌트설정
export const GraphBox = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
`;

export const RecordBox = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
`;

// 액션 버튼부분 (지우기 수정 부분)
export const BunttonBox = styled.div`
  display: flex;
  flex-direction: row;
  button {
    border: none;
    background-color: white;
    cursor: pointer;
  }
`;
