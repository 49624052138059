import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface OneIOState {
  checkTime: string;
  date: string;
  sbp: number;
  dbp: number;
  pr: number;
}

export interface IOState {
  isUpdated: boolean;
  data: OneIOState[];
}

const initialState: IOState = {
  isUpdated: false,
  data: []
};

export const IOSlice = createSlice({
  name: 'intakeoutput',
  initialState,
  reducers: {
    initSign() {
      return initialState;
    },
    updateSign(_, action: PayloadAction<IOState>) {
      return action.payload;
    }
  }
});

export const { initSign, updateSign } = IOSlice.actions;
export default IOSlice.reducer;
