import { useState } from 'react';
// import useNotification from 'hooks/useNotification';

import useUser from 'store/user/useUser';
// import CollegePatientList from './CollegePatientList';

import PatientsList from './PatientsList';
// import StudentList from './StudentList';

const SearchToolbar = () => {
  const { student_uuid: user_id } = useUser();

  return <PatientsList user_id={user_id} />;
};

export default SearchToolbar;
