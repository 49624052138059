import Login from 'components/Login/Login';
import { Routes, Route, HashRouter } from 'react-router-dom';
import useUser from 'store/user/useUser';
import Main from 'components/Main';

export default function Router() {
  const { student_uuid } = useUser();

  return (
    <HashRouter>
      <Routes>
        <Route path="*" element={<Main />}></Route>
        {/* <Route path="/EHR" element={<EHR />}></Route>
        <Route path="/fillo" element={<Fillo />}></Route>
        <Route path="/observation" element={<Observation />}></Route>
        <Route path="/testResult" element={<TestResult />}></Route>
        <Route path="/records" element={<Records />}></Route>
        <Route path="/nursing" element={<Nursing />}></Route>
        <Route path="/safety" element={<Safety />}></Route>
        <Route path="/agreement" element={<Agreement />}></Route> */}
      </Routes>
    </HashRouter>
  );
}
