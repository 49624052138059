import { Fragment, useEffect, useRef } from 'react';
import { Box, listClasses, Typography } from '@mui/material';
import * as S from '../../../../styles/ObservationStyles/ObservationStyles';
import left from '../../..//../assets/img/left.png';
import right from '../../../../assets/img/right.png';

import usePatient from 'store/patient/usePatient';

import IVItem from './IVItem';
import { getSearchQuery } from 'apis/searchQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import { IClinicalObs } from 'apis/observation/type';
import TunnelItem from './TunnelItem';
import useUser from 'store/user/useUser';
import TtubeItem from './TtubeItem';
import LtubeItem from './LtubeItem';
import ChestItem from './ChestItem';
import JPItem from './JPItem';

interface Props {
  list: any[];
  onResetList: () => void;
}

const RecordList = ({ list, onResetList }: Props) => {
  const { student_name, student_no } = useUser();
  const { xrClinicalObservation } = usePatient();

  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { isUpdateNursingRecord } = getSearchQuery(search);

  const ivList = list.filter(a => a.option_type === 1);
  const ivRef = useRef<HTMLElement>(null);
  const ivScrollHorizon = (width: number) => {
    if (ivRef.current) {
      ivRef.current.scrollLeft += width;
    }
  };

  const tunnelList = list.filter(a => a.option_type === 2);
  const tunnelRef = useRef<HTMLElement>(null);
  const tunnelScrollHorizon = (width: number) => {
    if (tunnelRef.current) {
      tunnelRef.current.scrollLeft += width;
    }
  };

  const ttubeList = list.filter(a => a.option_type === 3);
  const ttubeRef = useRef<HTMLElement>(null);
  const ttubeScrollHorizon = (width: number) => {
    if (ttubeRef.current) {
      ttubeRef.current.scrollLeft += width;
    }
  };

  const ltubeList = list.filter(a => a.option_type === 4);
  const ltubeRef = useRef<HTMLElement>(null);
  const ltubeScrollHorizon = (width: number) => {
    if (ltubeRef.current) {
      ltubeRef.current.scrollLeft += width;
    }
  };

  const chestList = list.filter(a => a.option_type === 5);
  const chestRef = useRef<HTMLElement>(null);
  const chestScrollHorizon = (width: number) => {
    if (chestRef.current) {
      chestRef.current.scrollLeft += width;
    }
  };

  const jpList = list.filter(a => a.option_type === 6);
  const jpRef = useRef<HTMLElement>(null);
  const jpScrollHorizon = (width: number) => {
    if (jpRef.current) {
      jpRef.current.scrollLeft += width;
    }
  };

  useEffect(() => {
    if (!isUpdateNursingRecord) return;

    onResetList();
    navigate(pathname, { replace: true });
    // eslint-disable-next-line
  }, [isUpdateNursingRecord]);

  // if (list.length === 0) {
  //   return (
  //     <Typography variant="caption">작성된 임상관찰기록이 없습니다.</Typography>
  //   );
  // }

  return (
    <Fragment>
      <S.OutSideContainer>
        {/* IV 카테터 */}
        <Box sx={{ display: 'flex ', flexDirection: 'row' }}>
          <S.RecordWrap>
            <div>
              <img
                src={left}
                alt="left"
                onClick={() => ivScrollHorizon(-280)}
              />
              <span>이전 기록</span>
            </div>
            <S.Separator />
            <div>
              <span>이후 기록</span>
              <img
                src={right}
                alt="right"
                onClick={() => ivScrollHorizon(+280)}
              />
            </div>
          </S.RecordWrap>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
          }}
        >
          <S.ItemHead>IV cath</S.ItemHead>

          <Box
            sx={{ display: 'flex', width: '100%', overflow: 'scroll' }}
            ref={ivRef}
          >
            {ivList.map((record: IClinicalObs) => {
              return (
                <div style={{ display: 'flex' }}>
                  <IVItem
                    {...record}
                    key={record.xr_clinical_observation_id}
                    studentNo={student_no}
                    nurseName={student_name}
                    activeId={xrClinicalObservation?.xr_clinical_observation_id}
                    refetch={onResetList}
                  />
                </div>
              );
            })}
          </Box>
        </Box>

        {/* 터널 카테터 */}
        <Box sx={{ display: 'flex ', flexDirection: 'row', marginTop: '20px' }}>
          <S.RecordWrap>
            <div>
              <img
                src={left}
                alt="left"
                onClick={() => tunnelScrollHorizon(-280)}
              />
              <span>이전 기록</span>
            </div>
            <S.Separator />
            <div>
              <span>이후 기록</span>
              <img
                src={right}
                alt="right"
                onClick={() => tunnelScrollHorizon(+280)}
              />
            </div>
          </S.RecordWrap>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
          }}
        >
          <S.ItemHead>Tunnel cath</S.ItemHead>

          <Box
            sx={{ display: 'flex', width: '100%', overflow: 'scroll' }}
            ref={tunnelRef}
          >
            {tunnelList.map((record: IClinicalObs) => {
              return (
                <div style={{ display: 'flex' }}>
                  <TunnelItem
                    {...record}
                    key={record.xr_clinical_observation_id}
                    studentNo={student_no}
                    nurseName={student_name}
                    activeId={xrClinicalObservation?.xr_clinical_observation_id}
                    refetch={onResetList}
                  />
                </div>
              );
            })}
          </Box>
        </Box>

        {/* T-tube  */}
        <Box sx={{ display: 'flex ', flexDirection: 'row', marginTop: '20px' }}>
          <S.RecordWrap>
            <div>
              <img
                src={left}
                alt="left"
                onClick={() => ttubeScrollHorizon(-280)}
              />
              <span>이전 기록</span>
            </div>
            <S.Separator />
            <div>
              <span>이후 기록</span>
              <img
                src={right}
                alt="right"
                onClick={() => ttubeScrollHorizon(+280)}
              />
            </div>
          </S.RecordWrap>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
          }}
        >
          <S.ItemHead>T-tube</S.ItemHead>

          <Box
            sx={{ display: 'flex', width: '100%', overflow: 'scroll' }}
            ref={ttubeRef}
          >
            {ttubeList.map((record: IClinicalObs) => {
              return (
                <div style={{ display: 'flex' }}>
                  <TtubeItem
                    {...record}
                    key={record.xr_clinical_observation_id}
                    studentNo={student_no}
                    nurseName={student_name}
                    activeId={xrClinicalObservation?.xr_clinical_observation_id}
                    refetch={onResetList}
                  />
                </div>
              );
            })}
          </Box>
        </Box>

        {/* L-tube  */}
        <Box sx={{ display: 'flex ', flexDirection: 'row', marginTop: '20px' }}>
          <S.RecordWrap>
            <div>
              <img
                src={left}
                alt="left"
                onClick={() => ltubeScrollHorizon(-280)}
              />
              <span>이전 기록</span>
            </div>
            <S.Separator />
            <div>
              <span>이후 기록</span>
              <img
                src={right}
                alt="right"
                onClick={() => ltubeScrollHorizon(+280)}
              />
            </div>
          </S.RecordWrap>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
          }}
        >
          <S.ItemHead>L-tube</S.ItemHead>

          <Box
            sx={{ display: 'flex', width: '100%', overflow: 'scroll' }}
            ref={ltubeRef}
          >
            {ltubeList.map((record: IClinicalObs) => {
              return (
                <div style={{ display: 'flex' }}>
                  <LtubeItem
                    {...record}
                    key={record.xr_clinical_observation_id}
                    studentNo={student_no}
                    nurseName={student_name}
                    activeId={xrClinicalObservation?.xr_clinical_observation_id}
                    refetch={onResetList}
                  />
                </div>
              );
            })}
          </Box>
        </Box>

        {/* Chest-tube  */}
        <Box sx={{ display: 'flex ', flexDirection: 'row', marginTop: '20px' }}>
          <S.RecordWrap>
            <div>
              <img
                src={left}
                alt="left"
                onClick={() => chestScrollHorizon(-280)}
              />
              <span>이전 기록</span>
            </div>
            <S.Separator />
            <div>
              <span>이후 기록</span>
              <img
                src={right}
                alt="right"
                onClick={() => chestScrollHorizon(+280)}
              />
            </div>
          </S.RecordWrap>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
          }}
        >
          <S.ItemHeadChest>Chest-tube</S.ItemHeadChest>

          <Box
            sx={{ display: 'flex', width: '100%', overflow: 'scroll' }}
            ref={chestRef}
          >
            {chestList.map((record: IClinicalObs) => {
              return (
                <div style={{ display: 'flex' }}>
                  <ChestItem
                    {...record}
                    key={record.xr_clinical_observation_id}
                    studentNo={student_no}
                    nurseName={student_name}
                    activeId={xrClinicalObservation?.xr_clinical_observation_id}
                    refetch={onResetList}
                  />
                </div>
              );
            })}
          </Box>
        </Box>

        {/* JP back / Hemovac  */}
        <Box sx={{ display: 'flex ', flexDirection: 'row', marginTop: '20px' }}>
          <S.RecordWrap>
            <div>
              <img
                src={left}
                alt="left"
                onClick={() => jpScrollHorizon(-280)}
              />
              <span>이전 기록</span>
            </div>
            <S.Separator />
            <div>
              <span>이후 기록</span>
              <img
                src={right}
                alt="right"
                onClick={() => jpScrollHorizon(+280)}
              />
            </div>
          </S.RecordWrap>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
          }}
        >
          <S.ItemHead>JP-bag / Hemovac</S.ItemHead>

          <Box
            sx={{ display: 'flex', width: '100%', overflow: 'scroll' }}
            ref={jpRef}
          >
            {jpList.map((record: IClinicalObs) => {
              return (
                <div style={{ display: 'flex' }}>
                  <JPItem
                    {...record}
                    key={record.xr_clinical_observation_id}
                    studentNo={student_no}
                    nurseName={student_name}
                    activeId={xrClinicalObservation?.xr_clinical_observation_id}
                    refetch={onResetList}
                  />
                </div>
              );
            })}
          </Box>
        </Box>
      </S.OutSideContainer>
    </Fragment>
  );
};

export default RecordList;
