import styled from 'styled-components';

export const Layout = styled.div`
  width: 100%;
  /* height: calc(100% - 240px); */
  /* margin-top: 240px; */
  min-height: 684px;
  min-width: 1200px;

  background: ${props => props.theme.color.brand_100};
  /* overflow: scroll; */
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled.div`
  margin: 15px 0 0px 20px;
  display: flex;
  gap: 10px;
  height: 30px;
  padding-top: 10px;
  span {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const ButtonWithTimeBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  span {
    font-size: 12px;
    font-weight: 300;
    color: ${props => props.theme.color.black_600};
    height: 30px;
    padding-top: 25px;
    margin-right: 10px;
  }
`;

export const Button = styled.button`
  background-color: ${props => props.theme.color.brand_300};
  color: white;
  width: 64px;
  height: 32px;
  border: none;
  border-radius: 4px;
  padding: 6px;
  margin: 15px 20px 0 0;

  cursor: pointer;
`;

export const Container = styled.div`
  margin: 10px 20px 0 20px;
  width: 97%;
  min-width: 1150px;
  background-color: white;
`;
