import React from 'react';
import * as S from '../../styles/TestStyles/TestDateStyles';

interface OwnProps {
  filledCount: number;
}
export default function DateProgressBar({ filledCount }: OwnProps) {
  return (
    <>
      <S.CircleWrap>
        {[...Array(7)].map((_, i) => (
          <React.Fragment key={i}>
            {i > 0 && i < 7 && <S.ConnectionLine />}
            <S.CircleWrapper>
              <S.Circle
                key={i}
                $status={
                  (filledCount === 0 && i === 0) ||
                  (filledCount >= 1 && i === 0) ||
                  filledCount > i + 1
                    ? 1
                    : 0
                }
              />
            </S.CircleWrapper>
          </React.Fragment>
        ))}
      </S.CircleWrap>
      <S.TextWrapper>
        <S.Box>
          <S.Text>1W</S.Text>
          <S.Text>1M</S.Text>
          <S.Text>3M</S.Text>
          <S.Text>6M</S.Text>
          <S.Text>1Y</S.Text>
          <S.Text>3Y</S.Text>
          <S.Text>5Y</S.Text>
        </S.Box>
      </S.TextWrapper>
    </>
  );
}
