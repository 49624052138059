import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { signIn, signOut, UserState } from '.';
import useSelectorTyped from 'store/useSelectorTyped';

type TLocalStorageKey = 'USER_EMAIL' | string;

export const removeLocalStorage = (storageKey: TLocalStorageKey) =>
  localStorage.removeItem(storageKey);

export const persistKey = `${process.env.NODE_ENV ?? 'development'} v0.2`;

const useUser = () => {
  const dispatch = useDispatch();

  const userState = useSelectorTyped<UserState>(state => {
    return state.user;
  });

  const onSignIn = useCallback(
    (user: UserState) => {
      dispatch(signIn(user));
    },
    [dispatch]
  );

  const onSignOut = useCallback(() => {
    dispatch(signOut());
    removeLocalStorage(`persist:${persistKey}`);
  }, [dispatch]);

  return { ...userState, onSignIn, onSignOut };
};

export default useUser;
