export const initialFillo = {
  update_at: '',

  default_info: {
    arrival_date: '',
    arrival_time: '',
    creation_date: '',
    creation_time: '',
    drink: { value: 3, input1: '', checked: [], input2: '' },
    smoke: { value: 3, input1: '', checked: [], input2: '' }
  },
  information: {
    root: { value: 3, input: '' },
    method: { value: 4, input: '' },
    motive: ''
  },
  family_historys: {
    family: { checked: [] },
    son: { checked: [], input: '' },
    daughter: { checked: [], input: '' },
    family_history: ''
  },
  disease_historys: {
    history: { value: 3, checked: [], input: '' },
    etc_history: ''
  },
  hospitalization: '',
  more_details: ''
};
