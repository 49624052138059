export const initialSafety = {
  // 낙상위험 평가도구
  morse_fall_scale: {
    history: { value: 3, checked: [] },
    secondary_diagnosis: { value: 3, checked: [] },
    ambulatory_aid: { value: 4, checked: [] },
    iv: { value: 3, checked: [] },
    gait: { value: 4, checked: [] },
    mental_status: { value: 3, checked: [] }
  },
  kid_mfs: {
    age: { value: 5, checked: [] },
    gender: { value: 3, checked: [] },
    diagnosis: { value: 5, checked: [] },
    cognitive_impairment: { value: 5, checked: [] },
    environmental: { value: 4, checked: [] },
    surgical: { value: 4, checked: [] },
    drug_use: { value: 4, checked: [] }
  },
  // 낙상 관련 간호 제공
  related_care: {
    bracelet_provided: { checked: [] }
  },
  // 욕창위험도평가
  branden_scale: {
    sense: { value: 5, checked: [] },
    humidity: { value: 5, checked: [] },
    activity: { value: 5, checked: [] },
    mobility: { value: 5, checked: [] },
    nutrition: { value: 5, checked: [] },
    friction_shearing: { value: 5, checked: [] }
  },
  // 욕창 기록
  bedsored_record: {
    skin_damage_status: { value: 3, checked: [] },
    damaged_area: '',
    size: '',
    skin_damage_stage: { value: 7, checked: [], input: '' }
  },
  // 억제대 , part1 : 순환상태 , part2: 피부상태
  restraint_band: {
    agreement: { value: 3, checked: [] },
    restraint_band_part1: { value: 3, checked: [] },
    restraint_band_part2: { value: 3, checked: [] },
    reason: { value: 3, checked: [] },
    application_part: '',
    remove_reason: '',
    intervention: { value: 4, checked: [] }
  }
};
