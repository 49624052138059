import * as S from '../../styles/TestStyles/TestContentStyles';
import { useState } from 'react';

export default function TestContents() {
  const [showRefer, setShowRefer] = useState<number>(0);

  const handleEnterToggle = () => {
    setShowRefer(showRefer === 1 ? 0 : 1);
  };

  return (
    <S.Layout>
      <S.Container>
        <S.Title>
          <span>2023-00-00</span>
          <S.BtnWrap>
            <span>참고치</span>
            <S.ToggleContainer onClick={handleEnterToggle}>
              <div
                className={`toggle-container ${showRefer === 1 ? 'toggle--checked' : null}`}
              />
              <div
                className={`toggle-circle ${showRefer === 1 ? 'toggle--checked' : null}`}
              />
            </S.ToggleContainer>
          </S.BtnWrap>
        </S.Title>
        <S.ContentWrap>
          <S.Item>
            <S.ItemWrap>
              <S.ItemTitle>
                <span>항목</span>
                <span>14:00</span>
                <span>19:00</span>
              </S.ItemTitle>
              <S.ItemDesc>
                <span>WBC</span>
                <span>13.0</span>
                <span>8.9</span>
              </S.ItemDesc>
              <S.ItemDesc>
                <span>WBC</span>
                <span>13.0</span>
                <span>8.9</span>
              </S.ItemDesc>
              <S.ItemDesc>
                <span>WBC</span>
                <span>13.0</span>
                <span>8.9</span>
              </S.ItemDesc>
              <S.ItemDesc>
                <span>WBC</span>
                <span>13.0</span>
                <span>8.9</span>
              </S.ItemDesc>
            </S.ItemWrap>
          </S.Item>
          {showRefer === 1 && (
            <S.Reference>
              <S.ReferWrap>
                <S.ReferTitle>
                  <span>참고치</span>
                </S.ReferTitle>
                <S.ReferDesc>
                  <span>4-10(*10^3/mm)</span>
                </S.ReferDesc>
                <S.ReferDesc>
                  <span>4-10(*10^3/mm)</span>
                </S.ReferDesc>
                <S.ReferDesc>
                  <span>4-10(*10^3/mm)</span>
                </S.ReferDesc>
                <S.ReferDesc>
                  <span>4-10(*10^3/mm)</span>
                </S.ReferDesc>
              </S.ReferWrap>
            </S.Reference>
          )}
        </S.ContentWrap>
      </S.Container>
    </S.Layout>
  );
}
