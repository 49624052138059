import 'styled-components';
import { DefaultTheme } from 'styled-components';

export const color = {
  // BRAND
  brand_100: '#F2EDFB',
  brand_200: '#866EA0',
  brand_300: '#604180',

  // Black-White
  black_900: '#282828',
  black_800: '#424242',
  black_700: '#5C5A5B',
  black_600: '#747575',
  black_500: '#8E8E8D',
  black_400: '#A7A7A8',
  black_300: '#C2C1C1',
  black_200: '#DBDBDB',
  black_100: '#F5F4F5',

  // ACTIVE-ERROR
  error: '#E63529',
  positive: '#3D69EF'
};

declare module 'styled-components' {
  export interface DefaultTheme {
    color: typeof color;
  }
}

export const theme: DefaultTheme = {
  color
};
