import * as S from '../../styles/SafetyStyles/ModalStyles';
import X from '../../assets/img/x-icon.png';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { initialSafety } from './initialState';
import usePatient from 'store/patient/usePatient';
import useNotification from 'hook/useNotification';
import { createObservation } from 'apis/observation/observationApi';
import MuiTextField from 'form/MuiTextField';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { AccessTime } from '@mui/icons-material';
import MFS from './MFS';
import KidMFS from './KidMFS';
import useUser from 'store/user/useUser';

interface OwnProps {
  showFall: boolean;
  setShowFall: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function FallModal({ showFall, setShowFall }: OwnProps) {
  /* 1 : 낙상 , 2: 소아 낙상 */
  const [category, setCategory] = useState<number>(1);
  const curDate = new Date();
  const curDateStr = curDate.toISOString().split('T')[0];
  const curDateTime = curDate;
  const [recordDate, setRecordDate] = useState<string | null>(curDateStr);
  const [recordTime, setRecordTime] = useState<Date | null>(curDateTime);
  const { student_uuid: user_id } = useUser();
  const { onSuccess, onFail, onResultCode, onRequired } = useNotification();
  const { patient, patientInfo, onSelectedPatientInfo } = usePatient();
  const { register, watch, setValue, getValues, handleSubmit } = useForm({
    defaultValues: initialSafety
  });
  const onSubmit = (data: typeof initialSafety) => {
    const id = patient?.patient_id;
    const { morse_fall_scale } = data;

    // Combine date and time into the correct format
    const checkTime =
      recordDate && recordTime
        ? `${recordDate} ${recordTime.getHours()}:${recordTime.getMinutes()}`
        : null;

    const request = {
      user_id,
      patient_id: id,
      content: JSON.stringify(data),
      option_type: category,
      check_time: checkTime
    };

    createObservation(request)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);
        onSuccess('저장성공.');
        setShowFall(false);
        location.reload();
      })
      .catch(e => onFail('저장실패.', e));
  };

  const formProps = {
    register,
    watch,
    getValues,
    setValue
  };

  const handleCategoryClick = (num: number) => {
    setCategory(num);
  };

  return (
    <S.ModalContainer $showModal={showFall}>
      {/*   <form onSubmit={handleSubmit(onSubmit)}> */}
      <S.ModalHead>
        <S.HeadText>
          <img
            src={X}
            alt="cancel"
            onClick={() => {
              setShowFall(false);
            }}
          />
          <span>낙상</span>
        </S.HeadText>
        <S.ModalSave>
          <span>최근 저장한 시간 : 2022-10-12 13:30 PM</span>
          <S.SaveBtn type="submit">저장</S.SaveBtn>
        </S.ModalSave>
      </S.ModalHead>
      <S.ModalContentContainer>
        <S.BtnWrap>
          <S.CategoryBtn
            type="button"
            selected={category === 1}
            onClick={() => handleCategoryClick(1)}
          >
            낙상위험 평가도구
          </S.CategoryBtn>
          <S.CategoryBtn
            type="button"
            selected={category === 2}
            onClick={() => handleCategoryClick(2)}
          >
            소아 낙상위험 평가도구
          </S.CategoryBtn>
        </S.BtnWrap>
        <span className="modal-title">
          {category === 1 ? '낙상위험 평가도구' : '소아 낙상위험 평가도구 '}
        </span>
        <S.IndexTitle>
          <span>측정 날짜 및 시간</span>
        </S.IndexTitle>
        <S.DateTimeWrap>
          <MuiTextField
            type="date"
            fullWidth
            required={false}
            sx={{ width: '120px' }}
            value={recordDate}
            onChange={e => setRecordDate(e.target.value)}
          />
          <MobileTimePicker
            value={recordTime}
            onChange={setRecordTime}
            renderInput={params => (
              <MuiTextField
                {...params}
                fullWidth
                InputProps={{ endAdornment: <AccessTime /> }}
                size="small"
                sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '12px'
                  },
                  width: '120px'
                }}
              />
            )}
          />
        </S.DateTimeWrap>
        {category === 1 && <MFS {...formProps} />}
        {category === 2 && <KidMFS {...formProps} />}
        <span className="modal-title">낙상 관련 간호 제공</span>
        {/* TODO : 정서님 체크박스 도움! */}
        <S.IndexTitle>
          <span>낙상 고위험</span>
        </S.IndexTitle>
        <S.IndexContent>
          <S.CheckWrap>
            <S.ItemsHead>낙상고위험 팔찌 제공</S.ItemsHead>
          </S.CheckWrap>
          <S.CheckWrap>
            <S.ItemsHead>침상에 낙상고위험군 알리는 표시 부착</S.ItemsHead>
          </S.CheckWrap>
          <S.CheckWrap style={{ borderBottom: 'none' }}>
            <S.ItemsHead>낙상고위험 대상임을 교육</S.ItemsHead>
          </S.CheckWrap>
        </S.IndexContent>
        <S.IndexTitle>
          <span>환경 관련</span>
        </S.IndexTitle>
        <S.IndexContent>
          <S.CheckWrap>
            <S.ItemsHead>바닥 미끄럽지 않은지 확인</S.ItemsHead>
          </S.CheckWrap>
          <S.CheckWrap>
            <S.ItemsHead>미끄럽지 않은 슬리퍼 사용하도록 교육</S.ItemsHead>
          </S.CheckWrap>
          <S.CheckWrap style={{ borderBottom: 'none' }}>
            <S.ItemsHead>침대 side rail 사용법 교육</S.ItemsHead>
          </S.CheckWrap>
        </S.IndexContent>
        <S.IndexTitle>
          <span>배변 / 배설</span>
        </S.IndexTitle>
        <S.IndexContent>
          <S.CheckWrap>
            <S.ItemsHead>코모도 제공</S.ItemsHead>
          </S.CheckWrap>
          <S.CheckWrap>
            <S.ItemsHead>보호자와 함께 화장실 갈 수 있도록 교육</S.ItemsHead>
          </S.CheckWrap>
          <S.CheckWrap>
            <S.ItemsHead>침상에서 소별 볼 수 있도록 소변기 제공함</S.ItemsHead>
          </S.CheckWrap>
          <S.CheckWrap style={{ borderBottom: 'none' }}>
            <S.ItemsHead>소변기 사용방법에 대하여 교육함</S.ItemsHead>
          </S.CheckWrap>
        </S.IndexContent>
      </S.ModalContentContainer>
    </S.ModalContainer>
  );
}
