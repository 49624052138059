import apiGateway from '../axios';
import { formatToRequestParameter } from '../formatting';
import { IGetSurvey, IUpdateFillo } from './type';
import camelcaseKeys from 'camelcase-keys';

// e-CARDEX
export const getFillo = (request: IGetSurvey) => {
  const url = `/enr_etc/xr/get/initial_hospitalization?${formatToRequestParameter(request)}`;
  return apiGateway.get(url);
};

export const updateFillo = (request: IUpdateFillo) => {
  const url = `/enr_etc/xr/create/initial_hospitalization
`;
  return apiGateway.post(url, request);
};
