import { useForm } from 'react-hook-form';
import { initialVsState } from './initialState';
import VitalSignGraph from './VitalSignGraph';
import VitalSignRecord from './VitalSignRecord';

import * as S from '../../../styles/ObservationStyles/VitalStyles';
import VitalTable from './VitalTable';

export default function VitalSign() {
  const { handleSubmit, watch, getValues, setValue } = useForm({
    defaultValues: initialVsState
  });

  const formProps = {
    watch,
    getValues,
    setValue
  };

  return (
    <div>
      <S.GraphBox>
        <VitalTable {...formProps} />
        <VitalSignGraph {...formProps} />
      </S.GraphBox>
      <VitalSignRecord {...formProps} />
    </div>
  );
}
