import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 12px;
  height: 100%;
`;

export const TitleBtnWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    font-size: 14px;
    font-weight: 700;
  }
`;

export const AdditionalBtn = styled.div`
  background: #ffc000;
  padding: 10px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
`;

export const Contents = styled.div`
  background: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

export const Inner = styled.div`
  margin: 8px;
  .content {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const BasicCard = styled.div`
  border-bottom: 1px solid #dddddd;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 8px;
  padding-bottom: 8px;
  .addi {
    font-size: 14px;
    color: #0019ff;
    margin-left: 30px;
  }
  .blue {
    color: #0019ff;
    font-size: 14px;
  }
`;

export const Medication = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  .medi {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const TestPrescription = styled.div`
  border-bottom: 1px solid #dddddd;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 8px;
  padding-bottom: 8px;
  .addi {
    font-size: 14px;
    color: #0019ff;
    margin-left: 30px;
  }
`;
