import styled from 'styled-components';

export const Layout = styled.div`
  width: 100%;
  /* margin-top: 240px; */
  background: ${props => props.theme.color.brand_100};
`;

export const Container = styled.div`
  margin: 12px;
  flex-direction: column;
  height: 100%;
  display: flex;
  gap: 8px;
`;

export const Head = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .patient {
    font-weight: 500;
    font-size: 14px;
    margin-top: 12px;
    margin-left: 8px;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
`;

export const CategoryBtn = styled.button<{ selected: boolean }>`
  background: ${props =>
    props.selected ? props.theme.color.brand_300 : '#fff'};
  color: ${props => (props.selected ? '#fff' : props.theme.color.black_400)};
  width: 100px;
  height: 36px;
  font-size: 12px;
  line-height: 15px;
  font-family: 'Spoqa', sans-serif;
  border: ${props => (props.selected ? 'none' : '1px solid #a7a7a8')};
  border-radius: 4px;
  cursor: pointer;
`;

export const OptionBtn = styled.button`
  border: none;
  border-radius: 4px;
  padding: 10px;
  background: ${props => props.theme.color.brand_300};
  color: #fff;
  font-family: 'Spoqa', sans-serif;
  cursor: pointer;
  margin-left: auto;
`;

export const ClinicalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  height: 100%;
`;

export const Wrap = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  flex-direction: row;
`;

export const IntubationPosition = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
`;

export const IntubationHead = styled.div`
  background: #fff;
  border-radius: 4px;
  font-weight: 700;
  font-size: 13px;
  padding: 8px;
`;

export const IntubationDesc = styled.div`
  background: #fff;
  border-radius: 4px;
  width: 320px;
  height: 100%;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 9;
`;

export const ItemWrap = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  margin-top: 12px;
  justify-content: center;
`;

export const ItemRL = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 15px 0px 15px;
  div {
    font-weight: 400;
    font-size: 20px;
  }
`;

export const ItemBtnWrap = styled.div`
  display: flex;
  justify-content: right;
  margin: 10px 20px 0px 0px;
  gap: 15px;
`;

export const ItemButton = styled.button`
  background-color: ${props => props.theme.color.brand_300};
  width: 36px;
  height: 36px;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #979797;
    /* border: 1px solid ${props => props.theme.color.brand_300}; */
  }
`;

export const RecordWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
  div {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  img {
    width: 20px;
    height: 20px;
  }
  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const Separator = styled.span`
  height: 12px;
  margin: 0;
  padding: 0;
  border: 0.5px solid ${props => props.theme.color.black_800};
`;

export const CategoryItemWrap = styled.div`
  display: flex;
  width: 286px;

  gap: 8px;
  margin-bottom: 8px;
  background-color: white;
  margin: 10px 15px 5px 15px;
`;

export const ItemHead = styled.div`
  width: 160px;
  height: 132px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.color.black_300};
  border-radius: 4px;
  background: ${props => props.theme.color.black_200};
  font-size: 14px;
  margin-top: 20px;
`;

export const ItemHeadChest = styled.div`
  width: 160px;
  height: 198px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.color.black_300};
  border-radius: 4px;
  background: ${props => props.theme.color.black_200};
  font-size: 14px;
  margin-top: 20px;
`;

export const ItemDesc = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  gap: 12px;
  background: #fff;
  padding: 5px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #fff;
  .buttontack {
    display: flex;
    justify-content: space-between;
    width: 260px;
  }
  .timetack {
    padding-top: 10px;
  }
`;

export const Overlay = styled.div<{ $show: boolean }>`
  display: ${({ $show }) => ($show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 101;
`;

export const OutSideContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
