/* eslint-disable @typescript-eslint/no-explicit-any */
import * as S from '../../../styles/ObservationStyles/ObservationStyles';
import Options from './Options';
import usePatient from 'store/patient/usePatient';
import { Box, Skeleton } from '@mui/material';
import ClinicalRecord from './ClinicalRecord';
import { ReactComponent as BodyFront } from 'assets/img/body-front.svg';
import { ReactComponent as BodyBack } from 'assets/img/body-back.svg';
import { useState } from 'react';

interface OwnProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Clinical({ showModal, setShowModal }: OwnProps) {
  const { patientInfo } = usePatient();
  const [frontBack, setFrontBack] = useState(false);

  const fronteHandle = () => {
    setFrontBack(false);
  };

  const backHandle = () => {
    setFrontBack(true);
  };

  const handleModal = () => {
    setShowModal(prev => !prev);
  };

  console.log('frontBack', frontBack);

  if (!patientInfo) {
    return <Skeleton variant="rectangular" sx={{ flex: 1 }} />;
  }

  const Content = () => {
    const contentBoxProps = {
      display: 'flex',
      mt: 2.5,
      columnGap: 2.5,
      overflow: 'hidden'
    };

    if (!patientInfo) {
      return (
        <Box {...contentBoxProps}>
          <Box flex={1} display="flex" flexDirection="column" overflow="auto">
            <Skeleton variant="rectangular" sx={{ flex: 1 }} />
          </Box>
          <Box flex={1} display="flex" flexDirection="column" overflow="auto">
            <Skeleton variant="rectangular" sx={{ flex: 1 }} />
          </Box>
        </Box>
      );
    }

    return (
      <Box {...contentBoxProps}>
        <ClinicalRecord patientInfo={patientInfo} />
      </Box>
    );
  };

  return (
    <S.ClinicalContainer>
      <S.OptionBtn onClick={handleModal}>Option 추가</S.OptionBtn>
      <S.Wrap>
        <S.IntubationPosition>
          <S.IntubationHead>
            <span>삽관 위치</span>
          </S.IntubationHead>
          <S.IntubationDesc>
            <S.ItemBtnWrap>
              <S.ItemButton onClick={fronteHandle}>앞</S.ItemButton>
              <S.ItemButton onClick={backHandle}>뒤</S.ItemButton>
            </S.ItemBtnWrap>
            <S.ItemWrap>
              {frontBack === false ? <BodyFront /> : <BodyBack />}
            </S.ItemWrap>
            <S.ItemRL>
              {frontBack === false ? (
                <>
                  <div>R</div>
                  <div>L</div>
                </>
              ) : (
                <>
                  <div>L</div>
                  <div>R</div>
                </>
              )}
            </S.ItemRL>
          </S.IntubationDesc>
        </S.IntubationPosition>
        <S.ItemContainer>
          <Content />
        </S.ItemContainer>
      </S.Wrap>
      <Options showModal={showModal} setShowModal={setShowModal} />
    </S.ClinicalContainer>
  );
}
