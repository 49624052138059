import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 12px;
  height: 100%;
  .title {
    font-size: 13px;
    font-weight: 700;
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  align-items: stretch;
  gap: 8px;
  width: 100%;
`;

export const Initial = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #fff;
  border-radius: 4px;
  flex: 0.5;
  font-size: 18px;
  font-weight: 700;
`;

export const Desc = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #fff;
  border-radius: 4px;
  flex: 9.5;
  gap: 4px;
  font-size: 14px;
`;

export const Contents = styled.div`
  height: auto;
  background: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
`;

export const Inner = styled.div`
  margin: 8px;
  .content {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const TestPrescription = styled.div`
  border-bottom: 1px solid #dddddd;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  margin-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
  .addi {
    font-size: 14px;
    color: #0019ff;
    margin-left: 30px;
  }
`;
