import * as S from '../../styles/SafetyStyles/ModalStyles';
import X from '../../assets/img/x-icon.png';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { initialSafety } from './initialState';
import MuiTextField from 'form/MuiTextField';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { AccessTime } from '@mui/icons-material';
import MuiRadioGroup from 'form/MuiRadioGroup';
import frontBody from '../../assets/img/frontBody.png';
import backBody from '../../assets/img/backBody.png';

interface OwnProps {
  showBed: boolean;
  setShowBed: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function BedsoreModal({ showBed, setShowBed }: OwnProps) {
  const curDate = new Date();
  const curDateStr = curDate.toISOString().split('T')[0];
  const curDateTime = curDate;
  const [recordDate, setRecordDate] = useState<string | null>(curDateStr);
  const [recordTime, setRecordTime] = useState<Date | null>(curDateTime);
  const { register, watch, setValue, getValues, handleSubmit } = useForm({
    defaultValues: initialSafety
  });
  /* const onSubmit = (data: typeof initialSafety) => {
    const id = patient?.patient_id;
    const { branden_scale } = data;

    // Combine date and time into the correct format
    const checkTime =
      recordDate && recordTime
        ? `${recordDate} ${recordTime.getHours()}:${recordTime.getMinutes()}`
        : null;

    const request = {
      user_id,
      patient_id: id,
      content: JSON.stringify(data),
      check_time: checkTime
    };

    createObservation(request)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);
        onSuccess('저장성공.');
        setShowBed(false);
        location.reload();
      })
      .catch(e => onFail('저장실패.', e));
  }; */
  return (
    <S.ModalContainer $showModal={showBed}>
      {/*   <form onSubmit={handleSubmit(onSubmit)}> */}
      <S.ModalHead>
        <S.HeadText>
          <img
            src={X}
            alt="cancel"
            onClick={() => {
              setShowBed(false);
            }}
          />
          <span>낙상</span>
        </S.HeadText>
        <S.ModalSave>
          <span>최근 저장한 시간 : 2022-10-12 13:30 PM</span>
          <S.SaveBtn type="submit">저장</S.SaveBtn>
        </S.ModalSave>
      </S.ModalHead>
      <S.ModalContentContainer>
        <span className="modal-title">욕창사정</span>
        <S.IndexTitle>
          <span>측정 날짜 및 시간</span>
        </S.IndexTitle>
        <S.DateTimeWrap>
          <MuiTextField
            type="date"
            fullWidth
            required={false}
            sx={{ width: '120px' }}
            value={recordDate}
            onChange={e => setRecordDate(e.target.value)}
          />
          <MobileTimePicker
            value={recordTime}
            onChange={setRecordTime}
            renderInput={params => (
              <MuiTextField
                {...params}
                fullWidth
                InputProps={{ endAdornment: <AccessTime /> }}
                size="small"
                sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '12px'
                  },
                  width: '120px'
                }}
              />
            )}
          />
        </S.DateTimeWrap>
        <S.IndexTitle>
          <span>욕창위험도평가</span>
        </S.IndexTitle>
        <S.IndexContent>
          <S.ItemWrap>
            <S.ItemsHead>평가 항목</S.ItemsHead>
            <S.ScoreWrap>
              <span style={{ marginLeft: '-28px' }}>1점</span>
              <span>2점</span>
              <span>3점</span>
              <span style={{ marginLeft: '15px' }}>4점</span>
            </S.ScoreWrap>
          </S.ItemWrap>
          <S.ItemWrap>
            <S.ItemsHead>감각지각</S.ItemsHead>
            <MuiRadioGroup
              i18nKey="SAFETY.SENSE"
              values={[1, 2, 3, 4]}
              defaultValue={getValues('branden_scale.sense.value')}
              onChange={v => setValue('branden_scale.sense.value', v)}
              width="250px"
            />
          </S.ItemWrap>
          <S.ItemWrap>
            <S.ItemsHead>습기</S.ItemsHead>
            <MuiRadioGroup
              i18nKey="SAFETY.HUMIDITY"
              values={[1, 2, 3, 4]}
              defaultValue={getValues('branden_scale.humidity.value')}
              onChange={v => setValue('branden_scale.humidity.value', v)}
              width="250px"
            />
          </S.ItemWrap>
          <S.ItemWrap>
            <S.ItemsHead>활동</S.ItemsHead>
            <MuiRadioGroup
              i18nKey="SAFETY.ACTIVITY"
              values={[1, 2, 3, 4]}
              defaultValue={getValues('branden_scale.activity.value')}
              onChange={v => setValue('branden_scale.activity.value', v)}
              width="250px"
            />
          </S.ItemWrap>
          <S.ItemWrap>
            <S.ItemsHead>기동성</S.ItemsHead>
            <MuiRadioGroup
              i18nKey="SAFETY.MOBILITY"
              values={[1, 2, 3, 4]}
              defaultValue={getValues('branden_scale.mobility.value')}
              onChange={v => setValue('branden_scale.mobility.value', v)}
              width="250px"
            />
          </S.ItemWrap>
          <S.ItemWrap>
            <S.ItemsHead>영양</S.ItemsHead>
            <MuiRadioGroup
              i18nKey="SAFETY.NUTRITION"
              values={[1, 2, 3, 4]}
              defaultValue={getValues('branden_scale.nutrition.value')}
              onChange={v => setValue('branden_scale.nutrition.value', v)}
              width="250px"
            />
          </S.ItemWrap>
          <S.ItemWrap>
            <S.ItemsHead>마찰력과 응전력</S.ItemsHead>
            <MuiRadioGroup
              i18nKey="SAFETY.FRICTION.SHEARING"
              values={[1, 2, 3]}
              defaultValue={getValues('branden_scale.friction_shearing.value')}
              onChange={v =>
                setValue('branden_scale.friction_shearing.value', v)
              }
              width="250px"
            />
          </S.ItemWrap>
        </S.IndexContent>
        <span className="total">합계 : -- 점</span>
        <div className="total-div">
          <div className="score">
            <div>
              <span className="standard">초고위험군</span>
              <span>: 9점 이하</span>
            </div>
            <div>
              <span className="standard">고위험군</span>
              <span>: 10~12점</span>
            </div>
            <div>
              <span className="standard">중위험군</span>
              <span>: 13~15점</span>
            </div>
            <div>
              <span className="standard">저위험군</span>
              <span>: 15~18점</span>
            </div>
          </div>
        </div>
        <span className="modal-title">욕창 기록지</span>
        <S.BedsoreContainer>
          <S.BedsoreImgWrap>
            <S.IndexTitle>
              <span>욕창 위치 (앞)</span>
            </S.IndexTitle>
            <img src={frontBody} alt="human-body" />
          </S.BedsoreImgWrap>
          <S.BedsoreImgWrap>
            <S.IndexTitle>
              <span>욕창 위치 (뒤)</span>
            </S.IndexTitle>
            <img src={backBody} alt="human-body" />
          </S.BedsoreImgWrap>
        </S.BedsoreContainer>
        <S.IndexTitle>
          <span>욕창 기록</span>
        </S.IndexTitle>
        <S.BedsoreRecordWrap>
          <S.ItemsHead>피부손상</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="EXIST.SHORT"
            values={[1, 2]}
            defaultValue={getValues('bedsored_record.skin_damage_status.value')}
            onChange={v =>
              setValue('bedsored_record.skin_damage_status.value', v)
            }
            width="150px"
          />
        </S.BedsoreRecordWrap>
        <S.BedsoreRecordWrap>
          <S.ItemsHead>손상부위</S.ItemsHead>
          <MuiTextField
            required={false}
            sx={{
              minWidth: '130px',
              width: '50%',
              marginLeft: '10px'
            }}
            placeholder="직접 입력"
            {...register('bedsored_record.damaged_area')}
          />
        </S.BedsoreRecordWrap>
        <S.BedsoreRecordWrap>
          <S.ItemsHead>크기</S.ItemsHead>
          <MuiTextField
            required={false}
            sx={{
              minWidth: '130px',
              width: '50%',
              marginLeft: '10px'
            }}
            placeholder="직접 입력"
            {...register('bedsored_record.size')}
          />
        </S.BedsoreRecordWrap>
        <S.BedsoreRecordWrap>
          <S.ItemsHead>피부손상</S.ItemsHead>
          <MuiRadioGroup
            i18nKey="SAFETY.SKIN.DAMAGE"
            values={[1, 2, 3, 4, 5, 6]}
            defaultValue={getValues('bedsored_record.skin_damage_stage.value')}
            onChange={v =>
              setValue('bedsored_record.skin_damage_stage.value', v)
            }
            width="150px"
          />
          <MuiTextField
            required={false}
            sx={{
              minWidth: '130px',
              width: '100px',
              marginLeft: '-80px'
            }}
            placeholder="직접 입력"
            {...register('bedsored_record.skin_damage_stage.input')}
          />
        </S.BedsoreRecordWrap>
      </S.ModalContentContainer>
    </S.ModalContainer>
  );
}
