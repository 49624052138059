import * as S from '../../styles/NursingStyles/InfoNursingStyles';
import { IFormRegister, IFormValues } from '../type';
import MuiTextField from 'form/MuiTextField';
import RowContainer from 'form/RowContainer';
import RowContent from 'form/RowContent';
import { Stack } from '@mui/material';
import MuiRadioGroup from 'form/MuiRadioGroup';
import adornment from 'form/adornment';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

export default function InfoOP2(props: Props) {
  const { disabled, register, getValues, setValue } = props;

  const contents = [
    {
      label: '진료과',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '주치의',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },

    {
      label: '지정의',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '수술과 / 집도의',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '예정 수술명 1',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '수술과 / 집도의',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '예정 수술명 2',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '수술과 / 집도의',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '예정 수술명 3',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    }
  ];

  return (
    <S.Layout1>
      <S.Container2>
        <RowContainer xs={12}>
          {contents.map(({ label, element }) => (
            <>
              <RowContent
                title={label}
                titleRatio={1}
                childrenRatio={
                  label === '지정의' ||
                  label === '수술과 / 집도의' ||
                  label.includes('예정 수술명')
                    ? 5
                    : 2
                }
              >
                {element}
              </RowContent>
            </>
          ))}
        </RowContainer>
      </S.Container2>
    </S.Layout1>
  );
}
