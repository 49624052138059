import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 12px;
  height: 100%;
`;

export const UserBtnWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .patient {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Infection = styled.button`
  border: none;
  border-radius: 4px;
  background: #ed7d31;
  color: #fff;
  font-family: 'Spoqa', sans-serif;
  padding: 10px 18px;
  cursor: pointer;
`;

export const Fall = styled.button`
  border: none;
  border-radius: 4px;
  background: #0070c0;
  color: #fff;
  font-family: 'Spoqa', sans-serif;
  padding: 10px 18px;
  cursor: pointer;
`;

export const Bedsore = styled.button`
  border: none;
  border-radius: 4px;
  background: #70ad47;
  color: #fff;
  font-family: 'Spoqa', sans-serif;
  padding: 10px 18px;
  cursor: pointer;
`;

export const BloodType = styled.div`
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  color: black;
  background: #fff;
  font-family: 'Spoqa', sans-serif;
  padding: 8px 16px;
`;

export const Detail = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 4px;
  gap: 8px;
  width: 100%;
  height: 100%;
`;

export const Inner = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Box1 = styled.div`
  display: flex;
  margin-top: 10px;
  margin-left: 10px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 30px;
  font-size: 14px;
`;

export const BoxWrap = styled.div`
  display: flex;
  flex-direction: row;
  .name {
    font-size: 14px;
    font-weight: 400;
  }
  .contents {
    font-size: 14px;
    font-weight: 500;
    margin-left: 4px;
  }
`;

export const Box2 = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  height: 100%;
  gap: 30px;
  font-size: 14px;
`;
