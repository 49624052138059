import MuiTextField from 'form/MuiTextField';
import * as S from '../../styles/FilloStyles/Info6Styles';
import { IFormRegister, IFormValues } from '../type';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

export default function Info6(props: Props) {
  const { disabled, register, getValues, setValue } = props;

  return (
    <S.Layout>
      <S.Container>
        <span className="title">Ⅵ. 추가사항(피부, 정신상태 등)</span>
        <S.ContentsContainer>
          <S.ContentsBox>
            <MuiTextField
              required={false}
              fullWidth
              disabled={disabled}
              placeholder="직접 입력"
              multiline
              minRows={2}
              {...register('more_details')}
            />
          </S.ContentsBox>
        </S.ContentsContainer>
      </S.Container>
    </S.Layout>
  );
}
