import styled from 'styled-components';

export const Layout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 10px;
  background-color: white;
  padding: 10px;
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 4px;
`;

export const Layout1 = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 10px;
  background-color: white;
  padding: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: -13px;
  border-radius: 4px;
`;

export const LayoutNoneBackground = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 10px;
  /* background-color: white; */
  /* padding: 10px; */
  padding-left: 10px;
  margin-top: 10px;
  /* margin-left: 10px; */
  margin-bottom: -13px;
  border-radius: 4px;
`;

export const HarfBackground = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  flex: 5;
`;

export const Container = styled.div`
  flex: 5;
  height: 114px;
  border-bottom: 1px solid #2a2a2a54;
  padding-bottom: 10px;
  .title {
    font-size: 14px;
    font-weight: 700;
    margin: 10px 0 10px 0;
  }

  .dummy {
    margin: 7px;
  }
`;

export const Container2 = styled.div`
  flex: 5;
  height: 200px;
  border-bottom: 1px solid #2a2a2a54;
  padding-bottom: 10px;
  .title {
    font-size: 14px;
    font-weight: 700;
    margin: 10px;
  }
  .dummy {
    margin: 7px;
  }
`;

export const Container3 = styled.div`
  height: 1530px;
  border-bottom: 1px solid #2a2a2a54;
  padding-bottom: 10px;
  background-color: white;
  border-radius: 4px;
  flex: 5;

  .title {
    font-size: 14px;
    font-weight: 700;
    margin: 10px;
  }
  .title_sub {
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0 10px 0;
  }
  .dummy {
    margin: 7px;
  }
`;

export const Container3_right = styled.div`
  height: 1000px;
  border-bottom: 1px solid #2a2a2a54;
  padding-bottom: 10px;
  background-color: white;
  border-radius: 4px;
  flex: 5;

  .title {
    font-size: 14px;
    font-weight: 700;
    margin: 10px;
  }
  .title_sub {
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0 10px 0;
  }
  .dummy {
    margin: 7px;
  }
`;

export const ContentsContainer = styled.div`
  width: 100%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  border-bottom: 1px solid #2020204e;
`;

export const ContentsBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 95%;
  .sub_title {
    font-size: 12px;
    font-weight: 500;
    width: 120px;
    padding: 10px;
  }
  .sub_title2 {
    font-size: 12px;
    font-weight: 500;
    width: 70px;
    padding: 10px;
  }
`;

export const MiniContainerBox = styled.div`
  display: flex;
`;

export const MiniBox = styled.div`
  display: flex;
  width: 100%;
`;

// preOp
export const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  justify-content: space-between;
  height: 50px;
`;
