import * as S from '../../../styles/ObservationStyles/PainConditionStyle';
import { ReactComponent as Body } from 'assets/img/body.svg';

import {
  Box,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import SectionTitle from 'form/SectionTitle';
import { useState } from 'react';
import { StyledSlider } from 'styles/ObservationStyles/PainMuiStyles';
import { IFormRegister, IFormValues } from '../../type';
import MuiTextField from 'form/MuiTextField';
import adornment from 'form/adornment';
import MuiRadioGroup from 'form/MuiRadioGroup';

interface Props extends IFormRegister, IFormValues {}

export default function FFI(props: Props) {
  const { register, getValues, setValue } = props;

  const [painScore1, setPainScore1] = useState(
    getValues('ffiSlicer1') !== undefined ? getValues('ffiSlicer1') : 0
  );
  const [painScore2, setPainScore2] = useState(
    getValues('ffiSlicer2') !== undefined ? getValues('ffiSlicer2') : 0
  );
  const [painScore3, setPainScore3] = useState(
    getValues('ffiSlicer3') !== undefined ? getValues('ffiSlicer3') : 0
  );
  const [painScore4, setPainScore4] = useState(
    getValues('ffiSlicer4') !== undefined ? getValues('ffiSlicer4') : 0
  );
  const [painScore5, setPainScore5] = useState(
    getValues('ffiSlicer5') !== undefined ? getValues('ffiSlicer5') : 0
  );
  const [painScore6, setPainScore6] = useState(
    getValues('ffiSlicer6') !== undefined ? getValues('ffiSlicer6') : 0
  );
  const [painScore7, setPainScore7] = useState(
    getValues('ffiSlicer7') !== undefined ? getValues('ffiSlicer7') : 0
  );
  const [painScore8, setPainScore8] = useState(
    getValues('ffiSlicer8') !== undefined ? getValues('ffiSlicer8') : 0
  );
  const [painScore9, setPainScore9] = useState(
    getValues('ffiSlicer9') !== undefined ? getValues('ffiSlicer9') : 0
  );

  const [functionScore1, setFunctionScore1] = useState(
    getValues('ffiSlicer10') !== undefined ? getValues('ffiSlicer10') : 0
  );
  const [functionScore2, setFunctionScore2] = useState(
    getValues('ffiSlicer11') !== undefined ? getValues('ffiSlicer11') : 0
  );
  const [functionScore3, setFunctionScore3] = useState(
    getValues('ffiSlicer12') !== undefined ? getValues('ffiSlicer12') : 0
  );
  const [functionScore4, setFunctionScore4] = useState(
    getValues('ffiSlicer13') !== undefined ? getValues('ffiSlicer13') : 0
  );
  const [functionScore5, setFunctionScore5] = useState(
    getValues('ffiSlicer14') !== undefined ? getValues('ffiSlicer14') : 0
  );
  const [functionScore6, setFunctionScore6] = useState(
    getValues('ffiSlicer15') !== undefined ? getValues('ffiSlicer15') : 0
  );
  const [functionScore7, setFunctionScore7] = useState(
    getValues('ffiSlicer16') !== undefined ? getValues('ffiSlicer16') : 0
  );
  const [functionScore8, setFunctionScore8] = useState(
    getValues('ffiSlicer17') !== undefined ? getValues('ffiSlicer17') : 0
  );
  const [functionScore9, setFunctionScore9] = useState(
    getValues('ffiSlicer18') !== undefined ? getValues('ffiSlicer18') : 0
  );

  const [limiteScore1, setLimiteScore1] = useState(
    getValues('ffiSlicer19') !== undefined ? getValues('ffiSlicer19') : 0
  );
  const [limiteScore2, setLimiteScore2] = useState(
    getValues('ffiSlicer20') !== undefined ? getValues('ffiSlicer20') : 0
  );
  const [limiteScore3, setLimiteScore3] = useState(
    getValues('ffiSlicer21') !== undefined ? getValues('ffiSlicer21') : 0
  );
  const [limiteScore4, setLimiteScore4] = useState(
    getValues('ffiSlicer22') !== undefined ? getValues('ffiSlicer22') : 0
  );
  const [limiteScore5, setLimiteScore5] = useState(
    getValues('ffiSlicer23') !== undefined ? getValues('ffiSlicer23') : 0
  );

  // 리펙토링 해볼 map
  const ffiContents = [
    {
      title: '통증척도',
      decs: [
        {
          label: '1. 가장 심할 때 통증의 정도는?',
          key: 'ffiSlicer1'
        },
        {
          label: '2. 아침에 통증의 정도는?',
          key: 'ffiSlicer2'
        },
        {
          label: '3. 맨발로 걸을 때 통증의 정도는?',
          key: 'ffiSlicer3'
        },
        {
          label: '4. 맨발로 서 있을 때 통증의 정도는?',
          key: 'ffiSlicer4'
        },
        {
          label: '5. 신발 신고 걸을 때 통증의 정도는?',
          key: 'ffiSlicer5'
        },
        {
          label: '6. 신발 신고 서 있을 때 통증의 정도는?',
          key: 'ffiSlicer6'
        },
        {
          label: '7. 보조기를 착용하고 걸을 때 통증의 정도는?',
          key: 'ffiSlicer7'
        },
        {
          label: '8. 보조기를 착용하고 서 있을 때 통증의 정도는?',
          key: 'ffiSlicer8'
        },
        {
          label: '9. 잠자리에 들기 전에 통증의 정도는?',
          key: 'ffiSlicer9'
        }
      ]
    },
    {
      title: '기능척도',
      decs: [
        {
          label: '10. 잠자리에 들기 전에 통증의 정도는?',
          key: 'ffiSlicer10'
        },
        {
          label: '11. 실외에서 걸을 때 불편함의 정도는?',
          key: 'ffiSlicer11'
        },
        {
          label: '12. 5-10분 정도(400-500미터) 걸을 때 불편함의 정도는?',
          key: 'ffiSlicer12'
        },
        {
          label: '13. 계단을 오를 때 불편함의 정도는?',
          key: 'ffiSlicer13'
        },
        {
          label: '14. 계단으로 내려올 때 불편함의 정도는?',
          key: 'ffiSlicer14'
        },
        {
          label: '15. 발가락 끝으로 설 때 불편함의 정도는?',
          key: 'ffiSlicer15'
        },
        {
          label: '16. 의자에서 일어 설 때 불편함의 정도는?',
          key: 'ffiSlicer16'
        },
        {
          label: '17. 언덕을 오를 때 불편함의 정도는?',
          key: 'ffiSlicer17'
        },
        {
          label: '18. 빠르게 걷거나 뛸 때 불편함의 정도는?',
          key: 'ffiSlicer18'
        }
      ]
    },
    {
      title: '활동제한척도',
      decs: [
        {
          label: '19. 발 때문에 활동이 실내에서만으로 제한된 정도는?',
          key: 'ffiSlicer19'
        },
        {
          label: '20. 발 때문에 활동이 침상에서만으로 제한된 정도는?',
          key: 'ffiSlicer20'
        },
        {
          label:
            '21. 실내에서 보조기구(지팡이, 워커, 목발 등)을 필요로 하는 정도는?',
          key: 'ffiSlicer21'
        },
        {
          label:
            '22. 실외에서 보조기구(지팡이, 워커, 목발 등)을 필요로 하는 정도는?',
          key: 'ffiSlicer22'
        },
        {
          label: '23. 신체적 활동에 어느정도 제약을 받습니까?',
          key: 'ffiSlicer23'
        }
      ]
    }
  ];

  const resultAnswer =
    Number(painScore1) +
    Number(painScore2) +
    Number(painScore3) +
    Number(painScore4) +
    Number(painScore5) +
    Number(painScore6) +
    Number(painScore7) +
    Number(painScore8) +
    Number(painScore9) +
    Number(functionScore1) +
    Number(functionScore2) +
    Number(functionScore3) +
    Number(functionScore4) +
    Number(functionScore5) +
    Number(functionScore6) +
    Number(functionScore7) +
    Number(functionScore8) +
    Number(functionScore9) +
    Number(limiteScore1) +
    Number(limiteScore2) +
    Number(limiteScore3) +
    Number(limiteScore4) +
    Number(limiteScore5);

  const resultAnswer2 = ((resultAnswer / 230) * 100).toFixed(2);

  return (
    <div>
      <Typography
        sx={{ fontSize: '20px', fontWeight: 700, whiteSpace: 'nowrap' }}
      >
        발, 발목 관절 기능 척도 평가(Foot function index , FFI)
      </Typography>
      {ffiContents.map(item => {
        return (
          <>
            <SectionTitle title={item.title} mb={2} mt={5} />
            <Grid item xs={12}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      key={'title'}
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        whiteSpace: 'nowrap'
                      }}
                    >
                      질문 사항
                    </TableCell>
                    <TableCell
                      key={'점수'}
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        textAlign: 'right',
                        whiteSpace: 'pre',
                        wordSpacing: '32px',
                        width: '500px'
                      }}
                    >
                      0 1 2 3 4 5 6 7 8 9 10
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.decs.map((subTitle, i) => {
                    return (
                      <TableRow>
                        <TableCell>
                          <Typography
                            sx={{
                              fontSize: 12,
                              fontWeight: '400',
                              width: '500px'
                            }}
                            maxHeight={20}
                            display="block"
                            variant="caption"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace={'pre-line'}
                          >
                            {subTitle.label}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>
                          <StyledSlider
                            min={0}
                            max={10}
                            marks={Array(11)
                              .fill(0)
                              .map((_, i) => {
                                return { value: i };
                              })}
                            sx={{ width: '428px' }}
                            value={
                              subTitle.key === 'ffiSlicer1'
                                ? painScore1
                                : subTitle.key === 'ffiSlicer2'
                                  ? painScore2
                                  : subTitle.key === 'ffiSlicer3'
                                    ? painScore3
                                    : subTitle.key === 'ffiSlicer4'
                                      ? painScore4
                                      : subTitle.key === 'ffiSlicer5'
                                        ? painScore5
                                        : subTitle.key === 'ffiSlicer6'
                                          ? painScore6
                                          : subTitle.key === 'ffiSlicer7'
                                            ? painScore7
                                            : subTitle.key === 'ffiSlicer8'
                                              ? painScore8
                                              : subTitle.key === 'ffiSlicer9'
                                                ? painScore9
                                                : subTitle.key === 'ffiSlicer10'
                                                  ? functionScore1
                                                  : subTitle.key ===
                                                      'ffiSlicer11'
                                                    ? functionScore2
                                                    : subTitle.key ===
                                                        'ffiSlicer12'
                                                      ? functionScore3
                                                      : subTitle.key ===
                                                          'ffiSlicer13'
                                                        ? functionScore4
                                                        : subTitle.key ===
                                                            'ffiSlicer14'
                                                          ? functionScore5
                                                          : subTitle.key ===
                                                              'ffiSlicer15'
                                                            ? functionScore6
                                                            : subTitle.key ===
                                                                'ffiSlicer16'
                                                              ? functionScore7
                                                              : subTitle.key ===
                                                                  'ffiSlicer17'
                                                                ? functionScore8
                                                                : subTitle.key ===
                                                                    'ffiSlicer18'
                                                                  ? functionScore9
                                                                  : subTitle.key ===
                                                                      'ffiSlicer19'
                                                                    ? limiteScore1
                                                                    : subTitle.key ===
                                                                        'ffiSlicer20'
                                                                      ? limiteScore2
                                                                      : subTitle.key ===
                                                                          'ffiSlicer21'
                                                                        ? limiteScore3
                                                                        : subTitle.key ===
                                                                            'ffiSlicer22'
                                                                          ? limiteScore4
                                                                          : limiteScore5
                            }
                            defaultValue={painScore1}
                            onChange={(_, value) => {
                              if (subTitle.key === 'ffiSlicer1')
                                setPainScore1(Number(value));
                              else if (subTitle.key === 'ffiSlicer2')
                                setPainScore2(Number(value));
                              else if (subTitle.key === 'ffiSlicer3')
                                setPainScore3(Number(value));
                              else if (subTitle.key === 'ffiSlicer4')
                                setPainScore4(Number(value));
                              else if (subTitle.key === 'ffiSlicer5')
                                setPainScore5(Number(value));
                              else if (subTitle.key === 'ffiSlicer6')
                                setPainScore6(Number(value));
                              else if (subTitle.key === 'ffiSlicer7')
                                setPainScore7(Number(value));
                              else if (subTitle.key === 'ffiSlicer8')
                                setPainScore8(Number(value));
                              else if (subTitle.key === 'ffiSlicer9')
                                setPainScore9(Number(value));
                              else if (subTitle.key === 'ffiSlicer10')
                                setFunctionScore1(Number(value));
                              else if (subTitle.key === 'ffiSlicer11')
                                setFunctionScore2(Number(value));
                              else if (subTitle.key === 'ffiSlicer12')
                                setFunctionScore3(Number(value));
                              else if (subTitle.key === 'ffiSlicer13')
                                setFunctionScore4(Number(value));
                              else if (subTitle.key === 'ffiSlicer14')
                                setFunctionScore5(Number(value));
                              else if (subTitle.key === 'ffiSlicer15')
                                setFunctionScore6(Number(value));
                              else if (subTitle.key === 'ffiSlicer16')
                                setFunctionScore7(Number(value));
                              else if (subTitle.key === 'ffiSlicer17')
                                setFunctionScore8(Number(value));
                              else if (subTitle.key === 'ffiSlicer18')
                                setFunctionScore9(Number(value));
                              else if (subTitle.key === 'ffiSlicer19')
                                setLimiteScore1(Number(value));
                              else if (subTitle.key === 'ffiSlicer20')
                                setLimiteScore2(Number(value));
                              else if (subTitle.key === 'ffiSlicer21')
                                setLimiteScore3(Number(value));
                              else if (subTitle.key === 'ffiSlicer22')
                                setLimiteScore4(Number(value));
                              else if (subTitle.key === 'ffiSlicer23')
                                setLimiteScore5(Number(value));

                              setValue(`${subTitle.key}`, value);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          </>
        );
      })}

      <Grid item xs={12} sx={{ marginTop: '30px', marginRight: '10px' }}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
          <Typography
            gutterBottom
            minWidth={115}
            fontWeight={500}
            fontSize="16px"
            variant="subtitle1"
            sx={{ marginBottom: '40px' }}
          >
            점수 : {resultAnswer}점/230 X 100 = {resultAnswer2}%
          </Typography>
          <Typography
            minWidth={115}
            variant="caption"
            sx={{ color: '#604180' }}
          >
            <Typography variant="inherit" sx={{ fontSize: '13px' }}>
              <Box component={'strong'} mr={0.5}>
                FFI 점수 범위 :
              </Box>
              0 ~ 100%
            </Typography>
            <Typography variant="inherit" sx={{ fontSize: '13px' }}>
              <Box component={'strong'} mr={0.5}>
                0% :
              </Box>
              건강한 상태
            </Typography>
            <Typography variant="inherit" sx={{ fontSize: '13px' }}>
              <Box component={'strong'} mr={0.5}>
                100% :
              </Box>
              심각한 통증 있으며, 운동 일상생활에 큰 제한이 있는 상태
            </Typography>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ width: '100%', marginTop: '40px', marginBottom: '12px' }}>
          <S.ContentContainer>
            <S.ItemWrap>
              <S.ItemsHead>통증점수</S.ItemsHead>
              <MuiTextField
                required={false}
                value={resultAnswer2}
                sx={{
                  minWidth: '130px',
                  width: '130px'
                }}
                InputProps={{ ...adornment('', '점'), readOnly: true }}
                {...register('ffiScore')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증부위</S.ItemsHead>
              <MuiTextField
                required={false}
                placeholder="직접 입력"
                {...register('ffiWay')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <Body />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증양상</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.PATTERN"
                values={[1, 2]}
                defaultValue={getValues('ffiPattern')}
                onChange={v => setValue('ffiPattern', v)}
                width="100px"
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증빈도</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.FREQUENCY"
                values={[1, 2, 3]}
                defaultValue={getValues('ffiFrequency')}
                onChange={v => setValue('ffiFrequency', v)}
                width="100px"
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증기간</S.ItemsHead>
              <MuiRadioGroup
                i18nKey="CLINICAL.PAIN.LONG"
                values={[1, 2]}
                defaultValue={getValues('ffiLong')}
                onChange={v => setValue('ffiLong', v)}
                width="100px"
              />
              <MuiTextField
                required={false}
                placeholder="직접 입력"
                {...register('ffiLongInput')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHead>통증중재</S.ItemsHead>
              <span className="medi">비약물적 중재</span>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('ffiNoneMediChecked1'))}
                  {...register('ffiNoneMediChecked1')}
                  onChange={(e, checked) => {
                    setValue('ffiNoneMediChecked1', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>심호흡 방법 교육</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('ffiNoneMediChecked2'))}
                  {...register('ffiNoneMediChecked2')}
                  onChange={(e, checked) => {
                    setValue('ffiNoneMediChecked2', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>냉찜질 시행</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('ffiNoneMediChecked3'))}
                  {...register('ffiNoneMediChecked3')}
                  onChange={(e, checked) => {
                    setValue('ffiNoneMediChecked3', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>휴식</span>
              </S.CheckboxWrap>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('ffiNoneMediChecked4'))}
                  {...register('ffiNoneMediChecked4')}
                  onChange={(e, checked) => {
                    setValue('ffiNoneMediChecked4', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>기타</span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('ffiNoneMediInput')}
              />
            </S.ItemWrap>
            <S.ItemWrap>
              <S.ItemsHeadDummy></S.ItemsHeadDummy>
              <span className="none_medi">약물적 중재</span>
              <S.CheckboxWrap>
                <Checkbox
                  size="small"
                  defaultChecked={Boolean(getValues('ffiMediChecked'))}
                  {...register('ffiMediChecked')}
                  onChange={(e, checked) => {
                    setValue('ffiMediChecked', checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#604180'
                    }
                  }}
                />
                <span>약물명 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('ffiMediInput1')}
              />
              <S.CheckboxWrap>
                <span>용량 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('ffiMediInput2')}
              />
              <S.CheckboxWrap>
                <span>투여방법 : </span>
              </S.CheckboxWrap>
              <MuiTextField
                required={false}
                fullWidth={false}
                placeholder="직접 입력"
                sx={{ width: '84px' }}
                {...register('ffiMediInput3')}
              />
            </S.ItemWrap>
          </S.ContentContainer>
        </Box>
      </Grid>
    </div>
  );
}
