import * as S from '../../styles/SafetyStyles/SafetyStyles';
import { Skeleton } from '@mui/material';
import { useState } from 'react';
import usePatient from 'store/patient/usePatient';
import Left from './Left';
import Right from './Right';

export default function Safety() {
  const { patientInfo } = usePatient();
  /* 0 : 낙상/욕창 , 1: 억제대 */
  const [viewType, setViewType] = useState<number>(0);
  const [showFall, setShowFall] = useState<boolean>(false);
  const [showBed, setShowBed] = useState<boolean>(false);
  const [showAddRestraint, setShowAddRestraint] = useState<boolean>(false);

  if (!patientInfo) {
    return <Skeleton variant="rectangular" sx={{ flex: 1 }} />;
  }
  const { patient_id, name, age, gender, department, room } = patientInfo;

  const handleViewType = (num: number) => {
    setViewType(num);
  };

  return (
    <>
      <S.Layout>
        <S.Container>
          <span className="patient">
            {name} / {gender === 1 ? '여자' : '남자'} /{age} ({patient_id})
            {department} / {room}
          </span>
          <S.BtnWrap>
            <S.CategoryBtn
              $selected={viewType === 0}
              onClick={() => handleViewType(0)}
            >
              낙상 / 욕창
            </S.CategoryBtn>
            <S.CategoryBtn
              $selected={viewType === 1}
              onClick={() => handleViewType(1)}
            >
              억제대
            </S.CategoryBtn>
          </S.BtnWrap>
          <S.ContainerWrap>
            <S.LeftLayout>
              <Left viewType={viewType} />
            </S.LeftLayout>
            <S.RightLayout>
              <Right
                viewType={viewType}
                showBed={showBed}
                setShowBed={setShowBed}
                showFall={showFall}
                setShowFall={setShowFall}
                showAddRestraint={showAddRestraint}
                setShowAddRestraint={setShowAddRestraint}
              />
            </S.RightLayout>
          </S.ContainerWrap>
        </S.Container>
      </S.Layout>
      <S.Overlay $show={showFall || showBed || showAddRestraint} />
    </>
  );
}
