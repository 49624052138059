import { Fragment } from 'react';
import {
  Grid,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Table,
  Box
} from '@mui/material';
import MuiRadioGroup from './components/MuiRadioGroup';

import { IFormRegister, IFormValues } from '../../../type';
import SectionTitle from 'form/SectionTitle';

interface Props extends IFormValues, IFormRegister {}

const KOOSContents4 = (props: Props) => {
  const { getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle
        title="운동 및 놀이 활동 기능 (Function, Sports and Recreational Activities)"
        mb={1}
        mt={1}
      />
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: '500',
            width: '100%',
            paddingLeft: '10px',
            borderBottom: 'solid 0.6px lightGray',
            height: '35px'
          }}
          whiteSpace={'nowrap'}
        >
          다음 질문들은 심한 활동을 요하는 신체적 능력과 관계된 것들입니다. 각
          질문에 대해 지난 일주일 동안 다음의 활동 시에 무릎으로 인한 어려움의
          정도를 표기해 주십시오.
        </Typography>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400'
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  쪼그려 앉을 때
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  i18nNullKey="ETC"
                  i18nKey="KOOS.SPORTS"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('actv01')}
                  onChange={v => setValue('actv01', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400'
                  }}
                  maxHeight={50}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  달릴 때
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  i18nNullKey="ETC"
                  i18nKey="KOOS.SPORTS"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('actv02')}
                  onChange={v => setValue('actv02', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px'
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  뜀뛰기 할 때
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  i18nNullKey="ETC"
                  i18nKey="KOOS.SPORTS"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('actv03')}
                  onChange={v => setValue('actv03', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px'
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  다친(아픈) 무릎으로 회전하거나 비틀 때
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  i18nNullKey="ETC"
                  i18nKey="KOOS.SPORTS"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('actv04')}
                  onChange={v => setValue('actv04', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px'
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  무릎을 꿇을 때
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  i18nNullKey="ETC"
                  i18nKey="KOOS.SPORTS"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('actv05')}
                  onChange={v => setValue('actv05', v)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <SectionTitle title="삶의 질 (Quality of Life)" mb={1} mt={1} />
      <Grid item xs={12} sx={{ marginTop: '-10px' }}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px'
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  무릎에 대한 문제점을 얼마나 자주 느끼십니까?
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  i18nNullKey="ETC"
                  i18nKey="KOOS.LIFEQUALITY"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('qol01')}
                  onChange={v => setValue('qol01', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px'
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  무릎에 해가 되는 활동을 피하기 위해 당신의 생활 습관을
                  개선했습니까?
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  i18nNullKey="ETC"
                  i18nKey="KOOS.LIFEQUALITY.TWO"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('qol02')}
                  onChange={v => setValue('qol02', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px'
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  무릎에 대한 자신감이 없어서 얼마나 어려움을 겪었습니까?
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  i18nNullKey="ETC"
                  i18nKey="KOOS.LIFEQUALITY.THREE"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('qol03')}
                  onChange={v => setValue('qol03', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px'
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  전반적으로, 무릎으로 인해 얼마나 많은 어려움이 있었습니까?
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  i18nNullKey="ETC"
                  i18nKey="KOOS.LIFEQUALITY.THREE"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('qol04')}
                  onChange={v => setValue('qol04', v)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Fragment>
  );
};

export default KOOSContents4;
