import Header, { MENU } from 'components/Common/Header';
import EHR from 'components/EHR/EHR';
import Fillo from 'components/Fillo/Fillo';
import Info from 'components/Info/Info';
import Observation from 'components/Observation/Observation';
import TestResult from 'components/TestResult/TestResult';
import Records from 'components/Records/Records';
import { useEffect, useState } from 'react';
import usePatient from 'store/patient/usePatient';
import useSurvey from 'store/survey/useSurvey';
import useDefaultValues from 'hook/useDefaultValues';
import { getPatientInfo } from 'apis/admin';
import useNotification from 'hook/useNotification';
import { Box, Skeleton } from '@mui/material';
import { SurveyDialogProps } from './type';
import Nursing from 'components/Nursing/Nursing';
import Safety from 'components/Safety/Safety';
import Agreement from 'components/Agreement/Agreement';
import useUser from 'store/user/useUser';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import useStudent from 'store/student/useStudent';
import { he } from 'date-fns/locale';
import { Height } from '@mui/icons-material';
import { Button } from '@mui/base';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dialogProps: Omit<SurveyDialogProps<any>, 'onClose'>;
}

export default function Main() {
  const { student_uuid: user_id, onSignIn, onSignOut } = useUser();
  const { onResetStudent } = useStudent();

  const navigate = useNavigate();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const accessToken = query.get('access_token');

  const [defaultValues, setDefaultValues] = useState(null);
  const { onGetDefaultValues } = useDefaultValues({
    user_id,
    setDefaultValues
  });

  const { patient, patientInfo, onSelectedPatientInfo, onResetPatient } =
    usePatient();

  console.log('patient, ', patient);
  const { surveyType, onCloseReadOnly } = useSurvey();
  const { onFail, onSuccess } = useNotification();

  const postAuto = async (parm: string) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/enr_etc/xr/login`,
        {},
        {
          headers: {
            Authorization: `${parm}`,
            'Content-type': 'application/json'
          }
        }
      )
      .then(res => {
        const userInfos = {
          student_uuid: res.data.user_id,
          student_no: res.data.student_no,
          student_name: res.data.student_name
        };
        onSignIn(userInfos);
        onSuccess('로그인에 성공했습니다.');
        navigate('/', { replace: true });

        return;
      })
      .catch(e => {
        alert('로그인 후 작성이 가능합니다.');

        navigate('https://d3gh3vtoxmyty7.cloudfront.net/signin.html');
      });
  };

  console.log('accessToken', accessToken);

  useEffect(() => {
    // onSignOut();
    // // 로그아웃 시 스토어 초기화
    // onResetStudent();
    // onResetPatient();
    // onCloseReadOnly();

    // postAuto(
    //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjE0MyIsInVzZXJuYW1lIjoiTnVyc2luZ3hyMzVAbnVyc2luZy14ci5jb20iLCJyb2xlIjoiRmFsc2UiLCJjb250cmFjdCI6IjMiLCJleHAiOjE3MjU0MzY2Mzh9.CfPqyCey4-0BoAzrxsnGCzmCrTL8GvkFHnmjcmHw9as'
    // );

    if (accessToken === null) {
      alert('로그인 후 작성이 가능합니다.');
      window.location.href =
        'https://d3gh3vtoxmyty7.cloudfront.net/signin.html';
    }

    if (accessToken) {
      onSignOut();
      // 로그아웃 시 스토어 초기화
      onResetStudent();
      onResetPatient();
      onCloseReadOnly();
      postAuto(accessToken);
    }
  }, []);

  useEffect(() => {
    if (!patient) return;

    // 가상환자 상세정보 요청
    getPatientInfo({ patient_id: patient.patient_id })
      .then(({ data }) => {
        onSelectedPatientInfo(data);
      })
      .catch(e => {
        onSelectedPatientInfo(null);
        onFail(`가상환자 데이터 조회에 실패했습니다.`, e);
      });
    // eslint-disable-next-line
  }, [patient]);

  useEffect(() => {
    if (!patientInfo || !surveyType) {
      return setDefaultValues(null);
    }

    const { patient_id } = patientInfo;
    onGetDefaultValues(patient_id, surveyType);
    // eslint-disable-next-line
  }, [surveyType, patientInfo]);

  const dialogProps = {
    user_id,
    patientInfo,
    defaultValues
  };

  const Contents = (props: Props) => {
    const { dialogProps } = props;
    switch (surveyType) {
      case MENU.INFO: {
        if (!defaultValues) return null;
        return <Info />;
      }
      case MENU.EHR: {
        if (!defaultValues) return null;
        return <EHR />;
      }
      case MENU.FILLO: {
        if (!defaultValues) return null;
        return <Fillo {...dialogProps} />;
      }
      case MENU.OBSERVATION: {
        if (!defaultValues) return null;
        return <Observation />;
      }
      case MENU.TESTRESULT: {
        if (!defaultValues) return null;
        return <TestResult />;
      }
      case MENU.RECORDS: {
        if (!defaultValues) return null;
        return <Records />;
      }
      case MENU.NURSING: {
        if (!defaultValues) return null;
        return <Nursing {...dialogProps} />;
      }
      case MENU.SAFETY: {
        if (!defaultValues) return null;
        return <Safety />;
      }
      case MENU.AGREEMENT: {
        if (!defaultValues) return null;
        return <Agreement />;
      }
    }

    return null;
  };

  if (!surveyType || !patientInfo)
    return (
      <Header>
        <Contents dialogProps={dialogProps} />
      </Header>
    );

  if (!patientInfo || !user_id) {
    return (
      <Header>
        <Skeleton variant="rectangular" sx={{ flex: 1 }} />;
      </Header>
    );
  }

  return (
    <>
      <Header>
        <Contents dialogProps={dialogProps} />
      </Header>
    </>
  );
}
