import * as S from '../../styles/NursingStyles/InfoNursingStyles';
import { IFormRegister, IFormValues } from '../type';
import MuiTextField from 'form/MuiTextField';
import RowContainer from 'form/RowContainer';
import RowContent from 'form/RowContent';
import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material';
import MuiRadioGroup from 'form/MuiRadioGroup';
import adornment from 'form/adornment';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

export default function InfoOP3(props: Props) {
  const { disabled, register, getValues, setValue } = props;

  const contents = [
    {
      label: '주사금지 부위',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '혈액형',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: 'X-matching',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: 'antibody screen',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '혈액처방',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '감염정보',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '알러지',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: 'ADR',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '삽입관',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '인공삽입물',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '낙상고위험군',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '욕창고위험군',
      element: (
        <MuiTextField
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      )
    },
    {
      label: '수술전 검사',
      element: (
        <Box display="flex" gap={12}>
          <FormControlLabel
            disabled={disabled}
            control={
              <Checkbox
                defaultChecked={getValues(
                  'operation_information.preoperative_xray'
                )}
                {...register('operation_information.preoperative_xray')}
                sx={{
                  '&.Mui-checked': {
                    color: '#604180'
                  }
                }}
              />
            }
            label="혈액검사"
            sx={{
              marginLeft: '-10px',
              '.MuiTypography-root': { fontSize: '12px', fontWeight: 400 }
            }}
          />
          <FormControlLabel
            disabled={disabled}
            control={
              <Checkbox
                defaultChecked={getValues(
                  'operation_information.preoperative_ekg'
                )}
                {...register('operation_information.preoperative_ekg')}
                sx={{
                  '&.Mui-checked': {
                    color: '#604180'
                  }
                }}
              />
            }
            defaultValue={getValues('operation_information.preoperative_ekg')}
            label="심전도"
            sx={{
              '.MuiTypography-root': { fontSize: '12px', fontWeight: 400 }
            }}
          />
          <FormControlLabel
            disabled={disabled}
            control={
              <Checkbox
                defaultChecked={getValues(
                  'operation_information.preoperative_ekg'
                )}
                {...register('operation_information.preoperative_ekg')}
                sx={{
                  '&.Mui-checked': {
                    color: '#604180'
                  }
                }}
              />
            }
            defaultValue={getValues('operation_information.preoperative_ekg')}
            label="영상검사"
            sx={{
              '.MuiTypography-root': { fontSize: '12px', fontWeight: 400 }
            }}
          />
        </Box>
      )
    }
  ];

  return (
    <S.Layout>
      <RowContainer xs={12}>
        {contents.map(({ label, element }) => (
          <>
            <RowContent
              title={label}
              titleRatio={1}
              childrenRatio={
                label === '혈액처방' ||
                label === '감염정보' ||
                label === '알러지' ||
                label === 'ADR' ||
                label === '삽입관' ||
                label === '인공삽입물' ||
                label === '낙상고위험군' ||
                label === '욕창고위험군' ||
                label === '수술전 검사'
                  ? 5
                  : 2
              }
            >
              {element}
            </RowContent>
          </>
        ))}
      </RowContainer>
    </S.Layout>
  );
}
